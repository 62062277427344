import {
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import SignatureCanvas from 'react-signature-canvas';
import { useRef } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const WithholdingDeclarationForm = ({ control, watch, setValue }) => {
    let witnessSigCanvas = useRef<SignatureCanvas>(null);

    const clearWitnessSignature = () => {
        if (witnessSigCanvas.current) {
            witnessSigCanvas.current.clear();
        }
    };

    const handleSaveWitnessSignature = () => {
        if (witnessSigCanvas.current) {
            const dataURL = witnessSigCanvas.current.toDataURL();
            setValue('declarationWitnessSigDataURL', dataURL);
        }
    };

    return (
        <Grid container spacing={2} margin={'10px'}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom component="div">
                    Declaration for the Purposes of the PAYG (Withholding Tax)
                    Legislation
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    (Tick as applicable and initial deletions and deletions)
                </Typography>
                <Typography variant="body1">
                    The supplier of the trade-in vehicle clearly declares that
                    it:
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="mainTaxInvoiceOption"
                    control={control}
                    render={({ field }) => (
                        <RadioGroup {...field}>
                            <FormControlLabel
                                value="provideTaxInvoice"
                                control={<Radio />}
                                label="Will provide a Tax Invoice for the supply of the above described trade-in vehicle as it holds an ABN which is"
                            />
                            <TextField
                                {...control.register('abnForGst')}
                                variant="standard"
                                margin="dense"
                                placeholder="Enter ABN"
                            />

                            <Typography variant="body1">
                                and it is registered for GST purposes; or
                            </Typography>
                            <FormControlLabel
                                value="notProvideTaxInvoice"
                                control={<Radio />}
                                label="Will not be providing a Tax Invoice for the supply of the above described trade-in vehicle because either:"
                            />
                        </RadioGroup>
                    )}
                />

                {/* // only display below options when notProvideTaxInvoice is selected */}
                {watch('mainTaxInvoiceOption') === 'notProvideTaxInvoice' && (
                    <>
                        <Controller
                            name="subTaxInvoiceOption"
                            control={control}
                            render={({ field }) => (
                                <RadioGroup {...field} sx={{ marginLeft: 4 }}>
                                    <FormControlLabel
                                        value="isIndividual"
                                        control={<Radio size="small" />}
                                        label="The supply is made by it as an individual and is wholly private or domestic in nature; or"
                                    />
                                    <FormControlLabel
                                        value="notRegisteredForGST"
                                        control={<Radio size="small" />}
                                        label="It holds an ABN which is"
                                    />
                                    <TextField
                                        {...control.register('abnNotForGST')}
                                        variant="standard"
                                        margin="dense"
                                        placeholder="Enter ABN"
                                    />
                                    <Typography variant="body1">
                                        but is not registered for GST purposes.
                                    </Typography>
                                </RadioGroup>
                            )}
                        />
                    </>
                )}
            </Grid>
            <Grid item xs={12}>
                WITNESS:{' '}
                <IconButton onClick={clearWitnessSignature}>
                    <ClearIcon />
                </IconButton>
            </Grid>
            <Grid item xs={12} textAlign="center">
                <Controller
                    name="declarationWitnessSigDataURL"
                    control={control}
                    render={({ field }) => (
                        <SignatureCanvas
                            penColor="black"
                            disabled={false}
                            canvasProps={{
                                width: 430,
                                height: 200,
                                className: 'sigCanvas',
                                style: {
                                    border: '1px solid black'
                                }
                            }}
                            ref={witnessSigCanvas}
                            // save user signature dataURL to form data
                            onEnd={handleSaveWitnessSignature}
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body1">DEALER ACCEPTED DATE</Typography>
            </Grid>
            <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                        name="dealerAcceptDate"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="Date"
                                value={dayjs(field.value)}
                                onChange={field.onChange}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false
                                    }
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
            </Grid>
        </Grid>
    );
};

export default WithholdingDeclarationForm;
