import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    Button,
    Box,
    Paper,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';
import PurchaserCreditForm from './PurchaserCreditForm';
import WrittenOffForm from './WrittenOffForm';
import CoolingOffForm from './CoolingOffForm';
import WithholdingDeclarationForm from './WithholdingDeclarationForm';
import StatutoryWarrantyForm from './StatutoryWarrantyForm';
import DealerDeclarationForm from './DealerDeclarationForm';
import DeliveryReceiptForm from './DeliveryReceiptForm';

interface AdditionalDealerFormsProps {
    onDealerFormsSubmit: (formData: any, submitType: any) => Promise<void>;
    vehicleCondition: string;
    customer: {
        name: string;
        email: string;
    };
    contractStatus: string;
    hasTradeIn: boolean;
}

const AdditionalDealerForms = ({
    onDealerFormsSubmit,
    vehicleCondition,
    customer,
    contractStatus,
    hasTradeIn
}: AdditionalDealerFormsProps) => {
    const [hasPreviewed, setHasPreviewed] = useState(false);

    const { control, watch, handleSubmit, setValue } = useForm({
        defaultValues: {
            // PurchaserCreditForm
            creditOption: 'noCreditNeeded',
            contact: '',
            phone: '',
            premium: '',

            // DELIVERY RECEIPT
            deliveryDate: null,
            purchaserSigDataURL: '',
            witnessSigDataURL: '',

            // WithholdingDeclarationForm
            mainTaxInvoiceOption: 'provideTaxInvoice',
            subTaxInvoiceOption: '',
            abnForGst: '',
            abnNotForGST: '',
            declarationWitnessSigDataURL: '',
            dealerAcceptDate: null,

            // WrittenOffForm
            writtenOffOption: '',

            // CoolingOffForm
            coolingOffOption: 'noCoolingOffPeriod',
            coolingOffPeriodStart: null,
            coolingOffPeriodEnd: null,

            // StatutoryWarrantyForm
            statutoryWarrantyOption: 'noWarranty',
            warrantorName: '',
            warrantorAddress: '',
            warrantorSuburb: '',
            warrantorState: '',
            warrantorPostcode: '',
            warrantorPhone: '',
            warrantorEmail: '',
            buyerPossessionDate: null,

            // DealerDeclarationForm
            noPriorContract: false,
            notPersuadeCoolingOff: false,
            hasStatutoryWarranty: false,
            noStatutoryWarranty: false,
            notPersuadeWarranty: false,
            informedBuyer: false,
            dealerSignDate: null,
            dealerSigDataURL: ''
        }
    });

    // when click 'Preview Changes' button, display the form data on corresponding PDF fields
    const onPreviewChanges = async (data) => {
        await onDealerFormsSubmit(data, 'preview');
        setHasPreviewed(true);
    };

    // when click 'Email Customer' button, open a dialog to confirm sending email to the customer
    const [openEmailDialog, setOpenEmailDialog] = useState(false);

    const onOpenEmailDialog = () => {
        setOpenEmailDialog(true);
    };

    const handleClickSendEmail = async (data) => {
        await onDealerFormsSubmit(data, 'upload');
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Paper elevation={3} sx={{ padding: 3 }}>
                <form>
                    <Grid container spacing={2}>
                        {/* // if the contract status is 'customerSigned', only display the DeliveryReceiptForm */}
                        {contractStatus === 'customerSigned' ? (
                            <DeliveryReceiptForm
                                control={control}
                                setValue={setValue}
                            />
                        ) : (
                            <>
                                <PurchaserCreditForm control={control} />

                                {hasTradeIn && (
                                    <WithholdingDeclarationForm
                                        control={control}
                                        watch={watch}
                                        setValue={setValue}
                                    />
                                )}

                                {/* // forms on contract for used vehicle and manufacturer only */}
                                {vehicleCondition === 'Used' && (
                                    <>
                                        <WrittenOffForm control={control} />
                                        <CoolingOffForm
                                            control={control}
                                            watch={watch}
                                        />
                                        <StatutoryWarrantyForm
                                            control={control}
                                            watch={watch}
                                        />
                                        <DealerDeclarationForm
                                            control={control}
                                            setValue={setValue}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        {/* // buttons to preview, save and email the form data */}
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="error">
                                * Always preview the changes before emailing the
                                customer.
                            </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                            <Button
                                onClick={handleSubmit(onPreviewChanges)}
                                variant="contained"
                                sx={{ my: 3 }}
                            >
                                Preview Changes
                            </Button>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                            <Button
                                onClick={handleSubmit(onOpenEmailDialog)}
                                variant="contained"
                                sx={{ my: 3 }}
                                disabled={!hasPreviewed}
                            >
                                Email Customer
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Dialog open={openEmailDialog}>
                <DialogTitle>Email Customer</DialogTitle>
                <DialogContent>
                    An email will be sent to <b>{customer?.name}</b> at the
                    following email address: <b>{customer?.email}</b> <br />{' '}
                    Always preview the contract before sending an email to the
                    customer.{' '}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEmailDialog(false)}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleClickSendEmail}>
                        Send Email
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AdditionalDealerForms;
