// IMPORTS
import {
    Icon,
    MenuItem,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
// COMPONENTS
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
// INTERFACES
import { Invoice } from '../../../global/interfaces/PartsInterface';
import { WarningAmber } from '@mui/icons-material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import InvoiceEntitySelector from './InvoiceEntitySelector';
import {
    handleChange,
    handleChangeType,
    handleDescriptionChange
} from '../logic/HandleFieldChange';
interface InvoiceLinesProps {
    disabled: boolean;
    invoiceDetails: Invoice;
    setInvoiceDetails: Dispatch<SetStateAction<Invoice>>;
    invoiceType: { type: string; id: number; selector: string };
}
const InvoiceLines = ({
    disabled,
    invoiceDetails,
    setInvoiceDetails,
    invoiceType
}: InvoiceLinesProps) => {
    const [rowType, setRowType] = useState<{ id: number; type: string }[]>([]);

    const adminInvoiceColumns = [
        { id: 0, label: 'Entity' },
        { id: 1, label: 'Description' },
        { id: 2, label: 'Amount' }
    ];

    const editableAdminInvoiceColumns = [
        { id: 0, label: 'Entity Type' },
        { id: 1, label: 'Entity' },
        { id: 2, label: 'Description' },
        { id: 3, label: 'Amount' }
    ];

    const stockInvoiceColumns = [
        { id: 0, label: 'Part Number' },
        { id: 1, label: 'Qty Ordered' },
        { id: 2, label: 'Qty Received' },
        { id: 3, label: 'Cost on File' },
        { id: 4, label: 'Cost on Invoice' },
        { id: 5, label: 'Line Total' }
    ];

    const vehicleInvoiceColumns = [
        { id: 0, label: 'Stock Number' },
        { id: 1, label: 'Description' },
        { id: 2, label: 'Cost' },
        { id: 3, label: 'Freight' },
        { id: 4, label: 'Total' }
    ];

    const VehicleRegistrationColumns = [
        { id: 0, label: 'Stock Number' },
        { id: 1, label: 'Vehicle' },
        { id: 2, label: 'Description' },
        { id: 3, label: 'Amount' }
    ];

    const checkIfMultipleStockOrder = (orderLines) => {
        let isMultipleSO = false;
        if (orderLines && orderLines[0]?.StockOrderId) {
            isMultipleSO = true;
        }
        return isMultipleSO;
    };

    const findLineEntity = (row) => {
        if (row.Account) {
            return `Account: ${row.Account.name}`;
        } else if (row.Service) {
            return `Purchase Order #${row.Service.id}`;
        } else if (row.Vehicle) {
            return `Stock Number: ${row.Vehicle.stockNumber}`;
        } else if (row.Debtor) {
            let customerName = row.Debtor.companyName
                ? row.Debtor.companyName
                : row.Debtor.firstName + ' ' + row.Debtor.lastName;
            return `Customer Name: ${customerName}`;
        } else if (invoiceDetails[invoiceType.selector].AccountId) {
            return `Account: ${invoiceDetails[invoiceType.selector].Account?.name}`;
        } else if (invoiceDetails[invoiceType.selector].ServiceId) {
            return `Purchase Order #${invoiceDetails[invoiceType.selector].Service?.id}`;
        } else if (invoiceDetails[invoiceType.selector].VehicleId) {
            return `Stock Number: ${invoiceDetails[invoiceType.selector].Vehicle?.stockNumber}`;
        } else if (invoiceDetails[invoiceType.selector].CustomerId) {
            let customerName = invoiceDetails[invoiceType.selector].Customer
                ?.companyName
                ? invoiceDetails[invoiceType.selector].Customer?.companyName
                : invoiceDetails[invoiceType.selector].Customer?.firstName +
                  ' ' +
                  invoiceDetails[invoiceType.selector].Customer?.lastName;
            return `Customer Name: ${customerName}`;
        }
    };

    useEffect(() => {
        let array = [];
        if (
            Object.hasOwn(invoiceDetails, 'StockInvoice') &&
            invoiceDetails.StockInvoice?.orderLines?.length > 0
        ) {
            for (let line of invoiceDetails.StockInvoice.orderLines as any[]) {
                if (line.Account) {
                    array.push({ id: line.id, type: `Account` });
                } else if (line.Service) {
                    array.push({ id: line.id, type: `Service` });
                } else if (line.Vehicle) {
                    array.push({ id: line.id, type: `Vehicle` });
                } else if (line.Debtor) {
                    array.push({ id: line.id, type: `Debtor` });
                } else if (invoiceDetails[invoiceType.selector].AccountId) {
                    array.push({ id: line.id, type: `Account` });
                } else if (invoiceDetails[invoiceType.selector].ServiceId) {
                    array.push({ id: line.id, type: `Service` });
                } else if (invoiceDetails[invoiceType.selector].VehicleId) {
                    array.push({ id: line.id, type: `Vehicle` });
                } else if (invoiceDetails[invoiceType.selector].CustomerId) {
                    array.push({ id: line.id, type: `Debtor` });
                }
            }
            setRowType(array);
        }
        // eslint-disable-next-line
    }, []);

    const getType = (row) => {
        const index = rowType.findIndex((x) => {
            console.log('x.id:', typeof x.id, x.id);
            console.log('row.id:', typeof row.id, row.id);
            return x.id === row.id;
        });

        const newValue = index !== -1 ? rowType[index].type : '';
        return newValue;
    };

    return (
        <Paper>
            <Typography variant="h6">Invoice Lines</Typography>
            <br />
            {invoiceType.type === 'Admin' && (
                <DataTable
                    columns={
                        disabled
                            ? adminInvoiceColumns
                            : editableAdminInvoiceColumns
                    }
                >
                    {disabled
                        ? invoiceDetails[invoiceType.selector]?.orderLines?.map(
                              (row) => (
                                  <TableRow>
                                      <DataCell>{findLineEntity(row)}</DataCell>
                                      <DataCell>{row.description}</DataCell>
                                      <DataCell>
                                          {CurrencyFormatter(row.amount)}
                                      </DataCell>
                                  </TableRow>
                              )
                          )
                        : invoiceDetails.StockInvoice?.orderLines?.map(
                              (row, index) => (
                                  <TableRow>
                                      <DataCell>
                                          <TextField
                                              fullWidth
                                              select
                                              size="small"
                                              value={getType(row)}
                                              onChange={(e) =>
                                                  handleChangeType(
                                                      e.target.value,
                                                      row.id,
                                                      rowType,
                                                      setRowType
                                                  )
                                              }
                                              InputLabelProps={{ shrink: true }}
                                          >
                                              <MenuItem value={'Account'}>
                                                  Account
                                              </MenuItem>
                                              <MenuItem value={'Debtor'}>
                                                  Debtor
                                              </MenuItem>
                                              <MenuItem value={'Vehicle'}>
                                                  Vehicle
                                              </MenuItem>
                                              <MenuItem value={'Service'}>
                                                  Service
                                              </MenuItem>
                                          </TextField>
                                      </DataCell>
                                      <DataCell>
                                          <InvoiceEntitySelector
                                              invoiceType={getType(row)}
                                              rowId={row.id}
                                              invoiceObject={invoiceDetails}
                                              setInvoiceObject={
                                                  setInvoiceDetails
                                              }
                                              handleChange={handleChange}
                                          />
                                      </DataCell>
                                      <DataCell>
                                          <TextField
                                              fullWidth
                                              value={row.description}
                                              onChange={(e) => {
                                                  handleDescriptionChange(
                                                      e.target.value,
                                                      row.id,
                                                      invoiceDetails,
                                                      setInvoiceDetails
                                                  );
                                              }}
                                          />
                                      </DataCell>
                                      <DataCell>
                                          {CurrencyFormatter(row.amount)}
                                      </DataCell>
                                  </TableRow>
                              )
                          )}
                </DataTable>
            )}
            {invoiceType.type === 'Stock' ? (
                checkIfMultipleStockOrder(
                    invoiceDetails[invoiceType.selector]?.orderLines
                ) ? (
                    <DataTable columns={stockInvoiceColumns}>
                        {invoiceDetails[invoiceType.selector]?.orderLines?.map(
                            (orderline) =>
                                orderline.orderLines.map((row) => (
                                    <TableRow>
                                        <DataCellColoured
                                            handleClick={() =>
                                                window.open(
                                                    `/inventory/view/${row.UnitId}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {row.partNumber}
                                        </DataCellColoured>
                                        <DataCell>
                                            {row.quantityOrdered}
                                        </DataCell>
                                        <DataCell>
                                            {row.quantityReceived === ''
                                                ? 0
                                                : row.quantityReceived}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(row.costOnFile)}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(row.itemPrice)}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(row.lineTotal)}
                                        </DataCell>
                                    </TableRow>
                                ))
                        )}
                    </DataTable>
                ) : (
                    <DataTable columns={stockInvoiceColumns}>
                        {invoiceDetails[invoiceType.selector]?.orderLines?.map(
                            (row) => (
                                <TableRow>
                                    <DataCellColoured
                                        handleClick={() =>
                                            window.open(
                                                `/inventory/view/${row.UnitId}`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        {row.partNumber}
                                    </DataCellColoured>
                                    <DataCell>{row.quantityOrdered}</DataCell>
                                    <DataCell>{row.quantityReceived}</DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(row.costOnFile)}
                                    </DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(row.itemPrice)}
                                    </DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(row.lineTotal)}
                                    </DataCell>
                                </TableRow>
                            )
                        )}
                    </DataTable>
                )
            ) : null}
            {invoiceType.type === 'Vehicle' && (
                <DataTable columns={vehicleInvoiceColumns}>
                    {invoiceDetails?.vehicleLines?.map((row) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/vehicles/view/${row.VehicleId}`,
                                        '_blank'
                                    )
                                }
                            >
                                {row.Vehicle?.stockNumber}
                            </DataCellColoured>
                            <DataCell>
                                {row.Vehicle?.year} {row?.Vehicle?.Make?.name}{' '}
                                {row?.Vehicle?.Model?.name}{' '}
                                {row?.Vehicle?.Series?.name}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(
                                    row?.amount ?? row.Vehicle?.costPrice
                                )}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(row.freight)}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(row?.amount + row.freight)}
                            </DataCell>
                            <DataCell>
                                {row?.amount == null ? (
                                    <Tooltip title="Vehicle Cost was not saved with the invoice, the vehicle current base cost is displayed.">
                                        <Icon color="warning">
                                            <WarningAmber />
                                        </Icon>
                                    </Tooltip>
                                ) : null}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            )}
            {invoiceType.type === 'Vehicle Registration' && (
                <DataTable columns={VehicleRegistrationColumns}>
                    {invoiceDetails?.vehicleRegistrationLines?.map((row) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(
                                        `/vehicles/view/${row.VehicleId}`,
                                        '_blank'
                                    )
                                }
                            >
                                {row.Vehicle?.stockNumber}
                            </DataCellColoured>
                            <DataCell>
                                {row.Vehicle?.year} {row?.Vehicle?.Make?.name}{' '}
                                {row?.Vehicle?.Model?.name}{' '}
                                {row?.Vehicle?.Series?.name}
                            </DataCell>
                            <DataCell>{row?.description}</DataCell>
                            <DataCell>
                                {CurrencyFormatter(
                                    row?.amount ?? row.Vehicle?.costPrice
                                )}
                            </DataCell>

                            <DataCell>
                                {row?.amount == null ? (
                                    <Tooltip title="Vehicle Cost was not saved with the invoice, the vehicle current base cost is displayed.">
                                        <Icon color="warning">
                                            <WarningAmber />
                                        </Icon>
                                    </Tooltip>
                                ) : null}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            )}
        </Paper>
    );
};

export default InvoiceLines;
