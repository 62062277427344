// IMPORTS
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import dayjs from 'dayjs';
// LOGIC
import api from '../../../../../api';
import {
    CurrencyFormatter,
    DateFormatter,
    ToFixed
} from '../../../global/logic/Formatters';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
import Header from './ServiceInvoiceComponents/Header';
import InsertNewHeader from './ServiceInvoiceComponents/InsertNewHeader';
// INTERFACES
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import {
    Customer,
    Settings,
    Site
} from '../../../global/interfaces/GeneralInterface';
import { Order } from '../../../global/interfaces/PartsInterface';
import { Service } from '../../../global/interfaces/ServiceInterface';

const PDFCustomerInvoice = async (
    serviceDetails: Service,
    selectedCustomer: Customer,
    vehicleType: string,
    selectedVehicle: Vehicle,
    partSales: Order[],
    isInsurer: boolean
) => {
    let settings: Settings;
    let site: Site;
    let image = null;

    // Get the settings in order to get company details and logo
    await api
        .get(`settingsNextGen/${serviceDetails.SiteId}?includeImage=true`)
        .then((res) => {
            settings = res.data.settings;
            site = res.data.site;

            // Create and format an image object
            let newImg = document.createElement('img');
            newImg.src = res.data.image;
            newImg.className = 'logo';
            image = newImg;
        });

    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });
    let pageCount = 1;

    Header(
        doc,
        serviceDetails,
        selectedCustomer,
        vehicleType,
        selectedVehicle,
        image,
        settings,
        site
    );

    doc.setFont('helvetica', 'bold').setFontSize(15);
    doc.text('Service Details', 105, 120, { align: 'center' });

    let nextY = 125;

    doc.setFontSize(10);

    if (serviceDetails.insuranceClaimNumber) {
        doc.setFont('helvetica', 'bold').text(
            'Insurance Claim Number: ',
            10,
            50
        );
        doc.setFont('helvetica', 'normal').text(
            serviceDetails.insuranceClaimNumber,
            55,
            50
        );
    }

    if (serviceDetails.nextService) {
        doc.setFont('helvetica', 'bold').text('Next Service: ', 20, nextY);
        doc.setFont('helvetica', 'normal').text(
            serviceDetails.nextService,
            43,
            nextY
        );
        nextY += 10;
    }

    if (serviceDetails.recommendations) {
        doc.setFont('helvetica', 'bold').text('Recommendations: ', 20, nextY);
        let split = doc.splitTextToSize(
            serviceDetails.recommendations
                ? serviceDetails.recommendations
                : '',
            170
        );
        let lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;
        let nbOfLine = split.length;
        doc.setFont('helvetica', 'normal').text(split, 20, nextY + 5);
        nextY += 10 + nbOfLine * lineHeight;
    }

    if (nextY >= 200) {
        pageCount = pageCount + 1;
        doc.addPage();
        doc.setPage(pageCount);
        InsertNewHeader(doc, image, serviceDetails, settings, site, pageCount);
        nextY = 60;
    }

    if (serviceDetails.invoiceDescription) {
        doc.setFont('helvetica', 'bold').text('Work Carried Out: ', 20, nextY);
        let split = doc.splitTextToSize(
            serviceDetails.invoiceDescription
                ? serviceDetails.invoiceDescription
                : '',
            170
        );
        doc.setFont('helvetica', 'normal').text(split, 20, nextY + 5);
    }

    pageCount = pageCount + 1;
    doc.addPage();
    doc.setPage(pageCount);

    InsertNewHeader(doc, image, serviceDetails, settings, site, pageCount);
    nextY = 60;

    if (partSales.length > 0 || serviceDetails.addedCosts.length > 0) {
        doc.setFont('helvetica', 'bold').setFontSize(15);
        doc.text('Parts', 14, nextY);

        let partHeader = [];
        let partBody = [];
        if (serviceDetails.jobType === 'Insurance' && !isInsurer) {
            partHeader = [
                ['Part Number', 'Name', 'Quantity', 'Backorder Quantity']
            ];
        } else {
            partHeader = [
                [
                    'Part Number',
                    'Name',
                    'Quantity',
                    'Backorder Quantity',
                    'Price (EX. GST)',
                    'GST',
                    'Total Price INC. GST'
                ]
            ];
        }

        let partsTotalExGst = 0;
        let partsTotalIncGst = 0;
        let gst = 0;

        if (partSales.length > 0) {
            partSales.forEach((sale) => {
                sale.orderLines.forEach((line) => {
                    if (serviceDetails.jobType === 'Insurance' && !isInsurer) {
                        partBody.push([
                            line.partNumber,
                            line.name,
                            line.quantity,
                            line.backorderQuantity
                        ]);
                    } else {
                        let price = line.priceCharged
                            ? parseFloat(line.priceCharged)
                            : parseFloat(line.pricePaid);
                        partsTotalExGst += ToFixed(
                            parseFloat(line.quantity) * price
                        );
                        partsTotalIncGst += ToFixed(
                            parseFloat(line.quantity) * price * 1.1
                        );
                        gst += ToFixed(
                            (parseFloat(line.quantity) * price * 1.1) / 11
                        );
                        partBody.push([
                            line.partNumber,
                            line.name,
                            line.quantity,
                            line.backorderQuantity,
                            CurrencyFormatter(ToFixed(price)),
                            CurrencyFormatter(ToFixed((price * 1.1) / 11)),
                            CurrencyFormatter(
                                ToFixed(parseFloat(line.quantity) * price * 1.1)
                            )
                        ]);
                    }
                });
            });
        }

        if (serviceDetails.addedCosts.length > 0) {
            serviceDetails.addedCosts.forEach((line) => {
                if (serviceDetails.jobType === 'Insurance' && !isInsurer) {
                    partBody.push([
                        line.partNumber,
                        line.name,
                        line.partType === 'fluid'
                            ? line.quantity + ' L'
                            : line.quantity,
                        0
                    ]);
                } else {
                    let price = line.priceCharged
                        ? ToFixed(parseFloat(line.priceCharged))
                        : ToFixed(parseFloat(line.pricePaid));
                    partsTotalExGst += ToFixed(
                        parseFloat(line.quantity) * price
                    );
                    partsTotalIncGst += ToFixed(
                        parseFloat(line.quantity) * price * 1.1
                    );
                    gst += ToFixed(
                        (parseFloat(line.quantity) * price * 1.1) / 11
                    );
                    partBody.push([
                        line.partNumber,
                        line.name,
                        line.partType === 'fluid'
                            ? line.quantity + ' L'
                            : line.quantity,
                        0,
                        line.partType === 'fluid'
                            ? CurrencyFormatter(ToFixed(price)) + '/L'
                            : CurrencyFormatter(ToFixed(price)),
                        CurrencyFormatter(ToFixed((price * 1.1) / 11)),
                        CurrencyFormatter(
                            ToFixed(parseFloat(line.quantity) * price * 1.1)
                        )
                    ]);
                }
            });
        }

        if (serviceDetails.jobType !== 'Insurance' || isInsurer) {
            partBody.push([
                '',
                '',
                '',
                'TOTAL',
                CurrencyFormatter(partsTotalExGst),
                CurrencyFormatter(gst),
                CurrencyFormatter(ToFixed(partsTotalIncGst))
            ]);
        }

        if (partBody.length > 25) {
            let numberOfPageNeeded = Math.ceil(partBody.length / 20); // 20 ITEM PER PAGE (IN CASE OF DOUBLE LINE PART NAME)
            let multipleBodyPart = [];
            for (let x = 1; x !== numberOfPageNeeded + 1; x++) {
                if (x !== numberOfPageNeeded) {
                    multipleBodyPart.push(partBody.slice((x - 1) * 20, x * 20));
                } else {
                    multipleBodyPart.push(partBody.slice((x - 1) * 20));
                }
            }

            for (let array of multipleBodyPart) {
                // Check if we are at the needed page (last array of multipleBodyPart)
                if (
                    array.length ===
                        multipleBodyPart[multipleBodyPart.length - 1].length &&
                    array[0][0] ===
                        multipleBodyPart[multipleBodyPart.length - 1][0][0]
                ) {
                    autoTable(doc, {
                        head: partHeader,
                        body: array,
                        startY: nextY + 5,
                        theme: 'grid',
                        styles: { lineColor: [201, 201, 201], lineWidth: 0.1 },
                        headStyles: {
                            fillColor: [19, 65, 123],
                            textColor: 'white',
                            lineColor: [19, 65, 123]
                        },
                        columnStyles: {
                            2: { cellWidth: 15 },
                            3: { cellWidth: 15 }
                        },
                        margin: { bottom: 20 },
                        willDrawCell: function (data) {
                            var rows = data.table.body;
                            if (
                                data.row.index === rows.length - 1 &&
                                serviceDetails.jobType !== 'Insurance'
                            ) {
                                doc.setFont('helvetica', 'bold').setTextColor(
                                    0
                                );
                            }
                        }
                    });

                    nextY = (doc as any).lastAutoTable.finalY + 10;
                } else {
                    // Print table as normal and go to next page at the end
                    autoTable(doc, {
                        head: partHeader,
                        body: array,
                        startY: nextY + 5,
                        theme: 'grid',
                        styles: { lineColor: [201, 201, 201], lineWidth: 0.1 },
                        headStyles: {
                            fillColor: [19, 65, 123],
                            textColor: 'white',
                            lineColor: [19, 65, 123]
                        },
                        columnStyles: {
                            2: { cellWidth: 15 },
                            3: { cellWidth: 15 }
                        },
                        margin: { bottom: 20 }
                    });
                    pageCount = pageCount + 1;
                    doc.addPage();
                    doc.setPage(pageCount);

                    InsertNewHeader(
                        doc,
                        image,
                        serviceDetails,
                        settings,
                        site,
                        pageCount
                    );
                    nextY = 60;
                }
            }
        } else {
            autoTable(doc, {
                head: partHeader,
                body: partBody,
                startY: nextY + 5,
                theme: 'grid',
                headStyles: {
                    fillColor: [19, 65, 123],
                    textColor: 'white',
                    lineColor: [19, 65, 123]
                },
                margin: { bottom: 20 },
                styles: { lineColor: [201, 201, 201], lineWidth: 0.1 },
                willDrawCell: function (data) {
                    var rows = data.table.body;
                    if (
                        data.row.index === rows.length - 1 &&
                        (serviceDetails.jobType !== 'Insurance' || isInsurer)
                    ) {
                        doc.setFont('helvetica', 'bold').setTextColor(0);
                    }
                }
            });

            nextY = (doc as any).lastAutoTable.finalY + 10;
        }
    }

    if (nextY >= 200) {
        pageCount = pageCount + 1;
        doc.addPage();
        doc.setPage(pageCount);

        InsertNewHeader(doc, image, serviceDetails, settings, site, pageCount);
        nextY = 60;
    }

    if (
        serviceDetails.miscCosts.length > 0 ||
        parseFloat(serviceDetails.environmentalLevy) > 0 ||
        parseFloat(serviceDetails.sundries) > 0 ||
        parseFloat(serviceDetails.subletTotal) > 0 ||
        parseFloat(serviceDetails.labourCost) > 0
    ) {
        doc.setFont('helvetica', 'bold').setFontSize(15);
        doc.text('Misc Costs', 14, nextY);

        let miscHeader = [];
        let miscBody = [];
        if (serviceDetails.jobType === 'Insurance' && !isInsurer) {
            miscHeader = [['Description']];
        } else {
            miscHeader = [
                ['Description', 'Amount (EX. GST)', 'GST', 'Amount (INC. GST)']
            ];
        }

        let miscTotal = 0;
        let miscGST = 0;
        let miscIncGst = 0;

        if (parseFloat(serviceDetails.labourCost) > 0) {
            if (serviceDetails.jobType === 'Insurance' && !isInsurer) {
                miscBody.push(['LABOUR']);
            } else {
                miscBody.push([
                    'LABOUR',
                    CurrencyFormatter(serviceDetails.labourCost),
                    CurrencyFormatter(
                        ToFixed(
                            (parseFloat(serviceDetails.labourCost) * 1.1) / 11
                        )
                    ),
                    CurrencyFormatter(
                        ToFixed(parseFloat(serviceDetails.labourCost) * 1.1)
                    )
                ]);
                miscTotal += parseFloat(serviceDetails.labourCost);
                miscGST += ToFixed(
                    (parseFloat(serviceDetails.labourCost) * 1.1) / 11
                );
                miscIncGst += ToFixed(
                    parseFloat(serviceDetails.labourCost) * 1.1
                );
            }
        }

        if (parseFloat(serviceDetails.environmentalLevy) > 0) {
            if (serviceDetails.jobType === 'Insurance' && !isInsurer) {
                miscBody.push(['ENVIRONMENTAL LEVY']);
            } else {
                miscBody.push([
                    'ENVIRONMENTAL LEVY',
                    CurrencyFormatter(serviceDetails.environmentalLevy),
                    CurrencyFormatter(
                        ToFixed(
                            (parseFloat(serviceDetails.environmentalLevy) *
                                1.1) /
                                11
                        )
                    ),
                    CurrencyFormatter(
                        ToFixed(
                            parseFloat(serviceDetails.environmentalLevy) * 1.1
                        )
                    )
                ]);
                miscTotal += parseFloat(serviceDetails.environmentalLevy);
                miscGST += ToFixed(
                    (parseFloat(serviceDetails.environmentalLevy) * 1.1) / 11
                );
                miscIncGst += ToFixed(
                    parseFloat(serviceDetails.environmentalLevy) * 1.1
                );
            }
        }

        if (parseFloat(serviceDetails.sundries) > 0) {
            if (serviceDetails.jobType === 'Insurance' && isInsurer) {
                miscBody.push(['SUNDRIES']);
            } else {
                miscBody.push([
                    'SUNDRIES',
                    CurrencyFormatter(serviceDetails.sundries),
                    CurrencyFormatter(
                        ToFixed(
                            (parseFloat(serviceDetails.sundries) * 1.1) / 11
                        )
                    ),
                    CurrencyFormatter(
                        ToFixed(parseFloat(serviceDetails.sundries) * 1.1)
                    )
                ]);
                miscTotal += parseFloat(serviceDetails.sundries);
                miscGST += ToFixed(
                    (parseFloat(serviceDetails.sundries) * 1.1) / 11
                );
                miscIncGst += ToFixed(
                    parseFloat(serviceDetails.sundries) * 1.1
                );
            }
        }

        if (parseFloat(serviceDetails.subletTotal) > 0) {
            if (serviceDetails.jobType === 'Insurance' && !isInsurer) {
                miscBody.push(['EXTERNAL WORK']);
            } else {
                miscBody.push([
                    'EXTERNAL WORK',
                    CurrencyFormatter(serviceDetails.subletTotal),
                    CurrencyFormatter(
                        ToFixed(
                            (parseFloat(serviceDetails.subletTotal) * 1.1) / 11
                        )
                    ),
                    CurrencyFormatter(
                        ToFixed(parseFloat(serviceDetails.subletTotal) * 1.1)
                    )
                ]);
                miscTotal += parseFloat(serviceDetails.subletTotal);
                miscGST += ToFixed(
                    (parseFloat(serviceDetails.subletTotal) * 1.1) / 11
                );
                miscIncGst += ToFixed(
                    parseFloat(serviceDetails.subletTotal) * 1.1
                );
            }
        }

        if (serviceDetails.miscCosts.length > 0) {
            serviceDetails.miscCosts.forEach((line) => {
                if (serviceDetails.jobType === 'Insurance' && !isInsurer) {
                    miscBody.push([line.description.toUpperCase()]);
                } else {
                    miscBody.push([
                        line.description.toUpperCase(),
                        CurrencyFormatter(line.amount),
                        CurrencyFormatter(
                            ToFixed((parseFloat(line.amount) * 1.1) / 11)
                        ),
                        CurrencyFormatter(
                            ToFixed(parseFloat(line.amount) * 1.1)
                        )
                    ]);
                    miscTotal += parseFloat(line.amount);
                    miscGST += ToFixed((parseFloat(line.amount) * 1.1) / 11);
                    miscIncGst += ToFixed(parseFloat(line.amount) * 1.1);
                }
            });
        }

        if (serviceDetails.jobType !== 'Insurance' || isInsurer) {
            miscBody.push([
                'TOTAL',
                CurrencyFormatter(ToFixed(miscTotal)),
                CurrencyFormatter(ToFixed(miscGST)),
                CurrencyFormatter(ToFixed(miscIncGst))
            ]);
        }

        autoTable(doc, {
            head: miscHeader,
            body: miscBody,
            startY: nextY + 5,
            theme: 'grid',
            headStyles: {
                fillColor: [19, 65, 123],
                textColor: 'white',
                lineColor: [19, 65, 123]
            },
            margin: { bottom: 20 },
            styles: { lineColor: [201, 201, 201], lineWidth: 0.1 },
            willDrawCell: function (data) {
                var rows = data.table.body;
                if (
                    data.row.index === rows.length - 1 &&
                    (serviceDetails.jobType !== 'Insurance' || isInsurer)
                ) {
                    doc.setFont('helvetica', 'bold').setTextColor(0);
                }
            }
        });

        nextY = (doc as any).lastAutoTable.finalY + 10;
    }

    if (nextY >= 200) {
        pageCount = pageCount + 1;
        doc.addPage();
        doc.setPage(pageCount);

        InsertNewHeader(doc, image, serviceDetails, settings, site, pageCount);
        nextY = 60;
    }

    // Table containing the payment lines
    let paymentHeader = [];
    let paymentBody = [];
    if (serviceDetails.jobType === 'Insurance') {
        paymentHeader = [['Payee', 'Payment Method', 'Payment Date', 'Amount']];
    } else {
        paymentHeader = [['Payment Method', 'Payment Date', 'Amount']];
    }

    if (serviceDetails.jobType === 'Insurance' && isInsurer) {
        serviceDetails.paymentLines.forEach((line) => {
            paymentBody.push([
                line.payingCustomerName.toUpperCase(),
                line.type.toUpperCase(),
                DateFormatter(line.date),
                CurrencyFormatter(line.amount)
            ]);
        });
    } else if (serviceDetails.jobType === 'Insurance' && !isInsurer) {
        serviceDetails.paymentLines.forEach((line) => {
            if (line.payingCustomerId === serviceDetails.CustomerId) {
                paymentBody.push([
                    line.payingCustomerName.toUpperCase(),
                    line.type.toUpperCase(),
                    DateFormatter(line.date),
                    CurrencyFormatter(line.amount)
                ]);
            }
        });
    } else {
        serviceDetails.paymentLines.forEach((line) => {
            paymentBody.push([
                line.type.toUpperCase(),
                `${DateFormatter(line.date)} ${dayjs(line.timestamp).format('h:mm A')}`,
                CurrencyFormatter(line.amount)
            ]);
        });
    }

    if (paymentBody.length > 0) {
        doc.setFont('helvetica', 'bold').setFontSize(15);
        doc.text('Payments', 14, nextY);
        InvoiceTable(doc, nextY + 5, paymentHeader, paymentBody);
        nextY = (doc as any).lastAutoTable.finalY + 5;
    }

    let totalBody = [];
    if (isInsurer) {
        totalBody.push([
            'TOTAL EX. GST',
            CurrencyFormatter(
                ToFixed(parseFloat(serviceDetails.totalPrice) / 1.1)
            )
        ]);
        totalBody.push([
            'GST',
            CurrencyFormatter(
                ToFixed(parseFloat(serviceDetails.totalPrice) / 11)
            )
        ]);
        totalBody.push([
            'TOTAL INC. GST',
            CurrencyFormatter(serviceDetails.totalPrice)
        ]);
        if (parseFloat(serviceDetails.customerExcess) > 0) {
            totalBody.push([
                'CUSTOMER EXCESS',
                CurrencyFormatter(serviceDetails.customerExcess)
            ]);
            totalBody.push([
                'BALANCE',
                CurrencyFormatter(
                    parseFloat(serviceDetails.totalPrice) -
                        parseFloat(serviceDetails.customerExcess)
                )
            ]);
        }
        if (paymentBody.length > 0) {
            let paid = serviceDetails.paymentLines.reduce(
                (acc, value) => acc + value.amount,
                0
            );
            let due = parseFloat(serviceDetails.totalPrice) - paid;
            if (parseFloat(serviceDetails.customerExcess) > 0) {
                let excessPaid = serviceDetails.paymentLines.reduce(
                    (acc, value) =>
                        acc +
                        (value.payingCustomerId === serviceDetails.CustomerId
                            ? value.amount
                            : 0),
                    0
                );
                let excessDue =
                    parseFloat(serviceDetails.customerExcess) - excessPaid;

                paid = serviceDetails.paymentLines.reduce(
                    (acc, value) =>
                        acc +
                        (value.payingCustomerId !== serviceDetails.CustomerId
                            ? value.amount
                            : 0),
                    0
                );
                due =
                    parseFloat(serviceDetails.totalPrice) -
                    parseFloat(serviceDetails.customerExcess) -
                    paid;

                totalBody.push([
                    'EXCESS BALANCE',
                    CurrencyFormatter(excessDue)
                ]);
                totalBody.push(['DUE', CurrencyFormatter(due)]);
            } else {
                totalBody.push(['DUE', CurrencyFormatter(due)]);
            }
        }
    } else if (serviceDetails.jobType === 'Insurance') {
        totalBody.push([
            'CUSTOMER EXCESS',
            CurrencyFormatter(serviceDetails.customerExcess)
        ]);
        totalBody.push([
            'TOTAL EX. GST',
            CurrencyFormatter(parseFloat(serviceDetails.customerExcess) / 1.1)
        ]);
        totalBody.push([
            'GST',
            CurrencyFormatter(parseFloat(serviceDetails.customerExcess) / 11)
        ]);
        totalBody.push([
            'TOTAL INC. GST',
            CurrencyFormatter(serviceDetails.customerExcess)
        ]);
        if (paymentBody.length > 0) {
            let paid = serviceDetails.paymentLines.reduce(
                (acc, value) =>
                    acc +
                    (value.payingCustomerId === serviceDetails.CustomerId
                        ? value.amount
                        : 0),
                0
            );
            let due = parseFloat(serviceDetails.customerExcess) - paid;
            if (parseFloat(serviceDetails.customerExcess) > 0) {
                totalBody.push(['EXCESS DUE', CurrencyFormatter(due)]);
            }
        }
    } else {
        totalBody.push([
            'TOTAL EX. GST',
            CurrencyFormatter(parseFloat(serviceDetails.totalPrice) / 1.1)
        ]);
        totalBody.push([
            'GST',
            CurrencyFormatter(parseFloat(serviceDetails.totalPrice) / 11)
        ]);
        totalBody.push([
            'TOTAL INC. GST',
            CurrencyFormatter(serviceDetails.totalPrice)
        ]);
        if (paymentBody.length > 0) {
            let paid = serviceDetails.paymentLines.reduce(
                (acc, value) => acc + value.amount,
                0
            );
            let due = parseFloat(serviceDetails.totalPrice) - paid;
            totalBody.push(['DUE', CurrencyFormatter(due)]);
        }
    }

    autoTable(doc, {
        body: totalBody,
        startY: nextY,
        theme: 'plain',
        margin: { bottom: 20, left: 135 },
        willDrawCell: function (data) {
            var rows = data.table.body;
            if (isInsurer && parseFloat(serviceDetails.customerExcess) > 0) {
                if (
                    data.row.index === rows.length - 1 ||
                    data.row.index === rows.length - 3
                ) {
                    doc.setFont('helvetica', 'bold');
                }
            } else {
                if (data.row.index === rows.length - 1) {
                    doc.setFont('helvetica', 'bold');
                }
            }
        }
    });

    // Open the pdf
    window.open(doc.output('bloburl'));
};

export default PDFCustomerInvoice;
