// IMPORTS
import { Grid, TableRow, TextField } from '@mui/material';
import { Receipt } from '@mui/icons-material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
// COMPONENTS
import TillSelector from '../../../global/paymentComponents/TillSelector';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../global/tableComponents/DataTable';
import Fab from '../../../global/Fab';
// INTERFACES
import {
    Credit,
    Order,
    Voucher
} from '../../../global/interfaces/PartsInterface';
import { Customer } from '../../../global/interfaces/GeneralInterface';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import CreditPDF from '../logic/CreditPDF';
import GetCredit from '../logic/GetCredit';

interface TempOrder extends Order {
    orderLines?: {
        rowId?: number;
        UnitId?: number;
        name?: string;
        partNumber?: string;
        priceRRP?: number;
        priceCharged?: string;
        pricePaid?: string;
        costPriceDaily?: number;
        costPriceAverage?: number;
        quantity?: string;
        backorderQuantity?: string;
        incGst?: boolean;
        totalPrice?: number;
        gstFree?: boolean;
        totalCost?: number;
        BinLocationIds?: { name: string }[];
        SOH?: number;
        RES?: number;
        tradeRRP?: number;
        isTradePrice?: boolean;
        margin?: number;
        stockOrderType?: boolean;
        collecting?: boolean;
        inactive?: boolean;
        linkedSaleId?: number;
        quantityReturned?: number; // used in crediting order
        backorderQuantityReturned?: number; // used in crediting order
        dateRefunded?: string; // used in crediting order
        creditId?: number; // used in crediting order
        amountRefunded?: number; // used in crediting order
        // USED ONLY HERE
        refundableQuantity?: number;
        backorderableQuantity?: number;
        quantityReturning?: number;
        backorderQuantityReturning?: number;
        amountRefunding?: number;
    }[];
}

interface CreditProps {
    saleDetails: TempOrder;
    creditId: number | string;
    customerDetails: Customer;
    vouchers: { voucher: Voucher; qtyReturning: number }[];
    setSelectedTill: Dispatch<SetStateAction<number>>;
    setTitle: Dispatch<SetStateAction<string>>;
    setSubtitle: Dispatch<SetStateAction<string>>;
}

/**
 * CreditDrawerContent
 * Show important details of an existing credit
 * @author Estienne
 * @param CreditProps
 * @returns {JSX} credit details and all parts on that credit
 */
const CreditDrawerContent = ({
    saleDetails,
    creditId,
    customerDetails,
    vouchers,
    setSelectedTill,
    setTitle,
    setSubtitle
}: CreditProps) => {
    const [credit, setCredit] = useState<Credit>(null);
    const columns = [
        { id: 0, label: 'Part Number' },
        { id: 1, label: 'Name' },
        { id: 2, label: 'Qty Returned' },
        { id: 3, label: 'Amount Refunded' }
    ];

    // Check whether we need a single credit or all credits on the sale
    useEffect(() => {
        if (typeof creditId == 'number') {
            GetCredit(
                creditId.toString(),
                false,
                setCredit,
                setTitle,
                setSubtitle
            );
        } else {
            GetCredit(
                saleDetails.id.toString(),
                true,
                setCredit,
                setTitle,
                setSubtitle
            );
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {credit != null ? (
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TillSelector
                            selectedTillId={
                                credit.Order.paymentLines.filter(
                                    (line) => line?.creditId === credit.id
                                )[0]?.TillId
                            }
                            setSelectedTillId={setSelectedTill}
                            department="parts"
                            size="small"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            disabled
                            size="small"
                            label="Refund Method"
                            value={credit.Order.paymentLines
                                .filter(
                                    (line) => line?.creditId === credit.id
                                )[0]
                                ?.type.toUpperCase()}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            disabled
                            size="small"
                            label="Reason for return"
                            value={credit?.reasonForCredit}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                        <DataTable columns={columns}>
                            {credit.Order.orderLines.map((row, index) => {
                                return (
                                    <TableRow key={index}>
                                        {row?.creditId === credit.id ? (
                                            <>
                                                <DataCellColoured
                                                    handleClick={() =>
                                                        window.open(
                                                            `/inventory/view/${row.partNumber}`
                                                        )
                                                    }
                                                >
                                                    {row.partNumber}
                                                </DataCellColoured>
                                                <DataCell>{row.name}</DataCell>
                                                <DataCell>
                                                    {parseInt(row.quantity) < 0
                                                        ? Math.abs(
                                                              parseInt(
                                                                  row.quantity
                                                              )
                                                          )
                                                        : Math.abs(
                                                              parseInt(
                                                                  row.backorderQuantity
                                                              )
                                                          )}
                                                </DataCell>
                                                <DataCell>
                                                    {CurrencyFormatter(
                                                        row.amountRefunded
                                                    ) ?? ''}
                                                </DataCell>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </TableRow>
                                );
                            })}
                            {credit?.freightRefunded ? (
                                <>
                                    <DataCell />
                                    <DataCell>{'FREIGHT'}</DataCell>
                                    <DataCell />
                                    <DataCell>
                                        {CurrencyFormatter(
                                            credit?.freightRefunded
                                        )}
                                    </DataCell>
                                </>
                            ) : (
                                ''
                            )}
                            {
                                <>
                                    {vouchers.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                {row.voucher.CreditId ===
                                                credit.id ? (
                                                    <>
                                                        <DataCell />
                                                        <DataCell>{`VOUCHER #${row.voucher.id}`}</DataCell>
                                                        <DataCell />
                                                        <DataCell>
                                                            {CurrencyFormatter(
                                                                row.voucher
                                                                    .totalPrice
                                                            )}
                                                        </DataCell>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                </>
                            }
                        </DataTable>
                    </Grid>
                </Grid>
            ) : null}

            <Fab
                editIcon={false}
                customIcon={<Receipt />}
                noSession={true}
                onClick={() =>
                    CreditPDF({
                        siteId: parseInt(localStorage.getItem('SiteId')),
                        saleDetails: saleDetails,
                        customerDetails: customerDetails,
                        vouchers: vouchers,
                        allCredits: false,
                        credit: credit
                    })
                }
            />
        </>
    );
};

export default CreditDrawerContent;
