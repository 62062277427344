import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';

interface LoginDetails {
    username: string;
    password: string;
    usernameError: boolean;
    passwordError: boolean;
    usernameErrorMessage: string;
    passwordErrorMessage: string;
}

const HandleLogin = (
    e,
    loginDetails: LoginDetails,
    setLoginDetails: Dispatch<SetStateAction<LoginDetails>>,
    setPasswordChange: Dispatch<SetStateAction<boolean>>,
    setToken
) => {
    e.preventDefault();

    if (
        loginDetails.username.length === 0 &&
        loginDetails.password.length === 0
    ) {
        setLoginDetails({
            ...loginDetails,
            usernameError: true,
            usernameErrorMessage: 'A username is required.',
            passwordError: true,
            passwordErrorMessage: 'A password is required.'
        });
        return;
    }
    if (loginDetails.username.length === 0) {
        setLoginDetails({
            ...loginDetails,
            usernameError: true,
            usernameErrorMessage: 'A username is required.'
        });
        return;
    }

    if (loginDetails.password.length === 0) {
        setLoginDetails({
            ...loginDetails,
            passwordError: true,
            passwordErrorMessage: 'A password is required.'
        });
        return;
    }
    if (
        loginDetails.username.length !== 0 &&
        loginDetails.password.length !== 0
    ) {
        api.post('loginNextGen', {
            username: loginDetails.username,
            password: loginDetails.password
        }).then((res) => {
            let error = false;
            if (res.data.error === 'Invalid Password') {
                setLoginDetails({
                    ...loginDetails,
                    passwordError: true,
                    passwordErrorMessage: 'Invalid Password.'
                });
                error = true;
                return;
            } else {
                setLoginDetails({
                    ...loginDetails,
                    passwordError: false,
                    passwordErrorMessage: null
                });
            }

            if (res.data.error === 'User does not exist') {
                setLoginDetails({
                    ...loginDetails,
                    usernameError: true,
                    usernameErrorMessage: 'Invalid Username.'
                });
                error = true;
                return;
            } else {
                setLoginDetails({
                    ...loginDetails,
                    usernameError: false,
                    usernameErrorMessage: null
                });
            }

            if (res.data.error === 'User no longer employed') {
                setLoginDetails({
                    ...loginDetails,
                    usernameError: true,
                    usernameErrorMessage:
                        'User is no longer employed and access has been revoked.'
                });
                error = true;
            }

            if (!error) {
                if (res.data.changePassword) {
                    setPasswordChange(true);
                    api.defaults.headers.common['Authorization'] =
                        'Bearer ' + localStorage.getItem('token');
                } else {
                    setToken(res.data.token);
                    api.defaults.headers.common['Authorization'] =
                        'Bearer ' + localStorage.getItem('token');
                    localStorage.setItem(
                        'SiteId',
                        res.data.userData.SiteId ?? 1
                    );
                    window.location.href = '/';
                }
            }
        });
    }
};

export default HandleLogin;
