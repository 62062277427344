import api from '../../../../../api';

const HandleAddPart = (
    missingParts,
    setMissingParts,
    newPart,
    selectedOrder,
    orderline,
    setAddPartDialogOpen,
    setButtonLoading,
    setSnackbar
) => {
    if (newPart.partNumber == null || newPart.partNumber === '') {
        setSnackbar({
            open: true,
            severity: 'error',
            message: 'You must provide a part number for the part.'
        });
        return;
    }
    if (newPart.name == null || newPart.name === '') {
        setSnackbar({
            open: true,
            severity: 'error',
            message: 'You must provide a name for the part.'
        });
        return;
    }
    if (newPart.supplier == null || !newPart.supplier?.id) {
        setSnackbar({
            open: true,
            severity: 'error',
            message: 'You must select a supplier for the part.'
        });
        return;
    }
    if (
        newPart.costPriceDaily == null ||
        newPart.costPriceDaily === '' ||
        parseFloat(newPart.costPriceDaily) < 0
    ) {
        setSnackbar({
            open: true,
            severity: 'error',
            message:
                'You must provide a cost price daily for the part that is 0 or above.'
        });
        return;
    }
    if (
        newPart.priceRRP == null ||
        newPart.priceRRP === '' ||
        parseFloat(newPart.priceRRP) < 0
    ) {
        setSnackbar({
            open: true,
            severity: 'error',
            message: 'You must provide an RRP for the part that is 0 or above.'
        });
        return;
    }

    let supplierObj = [
        {
            id: 1,
            SupplierId: newPart.supplier.id,
            code: newPart.supplier.code,
            name: newPart.supplier.name,
            SiteId: parseInt(localStorage.getItem('SiteId'))
        }
    ];
    newPart.SupplierIds = supplierObj;

    setButtonLoading(true);
    api.post(`handlePartFromOnlineOrder`, {
        newPart: newPart,
        selectedOrder: selectedOrder,
        orderline: orderline
    }).then((res) => {
        if (res.status === 200) {
            if (!res.data.orderComplete) {
                // If we get here it means the part was added but there are still more parts on the order that need adding
                let currentMissingParts = [...missingParts];

                // Find the index for the order that was updated and replace it with a new one
                let index = currentMissingParts.findIndex(
                    (x) =>
                        x.oraOrder.OnlineOrderId ===
                        selectedOrder.oraOrder.OnlineOrderId
                );
                currentMissingParts[index] = res.data.selectedOrder;

                // Update the object
                setMissingParts(currentMissingParts);
            } else {
                setSnackbar({
                    open: true,
                    severity: 'success',
                    message: 'Order has been created in Ora.'
                });

                // If we get here it means the part was added and the order is now complete
                let currentMissingParts = [...missingParts];

                // Filter out the compelted order
                currentMissingParts = currentMissingParts.filter(
                    (x) =>
                        x.oraOrder.OnlineOrderId !==
                        selectedOrder.oraOrder.OnlineOrderId
                );

                // Update the object
                setMissingParts(currentMissingParts);

                // If there are no more missing part orders then refresh the page
                if (currentMissingParts.length === 0) {
                    window.location.reload();
                }
            }

            setAddPartDialogOpen(false);
        } else {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Whoops! Something went wrong on our end.',
                subMessage: 'Please contact your IT department.'
            });
        }
        setButtonLoading(false);
    });
};

export default HandleAddPart;
