import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

const HandleUnreconcileTransaction = (
    id: number,
    setButtonLoading: Dispatch<SetStateAction<boolean>>,
    showSnackbar: showSnackbar
) => {
    setButtonLoading(true);
    api.put(`unreconcileManualTransaction/${id}`).then((res) => {
        if (res.status === 200) {
            showSnackbar(
                'Transaction Unreconciled.',
                'The page will now refresh.'
            );
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
            setButtonLoading(false);
        }
    });
};

export default HandleUnreconcileTransaction;
