// IMPORTS
import { useEffect, useState } from 'react';
import { Dialog, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Tabs from '../../global/Tabs';
import CustomerTable from '../../global/customerFormComponents/CustomerTable';
import TradeVehicles from './components/TradeVehicles';
import Fab from '../../global/Fab';
import EmptyMakeTableDialogContent from '../emptyTableDialog/EmptyMakeTableDialogContent';
import EmptyModelTableDialogContent from '../emptyTableDialog/EmptyModelTableDialogContent';
import { ImportMakeCSVContent } from '../emptyTableDialog/ImportMakeCSVContent';
import { ImportModelCSVContent } from '../emptyTableDialog/ImportModelCSVContent';
// LOGIC
import GetVehicleSpecificsLists from '../invoiceVehicle/logic/GetVehicleSpecificLists';
import HandleSubmitTrades from './logic/HandleSubmitTrades';
import HandleGetTrades from './logic/HandleGetTrades';

// INTERFACES
import { Creditor, Customer } from '../../global/interfaces/GeneralInterface';
import {
    BodyType,
    DriveType,
    FuelType,
    Make,
    Model,
    Series,
    TradeIn
} from '../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { withSnackbar } from '../../global/WrappingSnackbar';

const TradeVehicle = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    let { id } = useParams<{ id: string }>();
    const [readOnly, setReadOnly] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
    const [tradeVehicles, setTradeVehicles] = useState<TradeIn[]>([]);

    const [gstType, setGstType] = useState<string>('INC');

    const [emptyField, setEmptyField] = useState<string>('');
    const [specificLists, setSpecificLists] = useState<{
        Make: Make[];
        Model: Model[];
        Series: Series[];
        FuelType: FuelType[];
        Creditors: Creditor[];
        DriveType: DriveType[];
        BodyType: BodyType[];
    }>({
        Make: [
            { id: 0, name: '' },
            { id: 0, name: '' }
        ], // Put two element in it to prevent dialog appearing on page laod
        Model: [
            { id: 0, name: '', MakeId: 0 },
            { id: 0, name: '', MakeId: 0 }
        ], // Put two element in it to prevent dialog appearing on page laod
        Series: [],
        FuelType: [],
        Creditors: [],
        DriveType: [],
        BodyType: []
    });

    useEffect(() => {
        GetVehicleSpecificsLists({ setSpecificLists: setSpecificLists });
        if (id) {
            HandleGetTrades(
                parseInt(id),
                setTradeVehicles,
                setSelectedCustomer,
                setReadOnly,
                setGstType
            );
        }
        // eslint-disable-next-line
    }, []);

    const tabContent = [
        {
            id: 0,
            title: 'Select Customer',
            content: (
                <>
                    <CustomerTable
                        readOnly={readOnly}
                        selectedEntity={selectedCustomer}
                        setSelectedEntity={setSelectedCustomer}
                        includeShippingDrawer={false}
                    />
                    {/* {id ? (
                        <Fab
                            customIcon={<Assignment />}
                            onClick={
                                () => {}
                                PDFVendorStatement(
                                    tradeVehicles[0],
                                    selectedCustomer,
                                    tradeVehicles
                                )
                            }
                        />
                    ) : null} */}
                </>
            )
        },
        {
            id: 1,
            title: 'Trade Vehicles',
            content: (
                <>
                    <TradeVehicles
                        id={parseInt(id)}
                        tradeVehicles={tradeVehicles}
                        setTradeVehicles={setTradeVehicles}
                        readOnly={readOnly}
                        showSnackbar={showSnackbar}
                        gstType={gstType}
                        setGstType={setGstType}
                    />
                    {id ? //     onClick={ //     customIcon={<Assignment />} // <Fab
                    //         () => {}
                    //         PDFVendorStatement(
                    //             tradeVehicles[0],
                    //             selectedCustomer,
                    //             tradeVehicles
                    //         )
                    //     }
                    // />
                    null : (
                        <Fab
                            editIcon={false}
                            onClick={() =>
                                HandleSubmitTrades(
                                    selectedCustomer,
                                    tradeVehicles,
                                    gstType,
                                    showSnackbar
                                )
                            }
                        />
                    )}
                </>
            )
        }
    ];

    return (
        <PageWrapper>
            <Typography variant="h4">New Trade</Typography>
            <Tabs tabContent={tabContent} />

            <Dialog
                open={
                    (specificLists.Make.length === 0 ||
                        specificLists.Model.length === 0) &&
                    emptyField === ''
                }
                onClose={() => setEmptyField('noDialog')}
                maxWidth="md"
                fullWidth
            >
                {specificLists.Make.length === 0 ? (
                    <EmptyMakeTableDialogContent
                        setEmptyField={setEmptyField}
                    />
                ) : specificLists.Model.length === 0 ? (
                    <EmptyModelTableDialogContent
                        setEmptyField={setEmptyField}
                    />
                ) : null}
            </Dialog>

            <Dialog
                open={
                    (specificLists.Make.length === 0 ||
                        specificLists.Model.length === 0) &&
                    (emptyField === 'Make' || emptyField === 'Model')
                }
                onClose={() => setEmptyField('')}
                maxWidth="md"
                fullWidth
            >
                {emptyField === 'Make' ? (
                    <ImportMakeCSVContent showSnackbar={showSnackbar} />
                ) : emptyField === 'Model' ? (
                    <ImportModelCSVContent showSnackbar={showSnackbar} />
                ) : null}
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(TradeVehicle);
