// IMPORTS
import { useState } from 'react';
import { Typography, TableRow, IconButton } from '@mui/material';
import { Add, FileDownload, Print } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import VoucherTableFilter from './components/VoucherTableFilter';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import Drawer from '../../global/Drawer';
import TransactionDrawerContent from './components/TransactionDrawerContent';
// LOGIC
import HandleDownloadResults from '../../global/logic/HandleDownloadResults';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../global/logic/Formatters';
import PDFVoucherInvoice from './logic/PDFVoucherInvoice';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { Voucher } from '../../global/interfaces/PartsInterface';
import ClickableTypography from '../../global/ClickableTypography';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    voucherFilter: { hasBalance: null, hasLinkedOrder: null }
};
const clearedSort = ['id', 'DESC'];

/**
 * VoucherTable
 * Display vouchers in an organised table
 * @author Sienna
 * @returns {JSX} a table of vouchers
 */
const VoucherTable = () => {
    const history = useHistory();
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<Voucher[]>([]);
    const [openTransactionDrawer, setOpenTransactionDrawer] = useState(false);
    const [selectedVoucher, setSelectedVoucher] = useState<Voucher>();

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Gift Voucher',
            onClick: () => history.push('/inventory/newSale')
        },
        {
            icon: <FileDownload />,
            name: 'Export Current Results',
            onClick: () => handleDownloadResults()
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
        { id: 1, label: 'Voucher Code', width: 100 },
        { id: 2, label: 'Total Price', width: 100 },
        { id: 3, label: 'Balance', width: 100 },
        { id: 4, label: 'Customer Name', width: 150 },
        {
            id: 5,
            label: 'Date Created',
            name: 'createdAt',
            sort: true,
            width: 150
        },
        { id: 6, label: 'Linked Sale', width: 100 },
        { id: 7, label: 'User', width: 100 },
        { id: 8, label: '', width: 100 }
    ];

    // Handle downloading the csv
    const handleDownloadResults = () => {
        HandleDownloadResults(
            sort,
            'voucherSearchNextGen',
            handleRequestCreate(),
            'OraVoucherResults.csv',
            [
                'ID',
                'Voucher Code',
                'Total Price',
                'Balance',
                'Customer First Name',
                'Customer Last Name',
                'Date Created',
                'Linked Order',
                'Linked Vehicle Sale',
                'Online Voucher Code',
                'User'
            ],
            [
                'id',
                'voucherCode',
                'totalPrice',
                'currentBalance',
                'Customer.firstName',
                'Customer.lastName',
                'createdAt',
                'Order.id',
                'VehicleSale.id',
                'onlineVoucherCode',
                'User.username'
            ]
        );
    };

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let hasBalance = '&hasBalance=' + filter.voucherFilter.hasBalance;
        let hasLinkedOrder =
            '&hasLinkedOrder=' + filter.voucherFilter.hasLinkedOrder;

        let apiString = `${hasBalance}${hasLinkedOrder}`;
        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.voucherFilter.hasBalance != null ||
            filter.voucherFilter.hasLinkedOrder != null
        ) {
            isActive = true;
        }

        return isActive;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Vouchers</Typography>
            <br />
            <TableSearch
                searchTitle="Search Vouchers"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="voucherSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            >
                <VoucherTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() => {
                                    setOpenTransactionDrawer(true);
                                    setSelectedVoucher(row);
                                }}
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>...{row.voucherCode.slice(-4)}</DataCell>
                            <DataCell>
                                {CurrencyFormatter(row.totalPrice)}
                            </DataCell>
                            <DataCell>
                                {CurrencyFormatter(row.currentBalance)}
                            </DataCell>
                            <DataCell>
                                {row?.Customer?.firstName?.toUpperCase()}{' '}
                                {row?.Customer?.lastName?.toUpperCase()}
                            </DataCell>
                            <DataCell>{DateFormatter(row.createdAt)}</DataCell>
                            <DataCell>
                                {row.OrderId ? (
                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'inline-block' }}
                                    >
                                        P&A Sale:&nbsp;
                                        <ClickableTypography
                                            string={row.OrderId.toString()}
                                            link={`/inventory/viewSale/${row.OrderId}`}
                                            variant="body2"
                                        />
                                    </Typography>
                                ) : row.VehicleSaleId ? (
                                    <Typography
                                        variant="body2"
                                        sx={{ display: 'inline-block' }}
                                    >
                                        Vehicle Sale:&nbsp;
                                        <ClickableTypography
                                            string={row.VehicleSaleId.toString()}
                                            link={`/inventory/viewSale/${row.VehicleSaleId}`}
                                            variant="body2"
                                        />
                                    </Typography>
                                ) : row.onlineVoucherCode ? (
                                    'Online Voucher: ' + row.onlineVoucherCode
                                ) : (
                                    ''
                                )}
                            </DataCell>
                            <DataCell>
                                {row?.User?.firstName?.toUpperCase()}{' '}
                                {row?.User?.lastName
                                    ? row?.User?.lastName
                                          ?.charAt(0)
                                          ?.toUpperCase() + '.'
                                    : ''}
                            </DataCell>
                            <DataCell>
                                <IconButton
                                    onClick={() => PDFVoucherInvoice(row.id)}
                                >
                                    <Print />
                                </IconButton>
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Drawer
                openDrawer={openTransactionDrawer}
                setOpenDrawer={setOpenTransactionDrawer}
                title={'Gift Voucher Transactions'}
                subTitle={`${selectedVoucher?.voucherCode} -  ${selectedVoucher?.Customer?.firstName} ${selectedVoucher?.Customer?.lastName}`}
            >
                <TransactionDrawerContent
                    selectedVoucher={selectedVoucher}
                    setSelectedVoucher={setSelectedVoucher}
                />
            </Drawer>
        </PageWrapper>
    );
};

export default VoucherTable;
