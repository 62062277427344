// LOGIC
import PDFServiceCustomerReceipt from './PDFServiceCustomerReceipt';
import api from '../../../../../api';
// INTERFACE
import { PaymentLine } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import {
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';
import { PaymentTotal } from '../../newBooking/logic/GetTotals';
import { Order } from '../../../global/interfaces/PartsInterface';

const SubmitPayment = (
    id: number,
    paymentLines: PaymentLine[],
    serviceDetails: Service,
    payingCustomerType: string,
    partSales: Order[],
    sublets: PurchaseOrder[],
    showSnackbar: showSnackbar
) => {
    let obj = {
        serviceDetails: serviceDetails,
        paymentLines: paymentLines,
        totalPaid: PaymentTotal(paymentLines),
        partSales: partSales,
        sublets: sublets
    };
    api.put(`payForService/${id}`, obj).then((res) => {
        if (res.status === 200) {
            showSnackbar('Payment Successful.');
            PDFServiceCustomerReceipt(id, payingCustomerType);
        } else {
            if (res.data.indexOf('Conflict with Committed PNL') === -1) {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact the IT department.',
                    'error'
                );
            } else {
                showSnackbar(
                    'Payment not updated, please contact your manager or IT department.',
                    res.data,
                    'error'
                );
            }
        }
    });
};

export default SubmitPayment;
