// LOGIC
import { ToFixed } from '../../../global/logic/Formatters';
// INTERFACES
import { PaymentLine } from '../../../global/interfaces/GeneralInterface';
import { Order } from '../../../global/interfaces/PartsInterface';

export function FreightTotal(
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[]
) {
    let freightTotal = 0;

    // Add up the totals
    extraCosts.forEach((cost) => {
        if (cost.type === 'freight') {
            freightTotal += parseFloat(cost.amount);
        }
    });

    return ToFixed(freightTotal);
}

export function VoucherTotal(
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[]
) {
    let voucherTotal = 0;

    // Add up the totals
    extraCosts.forEach((cost) => {
        if (cost.type === 'voucher') {
            voucherTotal += parseFloat(cost.amount);
        }
    });

    return ToFixed(voucherTotal);
}

export function ExtraCostsTotal(
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[]
) {
    let extraCostsTotal = FreightTotal(extraCosts) + VoucherTotal(extraCosts);
    return ToFixed(extraCostsTotal);
}

export function PartsTotal(saleDetails: Order, includeInactive?: boolean) {
    let partsTotal = 0;
    for (let line of saleDetails.orderLines) {
        if ((includeInactive && line.inactive) || !line.inactive) {
            let price =
                line.amountRefunded > 0 && parseInt(line.quantity) < 0
                    ? Math.abs(line.amountRefunded / parseInt(line.quantity))
                    : parseFloat(line.pricePaid);
            partsTotal +=
                (parseInt(line.quantity) + parseInt(line.backorderQuantity)) *
                price;
        }
    }
    return ToFixed(partsTotal);
}

export function SaleTotal(
    saleDetails: Order,
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[],
    includeInactive?: boolean
) {
    let extraCostsTotal = FreightTotal(extraCosts) + VoucherTotal(extraCosts);
    let partsTotal = PartsTotal(saleDetails, includeInactive);
    return ToFixed(partsTotal + extraCostsTotal);
}

export function TotalCost(saleDetails: Order, includeInactive?: boolean) {
    let totalPartCost = 0;

    saleDetails.orderLines.forEach((line) => {
        if ((includeInactive && line.inactive) || !line.inactive) {
            let costPrice = line.costPriceAverage
                ? line.costPriceAverage
                : line.costPriceDaily;
            totalPartCost +=
                (parseInt(line.quantity) + parseInt(line.backorderQuantity)) *
                costPrice;
        }
    });

    return ToFixed(totalPartCost);
}

export function PaymentTotal(
    paymentLines: PaymentLine[],
    includeInactive?: boolean
) {
    let total = 0;

    paymentLines.forEach((payment) => {
        if (
            !payment.removed &&
            ((includeInactive && payment.inactive) || !payment.inactive)
        )
            total += payment.amount;
    });

    return ToFixed(total);
}

export function Balance(
    saleDetails: Order,
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[],
    paymentLines: PaymentLine[]
) {
    let saleTotal = SaleTotal(
        saleDetails,
        extraCosts,
        saleDetails.status === 'Sale' ? true : false
    );
    let paymentTotal = PaymentTotal(
        paymentLines,
        saleDetails.status === 'Sale' ? true : false
    );
    let balance = saleTotal - paymentTotal;
    return ToFixed(balance);
}

export function DueNow(
    selectedType: string,
    saleDetails: Order,
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[],
    paymentLines: PaymentLine[],
    includeInactive?: boolean
) {
    let dueNow = 0;

    saleDetails.orderLines.forEach((line) => {
        if ((includeInactive && line.inactive) || !line.inactive) {
            if (saleDetails.status === 'Sale') {
                dueNow += parseInt(line.quantity) * parseFloat(line.pricePaid);
            } else if (saleDetails.status === 'Proforma') {
                if (line.collecting) {
                    dueNow +=
                        parseInt(line.quantity) * parseFloat(line.pricePaid);
                }
            }
        }
    });

    if (saleDetails.status === 'Sale') {
        return Balance(saleDetails, extraCosts, paymentLines);
    } else {
        // due now should be price of collected items minus amount paid for current sale
        // cannot use saleDetails.amountPaid as this amount will split between multiple sales
        const amountPaidForCurrentSale =
            selectedType === 'contract'
                ? saleDetails.amountPaid
                : PaymentTotal(
                      paymentLines,
                      saleDetails.status === 'Sale' ? true : false
                  );
        dueNow -= amountPaidForCurrentSale;

        // if amount paid is greater than price of collected items, due now should be 0
        dueNow = dueNow < 0 ? 0 : dueNow;

        return ToFixed(dueNow);
    }
}
