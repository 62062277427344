// IMPORTS
import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Typography,
    TableRow,
    IconButton,
    InputAdornment
} from '@mui/material';
import {
    CancelOutlined,
    CheckCircleOutline,
    Delete,
    ExpandLess,
    ExpandMore,
    MergeType
} from '@mui/icons-material';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import dayjs from 'dayjs';

// COMPONENTS
import Autocomplete from '../../../global/Autocomplete';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import Fab from '../../../global/Fab';
import { withSnackbar } from '../../../global/WrappingSnackbar';
import RoleCheck from '../../../global/RoleCheck';
import AccountDrawerOnlineOrdersDetailed from './AccountDrawerOnlineOrdersDetailed';
// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
import GetAllAccountCategories from '../../../global/databaseLogic/GetAllAccountCategories';
import HandleUpdateAccount from '../logic/HandleUpdateAccount';
import HandleAddAccount from '../logic/HandleAddAccount';
import { HandleDeleteAccount } from '../logic/HandleDeleteAccount';
import {
    DateFormatter,
    CurrencyFormatter
} from '../../../global/logic/Formatters';
// INTERFACES
import {
    Account,
    AccountCategory
} from '../../../global/interfaces/AdminInterface';
import { Site } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';

interface AccountDrawerContentProps {
    selectedAccount: Account | null;
    setSelectedAccount: Dispatch<SetStateAction<Account>>;
    resultsList: Account[];
    setResultsList: Dispatch<SetStateAction<Account[]>>;
    setMergeDialogOpen: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

interface Transaction {
    id?: string;
    url?: string;
    date?: string;
    reference?: string;
    user?: string;
    amount?: number;
    invoiceDate?: string;
    reconciled?: boolean;
    paymentHistoryFullyPaid?: boolean;
    paymentHistoryReconciled?: boolean;
}

const AccountDrawerContent = ({
    selectedAccount,
    setSelectedAccount,
    resultsList,
    setResultsList,
    setMergeDialogOpen,
    showSnackbar
}: AccountDrawerContentProps) => {
    const [isNewAccount, setIsNewAccount] = useState<boolean>(true);
    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [sites, setSites] = useState<Site[]>([]);
    const [displayOO, setDisplayOO] = useState<boolean>(false);

    const [accountError, setAccountError] = useState({
        name: false,
        type: false,
        initialBalance: false
    });
    const [AccountCategories, setAccountCategories] = useState<
        AccountCategory[]
    >([]);
    const accountTypeOptions = [
        { id: 0, label: 'Revenue', value: 'Revenue' },
        { id: 1, label: 'Expense', value: 'Expense' },
        { id: 2, label: 'Current Asset', value: 'Asset (Current)' },
        { id: 3, label: 'Non-Current Asset', value: 'Asset (Non-Current)' },
        { id: 4, label: 'Current Liability', value: 'Liability (Current)' },
        {
            id: 5,
            label: 'Non-Current Liability',
            value: 'Liability (Non-Current)'
        }
    ];

    const [columns, setColumns] = useState([
        { id: 0, label: 'ID' },
        { id: 1, label: 'Date' },
        { id: 2, label: 'Reference' },
        { id: 3, label: 'User/Customer' },
        { id: 4, label: 'Amount' }
    ]);

    // Get all the sites and if viewing an account get all the associated transactions
    useEffect(() => {
        GetAllSites(setSites);
        GetAllAccountCategories(setAccountCategories);
        if (selectedAccount?.id) {
            setIsNewAccount(false);
            //GetAllAccountTransactions(selectedAccount, setSelectedAccount);
        }
    }, [selectedAccount]);

    useEffect(() => {
        if (
            selectedAccount &&
            (selectedAccount?.transactions?.filter((x) => x.reconciled).length >
                0 ||
                selectedAccount?.transactions?.filter(
                    (x) =>
                        x.paymentHistoryFullyPaid || x.paymentHistoryReconciled
                ))
        ) {
            setColumns([
                { id: 0, label: 'ID' },
                { id: 1, label: 'Date' },
                { id: 2, label: 'Reference' },
                { id: 3, label: 'User/Customer' },
                { id: 4, label: 'Amount' },
                { id: 5, label: 'Reconciled' }
            ]);
        } else {
            setColumns([
                { id: 0, label: 'ID' },
                { id: 1, label: 'Date' },
                { id: 2, label: 'Reference' },
                { id: 3, label: 'User/Customer' },
                { id: 4, label: 'Amount' }
            ]);
        }
    }, [selectedAccount]);

    const getAllOnlineOrder = (transactions: Transaction[]) => {
        let onlineOrderTransactions = transactions.filter((x) =>
            x.id.includes('_OO')
        );
        let latestDate = null;
        let earliestDate = null;
        let totalAmount = 0;

        for (let transaction of onlineOrderTransactions) {
            if (
                latestDate === null ||
                latestDate.isBefore(dayjs(transaction.date))
            ) {
                latestDate = dayjs(transaction.date);
            }
            if (
                earliestDate === null ||
                earliestDate.isAfter(dayjs(transaction.date))
            ) {
                earliestDate = dayjs(transaction.date);
            }
            totalAmount += transaction.amount;
        }

        return (
            <TableRow>
                <DataCell>Online Orders</DataCell>
                <DataCell>{`${earliestDate.format('DD/MM/YYYY')} to ${latestDate.format('DD/MM/YYYY')}`}</DataCell>
                <DataCell>
                    {onlineOrderTransactions.length} Online Orders
                </DataCell>
                <DataCell>Ora Online</DataCell>
                <DataCell>{CurrencyFormatter(totalAmount)}</DataCell>
                <DataCell handleClick={() => setDisplayOO(!displayOO)}>
                    {displayOO ? <ExpandMore /> : <ExpandLess />}
                </DataCell>
            </TableRow>
        );
    };

    return (
        <>
            {isNewAccount ? (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                data-cy={'accountNameField'}
                                size="small"
                                label="Account Name"
                                value={selectedAccount?.name}
                                onChange={(e) =>
                                    setSelectedAccount({
                                        ...selectedAccount,
                                        name: e.target.value
                                    })
                                }
                                InputLabelProps={{ shrink: true }}
                                error={accountError.name}
                            />
                        </Grid>
                        <RoleCheck
                            permission="account_initial_balance"
                            component={
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        data-cy={'accountInitialBalanceField'}
                                        size="small"
                                        type="number"
                                        label="Initial Balance"
                                        value={selectedAccount?.initialBalance}
                                        onChange={(e) =>
                                            setSelectedAccount({
                                                ...selectedAccount,
                                                initialBalance: parseFloat(
                                                    e.target.value
                                                )
                                            })
                                        }
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyOutlinedIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        error={accountError.initialBalance}
                                    />
                                </Grid>
                            }
                        />

                        <Grid item xs={6}>
                            <Autocomplete
                                options={accountTypeOptions}
                                cypressLabel={'accountTypeAutocomplete'}
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'label'}
                                textfieldLabel="Account Type"
                                size="small"
                                selectedValue={selectedAccount?.type}
                                handleSelectedValueChange={(event) => {
                                    setSelectedAccount({
                                        ...selectedAccount,
                                        type: event.value
                                    });
                                }}
                                handleInputValueChange={() => null}
                                isError={accountError.type}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                options={sites}
                                cypressLabel={'accountSiteAutocomplete'}
                                useTwoOptionLabels={false}
                                primaryOptionLabel={'name'}
                                textfieldLabel="Site"
                                size="small"
                                selectedValue={selectedAccount?.SiteId}
                                handleSelectedValueChange={(event) => {
                                    setSelectedAccount({
                                        ...selectedAccount,
                                        SiteId: event.id,
                                        Site: event
                                    });
                                }}
                                handleInputValueChange={() => null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Fab
                                cypressLabel={'accountDrawerSubmitFAB'}
                                editIcon={false}
                                onClick={() =>
                                    HandleAddAccount(
                                        selectedAccount,
                                        setAccountError,
                                        showSnackbar
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={
                                selectedAccount?.transactions.length === 0
                                    ? 11
                                    : 12
                            }
                        >
                            <Autocomplete
                                cypressLabel={
                                    'accountDrawerCategoryAutocomplete'
                                }
                                isDisabled={readOnly}
                                options={AccountCategories}
                                useTwoOptionLabels={false}
                                size="small"
                                primaryOptionLabel={'name'}
                                textfieldLabel="Account Category"
                                selectedValue={selectedAccount?.AccountCategory}
                                handleSelectedValueChange={(event) => {
                                    setSelectedAccount({
                                        ...selectedAccount,
                                        AccountCategoryId: event.id,
                                        AccountCategory: event
                                    });
                                }}
                                handleInputValueChange={() => null}
                                isError={accountError.type}
                            />
                        </Grid>
                        {selectedAccount?.transactions.length === 0 ? (
                            <Grid item xs={1}>
                                <IconButton
                                    data-cy={'accountDrawerDeleteButton'}
                                    disabled={readOnly}
                                    onClick={() => {
                                        HandleDeleteAccount(
                                            selectedAccount.id,
                                            showSnackbar
                                        );
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <TextField
                                disabled={readOnly}
                                data-cy={'accountNameField'}
                                fullWidth
                                size="small"
                                label="Account Name"
                                value={selectedAccount?.name}
                                onChange={(e) =>
                                    setSelectedAccount({
                                        ...selectedAccount,
                                        name: e.target.value
                                    })
                                }
                                InputLabelProps={{ shrink: true }}
                                error={accountError.name}
                            />
                        </Grid>
                        <RoleCheck
                            permission="account_initial_balance"
                            component={
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={readOnly}
                                        data-cy={'accountInitialBalanceField'}
                                        fullWidth
                                        size="small"
                                        type="number"
                                        label="Initial Balance"
                                        value={selectedAccount?.initialBalance}
                                        onChange={(e) =>
                                            setSelectedAccount({
                                                ...selectedAccount,
                                                initialBalance: parseFloat(
                                                    e.target.value
                                                )
                                            })
                                        }
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyOutlinedIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        error={accountError.initialBalance}
                                    />
                                </Grid>
                            }
                        />
                        <Grid item xs={12}>
                            {selectedAccount?.transactions?.length === 0 ? (
                                <Typography variant="body1">
                                    No transactions to display.
                                </Typography>
                            ) : (
                                <DataTable columns={columns}>
                                    {getAllOnlineOrder(
                                        selectedAccount?.transactions
                                    )}
                                    {displayOO ? (
                                        <AccountDrawerOnlineOrdersDetailed
                                            transactions={
                                                selectedAccount?.transactions
                                            }
                                        />
                                    ) : null}
                                    {selectedAccount?.transactions?.map(
                                        (transaction: Transaction) => {
                                            if (
                                                !transaction.id.includes('_OO')
                                            ) {
                                                return (
                                                    <TableRow>
                                                        {transaction.url ? (
                                                            <DataCellColoured
                                                                handleClick={() =>
                                                                    window.open(
                                                                        transaction.url,
                                                                        '_blank'
                                                                    )
                                                                }
                                                            >
                                                                {transaction.id}
                                                            </DataCellColoured>
                                                        ) : (
                                                            <DataCell>
                                                                {transaction.id}
                                                            </DataCell>
                                                        )}
                                                        <DataCell>
                                                            {transaction.invoiceDate
                                                                ? DateFormatter(
                                                                      transaction.invoiceDate
                                                                  )
                                                                : DateFormatter(
                                                                      transaction.date
                                                                  )}
                                                        </DataCell>
                                                        <DataCell>
                                                            {
                                                                transaction.reference
                                                            }
                                                        </DataCell>
                                                        <DataCell>
                                                            {transaction.user}
                                                        </DataCell>
                                                        <DataCell>
                                                            {CurrencyFormatter(
                                                                -transaction.amount
                                                            )}
                                                        </DataCell>
                                                        {(transaction.id.includes(
                                                            '_MT'
                                                        ) ||
                                                            transaction.id.includes(
                                                                '_SI'
                                                            )) &&
                                                        transaction.reconciled ? (
                                                            <DataCell>
                                                                <CheckCircleOutline
                                                                    sx={{
                                                                        color: 'green'
                                                                    }}
                                                                />
                                                            </DataCell>
                                                        ) : transaction.id.includes(
                                                              '_OO'
                                                          ) ? (
                                                            <DataCell />
                                                        ) : selectedAccount &&
                                                          selectedAccount?.transactions?.filter(
                                                              (x) =>
                                                                  x.reconciled
                                                          ).length > 0 ? (
                                                            <DataCell>
                                                                <CancelOutlined
                                                                    sx={{
                                                                        color: 'red'
                                                                    }}
                                                                />
                                                            </DataCell>
                                                        ) : null}
                                                    </TableRow>
                                                );
                                            } else {
                                                return null;
                                            }
                                        }
                                    )}
                                    <TableRow>
                                        <DataCell colSpan={3}></DataCell>
                                        <DataCell>
                                            <b>TOTAL</b>
                                        </DataCell>
                                        <DataCell>
                                            <b>
                                                {CurrencyFormatter(
                                                    selectedAccount.type.includes(
                                                        'Liability'
                                                    ) &&
                                                        !Number.isNaN(
                                                            selectedAccount.initialBalance
                                                        )
                                                        ? selectedAccount.initialBalance -
                                                              selectedAccount.accountTotal
                                                        : selectedAccount.type.includes(
                                                                'Asset'
                                                            ) &&
                                                            !Number.isNaN(
                                                                selectedAccount.initialBalance
                                                            )
                                                          ? selectedAccount.initialBalance - // Is now a subtraction because James looks online and : "Debit for an Asset is making the account go up", debit are negative in the DB
                                                            selectedAccount.accountTotal
                                                          : selectedAccount.accountTotal
                                                )}
                                            </b>
                                        </DataCell>
                                        {selectedAccount &&
                                        selectedAccount?.transactions?.filter(
                                            (x) => x.reconciled
                                        ).length > 0 ? (
                                            <DataCell></DataCell>
                                        ) : null}
                                    </TableRow>
                                </DataTable>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Fab
                                cypressLabel={'accountDrawerEditFab'}
                                editIcon={readOnly}
                                onClick={() => {
                                    readOnly
                                        ? setReadOnly(false)
                                        : HandleUpdateAccount(
                                              selectedAccount,
                                              resultsList,
                                              setResultsList,
                                              setReadOnly,
                                              showSnackbar,
                                              setAccountError
                                          );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Fab
                                customIcon={<MergeType />}
                                right={85}
                                onClick={() => {
                                    setMergeDialogOpen(true);
                                }}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default withSnackbar(AccountDrawerContent);
