import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import PageWrapper from '../../global/PageWrapper';
import { PDFDocument } from 'pdf-lib';

import {
    GetFileFromS3,
    UploadFileToS3
} from '../newVehicleSale/logic/S3FileService';
import CustomerVendorStatementForm from './components/CustomerVendorStatementForm';
import { EmailSignedPdf } from '../signContract/logic/EmailContract';
import CustomerSubmitSuccessPage from '../signContract/components/CustomerSubmitSuccessPage';
import VerifyCustomer from '../signContract/VerifyCustomer';

const CustomerSignVendorStatement = () => {
    let { id: vehicleId } = useParams<{ id: string }>();

    const [pdfDoc, setPdfDoc] = useState<PDFDocument>(null);
    const [pdfUrl, setPdfUrl] = useState<string>(null);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [customerVerified, setCustomerVerified] = useState<boolean>(false);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false);

    const LoadContractPDF = () => {
        GetFileFromS3({
            folderName: 'vendorStatements',
            fileName: parseInt(vehicleId),
            setPdfDoc: setPdfDoc,
            setPdfUrl: setPdfUrl
        });
    };

    useEffect(() => {
        if (customerVerified) {
            LoadContractPDF();
        }
        // eslint-disable-next-line
    }, [vehicleId, customerVerified]);

    const handleSubmitSignedStatement = (pdfBlob) => {
        // Convert blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = () => {
            const base64data = reader.result as string;
            // Upload the updated contract to S3
            UploadFileToS3({
                base64data: base64data,
                folderName: 'vendorStatements',
                fileName: parseInt(vehicleId)
            });

            EmailSignedPdf({
                type: 'vendor-statement',
                id: parseInt(vehicleId),
                base64data: base64data,
                newStatus: 'vendorSigned',
                setIsSubmitSuccess: setIsSubmitSuccess
            });
        };

        // Download the updated contract to the customer's device
        // const url = URL.createObjectURL(pdfBlob);
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = `Vendor-Statement-${vehicleId}.pdf`;
        // a.click();
    };

    // display the form data on corresponding PDF fields
    const onVendorFormSubmit = async (formData, submitType) => {
        if (!pdfDoc) return;

        // Make a copy of original document, so everytime formData changes, we start from the original document
        const pdfDocCopy = await PDFDocument.load(await pdfDoc.save());

        if (submitType === 'upload') {
            setButtonLoading(true);
        }

        const pages = pdfDocCopy.getPages();
        const firstPage = pages[0];

        if (formData.vendorSigDataURL) {
            const vendorSigImage = await pdfDocCopy.embedPng(
                formData.vendorSigDataURL
            );
            firstPage.drawImage(vendorSigImage, {
                x: 400,
                y: 30,
                width: 100,
                height: 30
            });
        }

        const pdfBytes = await pdfDocCopy.save();
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        // Update the PDF URL to reflect changes
        if (submitType === 'preview') {
            setPdfUrl(URL.createObjectURL(pdfBlob));
        }

        // Upload the updated PDF to S3
        if (submitType === 'upload') {
            handleSubmitSignedStatement(pdfBlob);
        }
    };

    if (!customerVerified) {
        return (
            <VerifyCustomer
                type="vendor-statement"
                setCustomerVerified={setCustomerVerified}
            />
        );
    }

    if (isSubmitSuccess) {
        return <CustomerSubmitSuccessPage />;
    }

    return (
        <PageWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ padding: 2 }}>
                        <Typography variant="body1">
                            Please find below the vendor statement filled out
                            and signed by the Motor Dealer.
                            <b>
                                To leave your signature, scroll down to the
                                designated VENDOR SIGNATURE area.
                            </b>{' '}
                            You may click the 'Preview Signature' button to view
                            your signature on the statement. By clicking 'Submit
                            Signed Statement', you will send a copy of your
                            signed statement to the Motor Dealer and your email
                            address specified in the VENDOR("SELLER") DETAILS
                            section on the statement.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {pdfUrl ? (
                        <Box sx={{ padding: 2 }} width="100%" height="160vh">
                            <iframe
                                src={pdfUrl + '#view=Fit'}
                                width="100%"
                                height="100%"
                                title={`Vendor Statement #${vehicleId}`}
                            ></iframe>
                        </Box>
                    ) : (
                        <Box
                            sx={{ padding: 2 }}
                            width="100%"
                            height="100%"
                            textAlign={'center'}
                        >
                            <CircularProgress />
                            <Typography variant="h5">
                                Loading vendor statement...
                            </Typography>
                        </Box>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <CustomerVendorStatementForm
                        onVendorFormSubmit={onVendorFormSubmit}
                        buttonLoading={buttonLoading}
                    />
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default CustomerSignVendorStatement;
