import api from '../../../../../api';

const GetVendorStatementInfo = (
    vehicleId,
    setCustomer,
    setContractStatus,
    setSnackbar
) => {
    api.get('/getVendorStatementInfo/' + vehicleId).then((res) => {
        if (res.status === 200) {
            setCustomer(res.data.customerInfo);
            setContractStatus(res.data.statementStatus);
        } else {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Whoops! Something went wrong on our end.',
                subMessage: 'Please contact your IT department.'
            });
        }
    });
};

export default GetVendorStatementInfo;
