import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { Snack } from '../../../global/interfaces/GlobalInterface';
import { PDFDocument } from 'pdf-lib';

interface UploadFileToS3Props {
    base64data: string;
    folderName: string;
    fileName: number;
    setSnackbar?: Dispatch<SetStateAction<Snack>>;
}

export const UploadFileToS3 = ({
    base64data,
    folderName,
    fileName,
    setSnackbar
}: UploadFileToS3Props) => {
    api.post('/uploadFileToS3', {
        base64data,
        folderName,
        fileName
    }).then((res) => {
        if (res.status !== 200) {
            setSnackbar?.({
                open: true,
                severity: 'error',
                message: 'Whoops! Something went wrong on our end.',
                subMessage: 'Please contact your IT department.'
            });
        }
    });
};

interface GetFileFromS3Props {
    folderName: string;
    fileName: number;
    setPdfDoc: Dispatch<SetStateAction<PDFDocument>>;
    setPdfUrl: Dispatch<SetStateAction<string>>;
    setSnackbar?: Dispatch<SetStateAction<Snack>>;
}
export const GetFileFromS3 = ({
    folderName,
    fileName,
    setPdfDoc,
    setPdfUrl,
    setSnackbar
}: GetFileFromS3Props) => {
    api.get(`/getFileFromS3/${folderName}/${fileName}`, {
        responseType: 'blob'
    }).then(async (response) => {
        if (response.status === 200 && response.data) {
            const arrayBuffer = await response.data.arrayBuffer();
            PDFDocument.load(arrayBuffer)
                .then((pdfDoc) => {
                    setPdfDoc(pdfDoc);
                })
                .catch((error) => {
                    console.error('Error loading PDF document:', error);
                    setSnackbar?.({
                        open: true,
                        severity: 'error',
                        message: 'Failed to load PDF document.',
                        subMessage: 'Please try again later.'
                    });
                });
            const pdfBlob = new Blob([arrayBuffer], {
                type: 'application/pdf'
            });
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(pdfUrl);
        } else {
            setSnackbar?.({
                open: true,
                severity: 'error',
                message: 'Whoops! Something went wrong on our end.',
                subMessage: 'Please contact your IT department.'
            });
        }
    });
};
