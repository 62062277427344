// IMPORTS
import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
// COMPONENTS
import Services from './Services';
import Sales from './Sales';
import Invoices from './Invoices';
import Totals from '../Totals';
// LOGIC
import GetVehicleAddedCosts from '../../logic/GetVehicleAddedCosts';
// INTERFACES
import { Service } from '../../../../global/interfaces/ServiceInterface';
import { Order, Invoice } from '../../../../global/interfaces/PartsInterface';
import {
  Vehicle,
  VehicleInvoice,
  VehicleInvoiceLine
} from '../../../../global/interfaces/VehicleSaleInterface';

interface TempVehicleInvoices extends VehicleInvoiceLine {
  Invoice: Invoice;
  VehicleInvoice: VehicleInvoice;
}
interface AddedCostsProps {
  vehicle: Vehicle;
}

interface VehicleAddedCosts {
  services: Service[];
  sales: Order[];
  invoices: Invoice[];
  vehicleInvoices: TempVehicleInvoices[];
}

const AddedCosts = ({ vehicle }: AddedCostsProps) => {
  const [vehicleAddedCosts, setVehicleAddedCosts] = useState<VehicleAddedCosts>(
    {
      services: [],
      sales: [],
      invoices: [],
      vehicleInvoices: []
    }
  );

  useEffect(() => {
    GetVehicleAddedCosts(vehicle.id, setVehicleAddedCosts);
  }, [vehicle.id]);

  console.log('vehicleAddedCosts:', vehicleAddedCosts);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Services services={vehicleAddedCosts.services} />
          <br />
          <Sales sales={vehicleAddedCosts.sales} />
          <br />
          <Invoices
            vehicleId={vehicle.id}
            invoices={vehicleAddedCosts.invoices}
            vehicleInvoices={vehicleAddedCosts.vehicleInvoices}
          />
        </Grid>
        <Grid item xs={4}>
          <Totals vehicle={vehicle} vehicleAddedCosts={vehicleAddedCosts} />
        </Grid>
      </Grid>
    </>
  );
};
export default AddedCosts;
