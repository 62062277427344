// IMPORTS
import jsPDF from 'jspdf';
// eslint-disable-next-line
import autoTable from 'jspdf-autotable'; // !DO NOT REMOVE
import moment from 'moment';

// LOGIC
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import api from '../../../../../api';

const PDFServiceCustomerReceipt = async (
    id: number,
    payingCustomerType: string
) => {
    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    api.get(`serviceNextGen/${id}`).then(async (res) => {
        let serviceDetails = res.data.serviceDetails;

        // Header for the invoice
        await InvoiceHeader(
            doc,
            parseInt(localStorage.getItem('SiteId')),
            'Payment Receipt',
            `Service #${id}`,
            true,
            true,
            serviceDetails.Customer,
            serviceDetails.jobFinish
        );

        // Table containing the voucher lines
        let header = [];
        let body = [];
        if (serviceDetails.jobType === 'Insurance') {
            header = [['Payee', 'Payment Method', 'Payment Date', 'Amount']];
        } else {
            header = [['Payment Method', 'Payment Date', 'Amount']];
        }

        serviceDetails.paymentLines.forEach((line) => {
            if (serviceDetails.jobType === 'Insurance') {
                if (
                    payingCustomerType === 'customer' &&
                    line.payingCustomerId === serviceDetails.CustomerId
                ) {
                    body.push([
                        line.payingCustomerName.toUpperCase(),
                        line.type.toUpperCase(),
                        DateFormatter(line.date),
                        CurrencyFormatter(line.amount)
                    ]);
                } else if (payingCustomerType === 'insurer') {
                    body.push([
                        line.payingCustomerName.toUpperCase(),
                        line.type.toUpperCase(),
                        DateFormatter(line.date),
                        CurrencyFormatter(line.amount)
                    ]);
                }
            } else {
                body.push([
                    line.type.toUpperCase(),
                    `${DateFormatter(line.date)} ${moment(line.timestamp).format('h:mm A')}`,
                    CurrencyFormatter(line.amount)
                ]);
            }
        });

        let lastYcoordinates = 82;

        doc.setFont('helvetica', 'bold').setFontSize(12);
        doc.text('Payments', 17, lastYcoordinates + 13);

        InvoiceTable(doc, lastYcoordinates + 18, header, body);

        // Open the pdf
        window.open(doc.output('bloburl'));
        // window.location.href = "/service";
    });
};

export default PDFServiceCustomerReceipt;
