// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import { PaymentLine } from '../../../global/interfaces/GeneralInterface';
import {
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';
import { ServiceTotal } from '../../newBooking/logic/GetTotals';
import { Order } from '../../../global/interfaces/PartsInterface';
import UpdateServiceTotalPrice from './UpdateServiceTotalPrice';

const GetServiceDetails = (
    id: number,
    setServiceDetails: Dispatch<SetStateAction<Service>>,
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>,
    setSublets: Dispatch<SetStateAction<PurchaseOrder[]>>,
    setPartSales: Dispatch<SetStateAction<Order[]>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setInvalid?: Dispatch<SetStateAction<boolean>>,
    setIncompleteServiceDialog?: Dispatch<SetStateAction<boolean>>
) => {
    setLoading(true);
    api.get(`serviceNextGen/${id}`).then((res) => {
        if (res.status === 204 && setInvalid) {
            setInvalid(true);
            setLoading(false);
        } else {
            let serviceDetails = res.data.serviceDetails;
            let sublets = res.data.purchaseOrders;
            let partSales = res.data.partSales;

            partSales.forEach((sale) => {
                sale.orderLines.forEach((line) => {
                    if (!line.priceCharged) {
                        line.priceCharged = line.pricePaid;
                    }
                });
            });

            if (!serviceDetails.labourCost) {
                serviceDetails.labourCost = 0;
            }

            if (
                !serviceDetails.totalPrice &&
                ServiceTotal(serviceDetails, sublets, partSales) !== 0
            ) {
                serviceDetails.totalPrice = ServiceTotal(
                    serviceDetails,
                    sublets,
                    partSales
                );
                UpdateServiceTotalPrice(id, serviceDetails.totalPrice);
            }

            if (serviceDetails.serviceComplete === 'Incomplete') {
                setIncompleteServiceDialog(true);
            } else {
                setServiceDetails(serviceDetails);
                setPaymentLines(serviceDetails.paymentLines);
                setSublets(sublets);
                setPartSales(partSales);
            }

            setLoading(false);
        }
    });
};

export default GetServiceDetails;
