export const handleChange = (
    newValue,
    id,
    type,
    invoiceDetails,
    setInvoiceDetails
) => {
    console.log('handleChange newValue:', newValue);
    console.log('handleChange id:', id);
    console.log('handleChange type:', type);
    console.log('handleChange invoiceDetails:', invoiceDetails);

    let currentRowArray = [
        ...(invoiceDetails.StockInvoice?.orderLines as any[])
    ];

    const index = currentRowArray.findIndex((item) => item.id === id);
    console.log('index:', index);

    if (index !== -1) {
        currentRowArray[index] = {
            ...currentRowArray[index],
            Account: type === 'Account' ? newValue : null,
            Debtor: type === 'Debtor' ? newValue : null,
            Service: type === 'Service' ? newValue : null,
            Vehicle: type === 'Vehicle' ? newValue : null
        };
    }

    console.log('currentRowArray:', currentRowArray);

    setInvoiceDetails({
        ...invoiceDetails,
        StockInvoice: {
            ...invoiceDetails.StockInvoice,
            orderLines: currentRowArray
        }
    });
};

export const handleDescriptionChange = (
    newValue,
    id,
    invoiceDetails,
    setInvoiceDetails
) => {
    let currentRowArray = [
        ...(invoiceDetails.StockInvoice?.orderLines as any[])
    ];
    let index = currentRowArray.findIndex((x) => x.id === id);

    currentRowArray[index] = {
        ...currentRowArray[index],
        description: newValue
    };

    setInvoiceDetails({
        ...invoiceDetails,
        StockInvoice: {
            ...invoiceDetails.StockInvoice,
            orderLines: currentRowArray
        }
    });
};

export const handleChangeType = (newType, id, rowType, setRowType) => {
    let currentRowArray = [...rowType];
    let index = currentRowArray.findIndex((x) => x.id === id);
    currentRowArray[index].type = newType;
    setRowType(currentRowArray);
};
