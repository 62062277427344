import SignatureCanvas from 'react-signature-canvas';
import api from '../../../../api';

const GetUserSignature = (id: number, sigCanvas: SignatureCanvas) => {
    api.get('userSignature/' + id.toString()).then((res) => {
        if (sigCanvas.current != null) {
            sigCanvas.current.fromDataURL(res.data.userSignature);
        }
    });
};

export default GetUserSignature;
