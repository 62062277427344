import PageWrapper from '../../global/PageWrapper';
import { CircularProgress, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import CalculateVehicleSaleRevenue from './logic/CalculateVehicleSaleRevenue';
import VehicleSaleRevenueOverview from './components/VehicleSaleRevenueOverview';
import VehicleSaleCounter from './components/VehicleSaleCounter';
import VehicleSaleBreakdownTable from './components/VehicleSaleBreakdownTable';
import {
    getComparisonDates,
    getDefaultDateRange
} from '../../global/dashboard/getMonthDatesAndBusinessDays';
import { VehicleSale } from '../../global/interfaces/VehicleSaleInterface';
import SiteAndDateHeader from '../../global/dashboard/SiteAndDateHeader';
import { Site } from '../../global/interfaces/GeneralInterface';
import getDashboardData from '../../global/dashboard/getDashboardData';
import Paper from '../../global/Paper';
import { Dayjs } from 'dayjs';

const VehicleSaleDashboard = () => {
    const { firstDayOfCurrentMonth, today } = getDefaultDateRange();
    // loading state
    const [isLoading, setIsLoading] = useState<Boolean>(true);

    // header: type; date; site
    const [selectedSite, setSelectedSite] = useState<Site[]>([]);

    const [compareType, setCompareType] = useState<'Month' | 'Year'>('Month');

    const [selectedStartDate, setSelectedStartDate] = useState<Dayjs>(
        firstDayOfCurrentMonth
    );
    const [selectedEndDate, setSelectedEndDate] = useState<Dayjs>(today);

    // get corresponding compareStartDate and compareEndDate based on compareType and selectedStartDate and selectedEndDate
    const { compareStartDate, compareEndDate } = useMemo(() => {
        return getComparisonDates(
            compareType,
            selectedStartDate,
            selectedEndDate
        );
    }, [compareType, selectedStartDate, selectedEndDate]);

    // vehicle sales data
    const [
        finalisedVehicleSalesSelectedMonth,
        setFinalisedVehicleSalesSelectedMonth
    ] = useState<VehicleSale[]>([]);

    const [soldVehicleSaleSelectedMonth, setSoldVehicleSaleSelectedMonth] =
        useState<VehicleSale[]>([]);

    const [
        finalisedVehicleSalesComparedMonth,
        setFinalisedVehicleSalesComparedMonth
    ] = useState<VehicleSale[]>([]);

    const [pendingManufacturerOrders, setPendingManufacturerOrders] = useState(
        []
    );

    const fetchVehicleSaleInDateRange = async () => {
        setIsLoading(true);

        try {
            if (
                selectedSite.length > 0 &&
                selectedStartDate &&
                selectedEndDate
            ) {
                const [sales, comparedSales] = await Promise.all([
                    getDashboardData({
                        type: 'vehicleSale',
                        startDate: selectedStartDate.toString(),
                        endDate: selectedEndDate.toString(),
                        selectedSite
                    }),
                    getDashboardData({
                        type: 'vehicleSale',
                        startDate: compareStartDate.toString(),
                        endDate: compareEndDate.toString(),
                        selectedSite
                    })
                ]);

                setFinalisedVehicleSalesSelectedMonth(
                    sales.finalisedVehicleSales
                );
                setSoldVehicleSaleSelectedMonth(sales.soldVehicleSales);
                setPendingManufacturerOrders(sales.pendingManufacturerOrders);
                setFinalisedVehicleSalesComparedMonth(
                    comparedSales.finalisedVehicleSales
                );
            }
        } catch (error) {
            console.error('Error fetching vehicle sales data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchVehicleSaleInDateRange();
        // eslint-disable-next-line
    }, [
        selectedSite,
        selectedStartDate,
        selectedEndDate,
        compareType,
        compareStartDate,
        compareEndDate
    ]);

    const {
        vehicleSalesData: selectedMonthFinalisedVehicleSalesData,
        totalRevenue: selectedMonthTotalFinalisedRevenue
    } = CalculateVehicleSaleRevenue(finalisedVehicleSalesSelectedMonth);

    const { vehicleSalesData: selectedMonthSoldVehicleSalesData } =
        CalculateVehicleSaleRevenue(soldVehicleSaleSelectedMonth);

    const { totalRevenue: comparedMonthTotalFinalisedRevenue } =
        CalculateVehicleSaleRevenue(finalisedVehicleSalesComparedMonth);

    const { vehicleSalesData: allPendingManufacturerOrdersData } =
        CalculateVehicleSaleRevenue(pendingManufacturerOrders);

    //breakdown table
    const [dataType, setDataType] = useState<
        'manufacturer' | 'finalised' | 'sale'
    >('finalised');

    const breakdownData =
        dataType === 'manufacturer'
            ? allPendingManufacturerOrdersData
            : dataType === 'sale'
              ? selectedMonthSoldVehicleSalesData
              : selectedMonthFinalisedVehicleSalesData;

    return (
        <PageWrapper>
            <Typography variant="h4">Dashboard</Typography>
            <br />
            <SiteAndDateHeader
                department="sales"
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                compareType={compareType}
                setCompareType={setCompareType}
            />
            <br />
            {isLoading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : (
                <>
                    <VehicleSaleRevenueOverview
                        selectedMonthFinalisedRevenue={
                            selectedMonthTotalFinalisedRevenue
                        }
                        comparedMonthFinalisedRevenue={
                            comparedMonthTotalFinalisedRevenue
                        }
                        compareType={compareType}
                        compareStartDate={compareStartDate}
                        compareEndDate={compareEndDate}
                    />
                    <br />
                    <VehicleSaleCounter
                        allPendingManufacturerOrdersData={
                            allPendingManufacturerOrdersData
                        }
                        currentMonthFinalisedVehicleSalesData={
                            selectedMonthFinalisedVehicleSalesData
                        }
                        currentMonthSoldVehicleSalesData={
                            selectedMonthSoldVehicleSalesData
                        }
                        setDataType={setDataType}
                    />
                    <br />
                    <VehicleSaleBreakdownTable
                        vehicleSaleData={breakdownData}
                        dataType={dataType}
                    />
                </>
            )}
        </PageWrapper>
    );
};

export default VehicleSaleDashboard;
