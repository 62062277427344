// IMPORTS
import { useState } from 'react';
import {
    Divider,
    IconButton,
    Table,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../global/Paper';
// LOGIC
import { CurrencyFormatter, ToFixed } from '../../../global/logic/Formatters';
import CalculateMargin from '../../../global/logic/CalculateMargin';
import { TotalCost } from '../../newSale/logic/CalculateTotals';
import {
    CalculateAmountPaid,
    CalculateAmountRefunded,
    CalculateVoucherTotal,
    CalculatePartsTotal
} from '../logic/CreditCalculations';
// INTERFACES
import { Order, Voucher } from '../../../global/interfaces/PartsInterface';

interface TotalFormProps {
    saleDetails: Order;
    vouchers: { voucher: Voucher; qtyReturning: number }[];
    freightRefunding: number;
    amountRefunding: number;
}

/**
 * TotalForm
 * Show all totals associated with the credit sale
 * @author Estienne
 * @param TotalFormProps
 * @returns {JSX} The total form
 */
const TotalForm = ({
    saleDetails,
    vouchers,
    freightRefunding,
    amountRefunding
}: TotalFormProps) => {
    const [showMargin, setShowMargin] = useState(false);
    const freightTotal = saleDetails?.freight - saleDetails?.freightRefunded;
    const totalCost = TotalCost(
        saleDetails,
        saleDetails.status === 'Sale' ? true : false
    );
    const amountPaid = CalculateAmountPaid(saleDetails, freightTotal, vouchers);

    return (
        <>
            <Paper>
                <Typography variant="h6">Totals</Typography>
                <Divider />
                <Table size="small">
                    <TableRow>
                        <TableCell
                            width="70%"
                            align="left"
                            sx={{ borderBottom: 'none' }}
                        >
                            Parts
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0
                            }}
                        >
                            {CurrencyFormatter(
                                CalculatePartsTotal(saleDetails?.orderLines)
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left" sx={{ borderBottom: 'none' }}>
                            Freight
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0
                            }}
                        >
                            {CurrencyFormatter(freightTotal)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left">Vouchers</TableCell>
                        <TableCell
                            align="left"
                            sx={{ paddingRight: 0, paddingLeft: 0 }}
                        >
                            {CurrencyFormatter(CalculateVoucherTotal(vouchers))}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            align="left"
                            sx={{ borderBottom: 'none', paddingBottom: 0 }}
                        >
                            <b>Amount Paid</b>
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0,
                                paddingBottom: 0
                            }}
                        >
                            <b>
                                {CurrencyFormatter(
                                    saleDetails.status === 'Proforma'
                                        ? saleDetails.amountPaid
                                        : amountPaid
                                )}
                            </b>
                        </TableCell>
                    </TableRow>
                    {saleDetails.status === 'Proforma' ? null : (
                        <TableRow>
                            <TableCell
                                align="left"
                                sx={{ borderBottom: 'none', paddingBottom: 0 }}
                            >
                                {<b>Amount Refunded</b>}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{
                                    borderBottom: 'none',
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                    paddingBottom: 0
                                }}
                            >
                                <b>
                                    {CurrencyFormatter(
                                        CalculateAmountRefunded(
                                            saleDetails.orderLines,
                                            freightRefunding,
                                            vouchers
                                        )
                                    )}
                                </b>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell
                            align="left"
                            sx={{ borderBottom: 'none', paddingBottom: 0 }}
                        >
                            {<b>Balance</b>}
                        </TableCell>
                        <TableCell
                            align="left"
                            sx={{
                                borderBottom: 'none',
                                paddingRight: 0,
                                paddingLeft: 0,
                                paddingBottom: 0
                            }}
                        >
                            <b>
                                {CurrencyFormatter(
                                    saleDetails.status === 'Proforma'
                                        ? saleDetails.amountPaid -
                                              amountRefunding
                                        : amountPaid -
                                              CalculateAmountRefunded(
                                                  saleDetails.orderLines,
                                                  freightRefunding,
                                                  vouchers
                                              )
                                )}
                            </b>
                            {showMargin ? (
                                <IconButton
                                    onClick={() => setShowMargin(false)}
                                >
                                    <ExpandLess fontSize="small" />
                                </IconButton>
                            ) : (
                                <IconButton onClick={() => setShowMargin(true)}>
                                    <ExpandMore fontSize="small" />
                                </IconButton>
                            )}
                        </TableCell>
                    </TableRow>
                    {showMargin ? (
                        <>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        paddingRight: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    Total Cost
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        padding: 0
                                    }}
                                >
                                    {CurrencyFormatter(totalCost)}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        paddingRight: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    Gross Profit
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        padding: 0
                                    }}
                                >
                                    {CurrencyFormatter(
                                        amountPaid -
                                            CalculateAmountRefunded(
                                                saleDetails?.orderLines,
                                                freightRefunding,
                                                vouchers
                                            ) -
                                            totalCost
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        paddingRight: 0,
                                        paddingTop: 0,
                                        paddingBottom: 0
                                    }}
                                >
                                    Margin
                                </TableCell>
                                <TableCell
                                    align="left"
                                    sx={{
                                        color: 'gray',
                                        borderBottom: 'none',
                                        padding: 0
                                    }}
                                >
                                    {ToFixed(
                                        CalculateMargin(
                                            amountPaid -
                                                CalculateAmountRefunded(
                                                    saleDetails?.orderLines,
                                                    freightRefunding,
                                                    vouchers
                                                ),
                                            totalCost
                                        )
                                    )}
                                    %
                                </TableCell>
                            </TableRow>
                        </>
                    ) : null}
                </Table>
            </Paper>
        </>
    );
};

export default TotalForm;
