import axios from 'axios';
import jwtDecode from 'jwt-decode';
//const BASE_URL = process.env.REACT_APP_BASE_URL || "http://10.1.1.13:8080/api/";

const location = window.location.href;
let BASE_URL = '';

if (location.includes('localhost')) BASE_URL = 'http://localhost:8080/api/';
if (location.includes('staging.oradms.com'))
    BASE_URL = 'https://staging.oradms.com/api/';
if (location.includes('bmc.oradms.com'))
    BASE_URL = 'https://bmc.oradms.com/api/';
if (location.includes('scm.oradms.com'))
    BASE_URL = 'https://scm.oradms.com/api/';
if (location.includes('scsr.oradms.com'))
    BASE_URL = 'https://scsr.oradms.com/api/';
if (location.includes('ft1.oradms.com'))
    BASE_URL = 'https://ft1.oradms.com/api/';
if (BASE_URL === '')
    BASE_URL = `https://${location.replace('http://', '').replace('https://', '').split('/')[0]}/api`;

const api = axios.create({
    baseURL: BASE_URL
});

api.defaults.headers.common['Authorization'] =
    'Bearer ' + localStorage.getItem('token');

api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log(error);
        if (error.response.status === 401) {
            localStorage.removeItem('token');
            window.location = '/login';
        }
        return error.response;
    }
);

api.interceptors.request.use(function (request) {
    if (request.url === 'login' || request.url === 'refresh') {
        return request;
    }
    let token = localStorage.getItem('token');
    let decoded;
    try {
        decoded = jwtDecode(token);
    } catch (e) {
        return request;
    }
    if (decoded.exp - Date.now() / 1000 <= 300) {
        // Refresh token
        api.get('refresh').then((response) => {
            if (response.status === 200) {
                localStorage.setItem('token', response.data);
                api.defaults.headers.common['Authorization'] =
                    'Bearer ' + localStorage.getItem('token');
            } else {
                console.log(response.data);
                localStorage.setItem('token', null);
            }
        });
    }
    return request;
});

export default api;
