import { useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    Typography,
    Button,
    Box,
    Paper,
    Grid,
    Divider,
    IconButton,
    FormControlLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SignatureCanvas from 'react-signature-canvas';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import dayjs from 'dayjs';

interface DealerVendorStatementFormProps {
    onDealerVendorStatementSubmit: (
        formData: any,
        submitType: any
    ) => Promise<void>;
    customer: {
        name: string;
        email: string;
    };
    contractStatus: string;
}

const DealerVendorStatementForm = ({
    onDealerVendorStatementSubmit,
    customer,
    contractStatus
}: DealerVendorStatementFormProps) => {
    const [hasPreviewed, setHasPreviewed] = useState(false);

    const { control, watch, handleSubmit, setValue } = useForm({
        defaultValues: {
            // Vendor details
            acn: '',

            // Vehicle Details
            builtDate: null,
            personalPlateOption: 'NO',
            newRego: '',

            // Statement
            accessories: '',

            // statement section 1
            engineReplaceOption: 'NO',
            engineReplaceDate: null,
            odometerReplaceOption: 'NO',
            concessionalRegistrationOption: 'NO',
            airBagsActivatedOption: 'NO',
            modificationsApprovalOption: 'NO',
            currentWrittenOffRegisterOption: 'NO',
            previousWrittenOffRegisterOption: 'NO',

            // statement section 2
            withSecurityInterest: 'NO',
            lenderName: '',
            loanAmount: '',
            payoutByDealer: 'NO',

            // statement section 4
            taxiOrHireCarUsage: 'NO',

            // statement section 6
            auctionOption: 'NO',
            reservePrice: '',

            // statement section 8
            gstRegistrationOption: 'NO',
            taxableSupplyOption: 'NO',
            gstFreeSupplyOption: 'NO',

            // declare at, default to current date time
            declareTime: dayjs(),

            // signature
            witnessSigDataURL: ''
        }
    });

    let witnessSigCanvas = useRef<SignatureCanvas>(null);

    const clearSignature = () => {
        witnessSigCanvas.current.clear();
        setValue('witnessSigDataURL', null);
    };

    const saveSignature = () => {
        const dataURL = witnessSigCanvas.current.toDataURL();
        setValue('witnessSigDataURL', dataURL);
    };

    const handlePreviewChanges = async (data) => {
        await onDealerVendorStatementSubmit(data, 'preview');
        setHasPreviewed(true);
    };

    // when click 'Email Customer' button, open a dialog to confirm sending email to the customer
    const [openEmailDialog, setOpenEmailDialog] = useState(false);

    const onOpenEmailDialog = () => {
        setOpenEmailDialog(true);
    };

    const handleClickSendEmail = async (data) => {
        await onDealerVendorStatementSubmit(data, 'upload');
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Paper elevation={3} sx={{ padding: 3 }}>
                <form>
                    <Grid container spacing={2}>
                        {/* // Vendor Details ACN*/}
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                Vendor Details
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="acn"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="ACN"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>

                        {/* // Vehicle Details */}
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                Vehicle Details
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="builtDate"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            views={['month', 'year']}
                                            format="MM/YYYY"
                                            label="Built Date"
                                            value={dayjs(field.value)}
                                            onChange={field.onChange}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: false
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Is the vehicle fitted with a personal plate?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="personalPlateOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="YES"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="NO"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        {watch('personalPlateOption') === 'YES' && (
                            <Grid item xs={12}>
                                <Controller
                                    name="newRego"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label="New Rego"
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Grid>
                        )}

                        {/* // Statement */}
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="accessories"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        multiline
                                        rows={2}
                                        variant="outlined"
                                        label="Accessories"
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                Section 1
                            </Typography>
                        </Grid>

                        {/* a. Engine Replacement */}
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                (a) The engine HAS / HAS NOT been replaced by/on
                                behalf of the vendor.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="engineReplaceOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="HAS"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="HAS NOT"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        {watch('engineReplaceOption') === 'YES' && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        If replaced, the approximate date of
                                        replacement was:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <Controller
                                            name="engineReplaceDate"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    format="DD/MM/YYYY"
                                                    label="Date"
                                                    value={dayjs(field.value)}
                                                    onChange={field.onChange}
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            error: false
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </>
                        )}

                        {/* b. Odometer Replacement */}
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                (b) The odometer HAS / HAS NOT been replaced
                                by/on behalf of the vendor, and I confirm to the
                                best of my knowledge that the odometer reading
                                is correct.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="odometerReplaceOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="HAS"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="HAS NOT"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* c. Concessional Registration */}
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                (c) The Concessional Registration HAS / HAS NOT
                                been claimed in respect of the vehicle by the
                                vendor.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="concessionalRegistrationOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="HAS"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="HAS NOT"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* d. Air Bags Activation */}
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                (d) If fitted with Air Bags, have the Air Bags
                                been activated?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="airBagsActivatedOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="YES"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="NO"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* e. Vehicle Modifications Approval */}
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                (e) That any conversion or modification from the
                                manufacturer's specifications HAS / HAS NOT been
                                approved by the Department of Transport and Main
                                Roads and a Letter of Approval is held and
                                modification plates are fixed to the vehicle.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="modificationsApprovalOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="HAS"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="HAS NOT"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* f. Written Off Vehicle Register Current Status */}
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                (f) The vehicle IS / IS NOT currently on the
                                written off vehicle register.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="currentWrittenOffRegisterOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="IS"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="IS NOT"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* g. Written Off Vehicle Register Previous Status */}
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                (g) The vehicle HAS / HAS NOT been previously
                                recorded on the written-off vehicle register.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="previousWrittenOffRegisterOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="HAS"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="HAS NOT"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* Section 2 */}
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                Section 2
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="withSecurityInterest"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="(a) The vehicle is the vendor's sole and absolutely unencumbered property and free from any Consumer Mortgage, Bill of Sale, Hire Purchase Agreement, lien, charge, or an adverse interest whatsoever, and no person or corporation has any rights, title or interest therein and the vendor has good right and title to sell same."
                                        />
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="(b) There is an encumbrance, charge, lien, or other adverse interest whatsoever by the way of Bill of Sale / Hire Purchase Agreement / Other over the said vehicle."
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* Security Interest Details if any */}
                        {watch('withSecurityInterest') === 'YES' && (
                            <>
                                <Grid item xs={12} sm={8}>
                                    <Controller
                                        name="lenderName"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Name of Lender"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Controller
                                        name="loanAmount"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                label="Amount (inclusive of GST)"
                                                variant="outlined"
                                                type="number"
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}

                        {/* Encumbrance Payout Status */}
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                (c) The said encumbrance IS / IS NOT to be paid
                                out by the Motor Dealer.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="payoutByDealer"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="IS"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="IS NOT"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* Section 4 */}
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                Section 4
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                4. The vehicle HAS / HAS NOT been used as a Taxi
                                or Hire Car.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="taxiOrHireCarUsage"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="HAS"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="HAS NOT"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* Section 6 */}
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                Section 6
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                6. The vehicle IS / IS NOT to be sold at
                                auction.{' '}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="auctionOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="IS"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="IS NOT"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                        {watch('auctionOption') === 'YES' && (
                            <Grid item xs={12}>
                                <Controller
                                    name="reservePrice"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label="Auction Reserve Price ($)"
                                            variant="outlined"
                                            type="number"
                                        />
                                    )}
                                />
                            </Grid>
                        )}

                        {/* Section 8 */}
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                Section 8
                            </Typography>
                        </Grid>
                        {/* 8(i) GST Registration Status */}
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                (i) I am registered or required to be registered
                                for GST
                            </Typography>
                            <Controller
                                name="gstRegistrationOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="YES"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="NO"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* 8(ii) Taxable Supply Status */}
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                (ii) The supply of the above mentioned vehicle
                                by me represents a taxable supply by me in the
                                course of an enterprise which I carry on
                            </Typography>
                            <Controller
                                name="taxableSupplyOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="YES"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="NO"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        {/* 8(iii) GST-Free Supply Status */}
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                (iii) The supply of the above mentioned vehicle
                                by me is not a GST-free supply
                            </Typography>
                            <Controller
                                name="gstFreeSupplyOption"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup {...field} row>
                                        <FormControlLabel
                                            value="YES"
                                            control={<Radio />}
                                            label="YES"
                                        />
                                        <FormControlLabel
                                            value="NO"
                                            control={<Radio />}
                                            label="NO"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>

                        {/* declar date */}

                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="declareTime"
                                    control={control}
                                    render={({ field }) => (
                                        <MobileDateTimePicker
                                            format="DD/MM/YYYY hh:mm a"
                                            label="Declare at"
                                            value={dayjs(field.value)}
                                            onChange={field.onChange}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: false,
                                                    InputProps: {
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <AccessTimeIcon />
                                                            </InputAdornment>
                                                        )
                                                    }
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12}>
                            WITNESS SIGNATURE:{' '}
                            <IconButton
                                // disabled={disabled}
                                onClick={clearSignature}
                            >
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Controller
                                name="witnessSigDataURL"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <SignatureCanvas
                                            penColor="black"
                                            disabled={false}
                                            canvasProps={{
                                                width: 430,
                                                height: 200,
                                                className: 'sigCanvas',
                                                style: {
                                                    border: '1px solid black'
                                                }
                                            }}
                                            ref={witnessSigCanvas}
                                            // save user signature dataURL to form data
                                            onEnd={saveSignature}
                                        />
                                    </>
                                )}
                            />
                        </Grid>

                        {/* // buttons to preview and email the form data */}
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" color="error">
                                * Always preview the changes before emailing the
                                customer.
                            </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                            <Button
                                onClick={handleSubmit(handlePreviewChanges)}
                                variant="contained"
                                sx={{ my: 3 }}
                            >
                                Preview Changes
                            </Button>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                            <Button
                                onClick={handleSubmit(onOpenEmailDialog)}
                                variant="contained"
                                sx={{ my: 3 }}
                                disabled={!hasPreviewed}
                            >
                                Email Customer
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
            <Dialog open={openEmailDialog}>
                <DialogTitle>Email Customer</DialogTitle>
                <DialogContent>
                    An email will be sent to <b>{customer?.name}</b> at the
                    following email address: <b>{customer?.email}</b> <br />{' '}
                    Always preview the contract before sending an email to the
                    customer.{' '}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEmailDialog(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit(handleClickSendEmail)}
                    >
                        Send Email
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DealerVendorStatementForm;
