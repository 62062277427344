const BlankDeliveryBox = (doc, fillInformation, notes) => {
  doc.rect(107, 185, 93, 30); // The box around the delivery receipt
  doc
    .setFont('helvetica', 'bold')
    .setFontSize(9)
    .text('DELIVERY RECEIPT', 109, 188);
  doc
    .setFont('helvetica', 'normal')
    .setFontSize(7)
    .text('I acknowledge receipt of the vehicle as ordered.', 109, 192);
  doc.text(
    'DATED this ______________ Day of ___________________ Year __________',
    109,
    198
  );
  doc.setFont('helvetica', 'bold').text('SIGNATURE OF', 109, 202);
  doc.text('THE PURCHASER: ', 109, 206).line(133, 206, 200, 206);
  doc.text('WITNESS: ', 109, 213).line(128, 213, 200, 213);

  /* ---------------------------------- Notes --------------------------------- */
  doc.rect(107, 217, 93, 66); // The box around the notes
  doc
    .setFont('helvetica', 'bold')
    .setFontSize(9)
    .text('ADDITIONAL NOTES', 109, 220);
  doc
    .setFont('helvetica', 'italic')
    .setFontSize(7)
    .text(
      'The following notes are to be considered part of this contract and are legally',
      109,
      224
    );
  doc.text('binding to both Customer and Motor Dealer', 109, 227);

  if (fillInformation) {
    let string = notes;
    let splitText = doc.splitTextToSize(string, 87);
    doc
      .setFont('helvetica', 'normal')
      .setFontSize(9)
      .text(splitText, 109, 231, { maxWidth: 87, align: 'left' });
  }
};

export default BlankDeliveryBox;
