// IMPORTS
import {
    CircularProgress,
    Fab,
    Grid,
    IconButton,
    TableRow,
    Typography
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Balance, Print } from '@mui/icons-material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import Paper from '../../../global/Paper';
// INTERFACE
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
// LOGIC
import DownloadABA from '../logic/DownloadABA';
import GeneratePaymentRemittance from '../logic/GeneratePaymentRemittance';
import GetCreditors from '../../../global/databaseLogic/GetCreditors';
import HandleGenerateABA from '../logic/HandleGenerateABA';
import PDFCreditorBalanceReport from '../logic/PDFCreditorBalanceReport';
import { CalculateAmountRemaining } from '../logic/InvoiceSorting';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface CreditorLine {
    Creditor: Creditor;
    Invoices: any[];
    InvoiceTotal: number;
    PaymentTotal: number;
    PaymentRemaining: number;
}

interface CreditorReconciliationProps {
    readyInvoices: any[];
    showSnackbar: showSnackbar;
}

/**
 * FinaliseAndPay
 * Confirm the creditor lines and generate the ABA file
 * @author Estienne
 * @param CreditorReconciliationProps
 */
const FinaliseAndPay = ({
    readyInvoices,
    showSnackbar
}: CreditorReconciliationProps) => {
    const [creditorLines, setCreditorLines] = useState<CreditorLine[]>([]);
    const [creditors, setCreditors] = useState<Creditor[]>([]);
    const [totals, setTotals] = useState({
        invoice: 0,
        payment: 0,
        remaining: 0
    });
    const [file, setFile] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const payColumns = [
        { id: 0, label: '' },
        { id: 1, label: 'Payment Details', align: 'left' },
        { id: 2, label: 'Creditor' },
        { id: 3, label: 'Invoice Total' },
        { id: 4, label: 'Payment Total' },
        { id: 5, label: 'Payment Remaining' },
        { id: 6, label: '' }
    ];

    // Get the creditor details
    useEffect(() => {
        if (readyInvoices.length > 0) {
            getCreditorDetails(readyInvoices);
        } else {
            setLoading(false);
        }
    }, [readyInvoices]);

    // Bundle the creditor details with the invoices
    useEffect(() => {
        if (creditors.length > 0) {
            createCreditorLines(creditors, readyInvoices, setCreditorLines);
        }
        // eslint-disable-next-line
    }, [creditors]);

    // Stop loading once creditor lines has been populated
    useEffect(() => {
        if (creditorLines.length > 0) {
            setLoading(false);
        }
    }, [creditorLines]);

    // Save the generated ABA file
    useEffect(() => {
        if (file) {
            DownloadABA(file);
        }
    }, [file]);

    /**
     * getCreditorDetails
     * Bundle supplier IDs of ready invoices into a set
     * and get the details of all creditors from the server
     * @author Estienne
     * @param invoices the ready invoices
     */
    const getCreditorDetails = (invoices: any[]) => {
        let creditors = new Set<number>();
        invoices.forEach((invoice) => {
            creditors.add(invoice.SupplierId);
        });

        // Convert the set to an array and get the creditors
        GetCreditors(Array.from(creditors), setCreditors);
    };

    /**
     * createCreditorLines
     * Bundle the creditors and their invoices together
     * @author Estienne
     * @param creditors the creditors
     * @param invoices the ready invoices
     * @param setCreditorLines react state setter for the creditor lines
     */
    const createCreditorLines = (
        creditors: Creditor[],
        invoices: any[],
        setCreditorLines: Dispatch<SetStateAction<CreditorLine[]>>
    ) => {
        let creditorLines = [];
        let totalInvoice = 0;
        let totalPayment = 0;
        let totalRemaining = 0;

        creditors.forEach((creditor) => {
            let creditorInvoices = invoices.filter(
                (invoice) => invoice.SupplierId === creditor.id
            );

            // Sum the invoice total, payment total and total remaining values
            let lineInvoice = 0;
            let linePayment = 0;
            let lineRemaining = 0;
            creditorInvoices.forEach((invoice) => {
                lineInvoice += parseFloat(invoice.Invoice.documentTotal);
                linePayment += parseFloat(invoice.paymentAmount);
                lineRemaining += CalculateAmountRemaining(
                    parseFloat(invoice.Invoice.documentTotal),
                    parseFloat(invoice.paymentAmount),
                    invoice.paymentHistory
                );
            });

            let creditorLine = {
                Creditor: creditor,
                Invoices: creditorInvoices,
                InvoiceTotal: lineInvoice,
                PaymentTotal: linePayment,
                PaymentRemaining: lineRemaining
            };
            creditorLines.push(creditorLine);

            totalInvoice += lineInvoice;
            totalPayment += linePayment;
            totalRemaining += lineRemaining;
        });

        setCreditorLines(creditorLines);
        setTotals({
            ...totals,
            invoice: totalInvoice,
            payment: totalPayment,
            remaining: totalRemaining
        });
    };

    return (
        <>
            {loading ? (
                <Paper>
                    <Typography align="center">
                        <CircularProgress />
                    </Typography>
                </Paper>
            ) : creditorLines.length > 0 ? (
                <Grid container spacing={2}>
                    <Grid item>
                        <Paper textAlign="center">
                            <DataTable columns={payColumns}>
                                {creditorLines.map((line) => (
                                    <TableRow>
                                        <DataCell width="10%">
                                            <Typography
                                                variant="body2"
                                                align="right"
                                            >
                                                <b>BSB:</b>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="right"
                                            >
                                                <b>Acc Num:</b>
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="right"
                                            >
                                                <b>Reference:</b>
                                            </Typography>
                                        </DataCell>
                                        <DataCell width="15%">
                                            <Typography
                                                variant="body2"
                                                align="left"
                                            >
                                                {
                                                    line.Creditor.paymentDetails
                                                        .bsb
                                                }
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="left"
                                            >
                                                {
                                                    line.Creditor.paymentDetails
                                                        .accountNumber
                                                }
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                align="left"
                                            >
                                                {
                                                    line.Creditor.paymentDetails
                                                        .defaultReference
                                                }
                                            </Typography>
                                        </DataCell>
                                        <DataCellColoured
                                            handleClick={() =>
                                                window.open(
                                                    `/viewCreditor/${line.Creditor.id}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.Creditor.name}
                                        </DataCellColoured>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                line.InvoiceTotal
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                line.PaymentTotal
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                line.PaymentRemaining
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            <IconButton
                                                onClick={() =>
                                                    GeneratePaymentRemittance({
                                                        creditors: [line],
                                                        showSnackbar:
                                                            showSnackbar
                                                    })
                                                }
                                            >
                                                <Print />
                                            </IconButton>
                                        </DataCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <DataCell></DataCell>
                                    <DataCell></DataCell>
                                    <DataCell>
                                        <b>TOTAL</b>
                                    </DataCell>
                                    <DataCell>
                                        <b>
                                            {CurrencyFormatter(totals.invoice)}
                                        </b>
                                    </DataCell>
                                    <DataCell>
                                        <b>
                                            {CurrencyFormatter(totals.payment)}
                                        </b>
                                    </DataCell>
                                    <DataCell>
                                        <b>
                                            {CurrencyFormatter(
                                                totals.remaining
                                            )}
                                        </b>
                                    </DataCell>
                                    <DataCell>
                                        <IconButton
                                            onClick={() =>
                                                GeneratePaymentRemittance({
                                                    creditors: creditorLines,
                                                    showSnackbar: showSnackbar
                                                })
                                            }
                                        >
                                            <Print />
                                        </IconButton>
                                    </DataCell>
                                </TableRow>
                            </DataTable>
                            <br />
                            <LoadingButton
                                loading={buttonLoading}
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    HandleGenerateABA({
                                        creditors: creditorLines,
                                        setFile: setFile,
                                        setButtonLoading: setButtonLoading,
                                        showSnackbar: showSnackbar
                                    })
                                }
                            >
                                GENERATE ABA
                            </LoadingButton>
                        </Paper>
                    </Grid>
                </Grid>
            ) : (
                <Paper>
                    <Typography variant="h6" align="center">
                        No 'Ready' invoices have been selected to be paid
                    </Typography>
                </Paper>
            )}

            <div
                style={{
                    margin: 0,
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
            >
                <Fab
                    color="primary"
                    onClick={(e) => PDFCreditorBalanceReport()}
                >
                    <Balance />
                </Fab>
            </div>
        </>
    );
};

export default FinaliseAndPay;
