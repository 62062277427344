import { useContext, useEffect, useRef } from 'react';
import { User } from '../../../global/interfaces/GeneralInterface';
import { UserContext } from '../../../../main/Main';
import GetUserSignature from '../../../global/databaseLogic/GetUserSignature';
import SignatureCanvas from 'react-signature-canvas';
import { Grid, IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';
import Paper from '../../../global/Paper';

const UserSignature = ({ userId, userDetails, setUserDetails, readOnly }) => {
    const currentUser = useContext<User>(UserContext);
    let sigCanvas = useRef<SignatureCanvas>(null);

    useEffect(() => {
        GetUserSignature(parseInt(userId), sigCanvas);
    }, [userId]);

    useEffect(() => {
        if (sigCanvas.current) {
            if (readOnly) {
                sigCanvas.current.off();
            } else {
                sigCanvas.current.on();
            }
        }
        // eslint-disable-next-line
    }, [readOnly]);

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    const formatIntoPng = () => {
        if (sigCanvas.current) {
            const dataURL = sigCanvas.current.toDataURL();
            setUserDetails({ ...userDetails, signature: dataURL });
            return dataURL;
        }
    };
    return userDetails.id === currentUser.id ? (
        <>
            <Paper>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {Object.hasOwn(userDetails, 'id') &&
                        currentUser.id === userDetails.id ? (
                            <Grid item xs={12} textAlign={'center'}>
                                User Signature (This is used to prefill
                                e-signature.){' '}
                                <IconButton
                                    disabled={readOnly}
                                    onClick={() => clearSignature()}
                                >
                                    <Clear />
                                </IconButton>
                                <br />
                                <SignatureCanvas
                                    disabled={readOnly}
                                    penColor={'black'}
                                    canvasProps={{
                                        width: 350,
                                        height: 150,
                                        className: 'sigCanvas',
                                        style: {
                                            border: '1px solid black'
                                        }
                                    }}
                                    ref={sigCanvas}
                                    onEnd={() => formatIntoPng()}
                                />
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </Paper>
            <br />
        </>
    ) : null;
};
export default UserSignature;
