// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// INTERFACES
import { Credit } from '../../../global/interfaces/PartsInterface';
// LOGIC
import api from '../../../../../api';
import { DateFormatter } from '../../../global/logic/Formatters';

/**
 * GetCredit
 * Retrieve all details for a previous credit
 * @author Estienne
 * @param id the desired credit id (or 'FREIGHT' or 'VOUCHER #xyz')
 * @param getAllCredits a check for whether we need all credits or a specific credit
 * @param setCredit state setter for an individual credit
 * @param setTitle state setter for the drawer title
 * @param setSubtitle state setter for the drawer subtitle
 */
const GetCredit = (
  id: string,
  getAllCredits: boolean,
  setCredit: Dispatch<SetStateAction<Credit>>,
  setTitle: Dispatch<SetStateAction<string>>,
  setSubtitle: Dispatch<SetStateAction<string>>
) => {
  // If we're opening the Freight credit drawer, get the credit with a valid freightRefunded amount
  if (getAllCredits) {
    api.get(`creditsByOrder/${id}`).then((res) => {
      if (res.status === 200) {
        let credits = res.data;

        // Retrieve the credit containing the refunded freight
        let freightCredit = credits.filter(
          (credit) => credit.freightRefunded != null
        )[0];

        // Otherwise, retrieve the credit containing a specific refunded voucher
        setCredit(freightCredit);
        setTitle(`Credit #${freightCredit.id}`);
        setSubtitle(
          `Created ${DateFormatter(freightCredit.createdAt)} by ${freightCredit.User.firstName} ${freightCredit.User.lastName}`
        );
      }
    });
  }

  // Otherwise, get credit by credit id
  else {
    api.get(`creditNextGen/${id}`).then((res) => {
      if (res.status === 200) {
        let credit = res.data;
        setCredit(credit);
        setTitle(`Credit #${credit.id}`);
        setSubtitle(
          `Created ${DateFormatter(credit.createdAt)} by ${credit.User.firstName} ${credit.User.lastName}`
        );
      }
    });
  }
};

export default GetCredit;
