import api from '../../../../../api';

const PrintLOA = async (customerType, saleDetails, setLoadingButton) => {
    try {
        const result = await api.post('/printLOA', {
            customerType,
            saleDetails
        });
        const base64PDF = result.data;

        if (typeof base64PDF !== 'string') {
            throw new Error('Expected a base64 string');
        }
        const pdfBytes = Uint8Array.from(atob(base64PDF), (c) =>
            c.charCodeAt(0)
        );

        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
        setLoadingButton(null);
    } catch (error) {
        console.error('Error printing LOA:', error);
    }
};

export default PrintLOA;
