import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Pagination,
    Table,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import {
    History,
    Print,
    RadioButtonChecked,
    RadioButtonUnchecked
} from '@mui/icons-material';
import { useEffect, useState } from 'react';

// COMPONENTS
import Paper from '../../../global/Paper';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import Drawer from '../../../global/Drawer';

// LOGIC
import {
    CurrencyFormatter,
    DateFormatter,
    ToFixed
} from '../../../global/logic/Formatters';
import { HandleUnreconcilePayrun } from '../logic/HandleUnreconcilePayrun';
import RegeneratePaymentRemittance from '../../../admin/creditorReconciliation/logic/RegeneratePaymentRemittance';
import GetAllPaymentRunForSupplier from '../../../global/databaseLogic/GetAllPaymentRunForSupplier';

// INTERFACES
import { PaymentRun } from '../../../global/interfaces/AdminInterface';
import { Invoice } from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import RoleCheck from '../../../global/RoleCheck';

interface PaymentRunsProps {
    CreditorId: number;
    showSnackbar: showSnackbar;
}

// Constant variable for rows per page
const rowsPerPage = 10;

const PaymentRuns = ({ CreditorId, showSnackbar }: PaymentRunsProps) => {
    const [paymentRuns, setPaymentRuns] = useState([]);
    const [page, setPage] = useState<number>(1);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [selectedPayrun, setSelectedPayrun] = useState<{
        id: number;
        payrun: PaymentRun;
        Invoices: Invoice[];
    }>({
        id: null,
        payrun: null,
        Invoices: []
    });

    const [unreconcileDialogOpen, setUnreconcileDialogOpen] =
        useState<boolean>(false);
    const [matchedBankLines, setMatchedBankLines] = useState<any[]>([]);
    const [selectedBankLine, setSelectedBankLine] = useState<any>({});

    const columns = [
        { id: 0, label: '', width: 1 },
        { id: 1, label: 'Payment Run ID', width: 100 },
        { id: 2, label: 'Date', width: 100 },
        { id: 3, label: 'Number of Invoices', width: 50 },
        { id: 4, label: 'Payment Amount', width: 100 },
        { id: 5, label: 'User', width: 200 },
        { id: 6, label: '', width: 100 }
    ];

    const drawerColumns = [
        { id: 0, label: 'Invoice ID', width: 100 },
        { id: 2, label: 'Document Reference', width: 200 },
        { id: 3, label: 'Document Date', width: 100 },
        { id: 4, label: 'Document Total', width: 150 },
        { id: 5, label: 'Site', width: 150 },
        { id: 6, label: 'Due Date', width: 150 }
    ];

    useEffect(() => {
        GetAllPaymentRunForSupplier(CreditorId, setPaymentRuns);
    }, [CreditorId]);

    // Handle changing the page
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const getPayrunTotal = (invoices) => {
        let total = 0;
        for (let invoice of invoices) {
            if (invoice.AssociatedInvoice) {
                total +=
                    parseFloat(invoice?.AssociatedInvoice?.documentTotal) ?? 0;
            }
        }
        return ToFixed(total);
    };

    const getLink = (invoice) => {
        let url = '';
        if (
            invoice.invoiceType === 'StockInvoice' &&
            invoice?.AssociatedInvoice?.invoiceType === 'Credit'
        ) {
            url = `admin/creditInvoice/${invoice.AssociatedInvoice.id}`;
        } else if (
            invoice.invoiceType === 'StockInvoice' &&
            invoice?.AssociatedInvoice?.invoiceType !== 'Credit'
        ) {
            url = `/inventory/viewInvoice/${invoice.id}`;
        } else if (invoice.invoiceType === 'VehicleInvoice') {
            url = `/inventory/viewInvoice/${invoice.id}`;
        } else if (invoice.invoiceType === 'VehicleRegistration') {
            url = `/inventory/viewInvoice/${invoice.id}`;
        } else if (invoice.invoiceType === 'CreditInvoice') {
            url = `/inventory/viewInvoice/${invoice.id}`;
        } else if (invoice.invoiceType === 'ServiceWarranty') {
            url = `admin/viewServiceWarrantyInvoice/${invoice.AssociatedInvoice.id}`;
        } else if (invoice.invoiceType === 'VehicleRebate') {
            url = `admin/viewRebateInvoice/${invoice.AssociatedInvoice.id}`;
        }
        return url;
    };

    const getSite = (invoice) => {
        let site = '';
        if (invoice.AssociatedInvoice?.Site?.name) {
            site = invoice.AssociatedInvoice?.Site?.name;
        }
        return site;
    };

    const getDate = (invoice) => {
        let date = '';
        if (invoice.AssociatedInvoice?.invoiceDate) {
            date = DateFormatter(invoice.AssociatedInvoice?.invoiceDate);
        } else if (invoice.AssociatedInvoice?.dateReceived) {
            date = DateFormatter(invoice.AssociatedInvoice?.dateReceived);
        } else {
            date = DateFormatter(invoice.createdAt);
        }
        return date;
    };
    let currentTheme = useTheme();
    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant="h5">Payment Runs</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Pagination
                            color="primary"
                            count={
                                paymentRuns.length <= rowsPerPage
                                    ? 1
                                    : Math.ceil(
                                          paymentRuns.length / rowsPerPage
                                      )
                            }
                            page={page}
                            onChange={handleChange}
                            sx={{ display: 'flex', justifyContent: 'right' }}
                        />
                    </Grid>
                </Grid>

                <br />
                <DataTable columns={columns}>
                    {paymentRuns
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((payrun) => {
                            if (payrun.payrun) {
                                return (
                                    <TableRow>
                                        <DataCell>
                                            <Chip
                                                variant="outlined"
                                                color={
                                                    payrun.payrun?.reconciled
                                                        ? 'success'
                                                        : 'error'
                                                }
                                                label={
                                                    payrun.payrun?.reconciled
                                                        ? 'Reconciled'
                                                        : 'Unreconciled'
                                                }
                                            />
                                        </DataCell>
                                        <DataCellColoured
                                            handleClick={() => {
                                                setSelectedPayrun(payrun);
                                                setOpenDrawer(true);
                                            }}
                                        >
                                            {payrun.id}
                                        </DataCellColoured>
                                        <DataCell>
                                            {DateFormatter(
                                                payrun.payrun?.createdAt
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {payrun.Invoices.length}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                getPayrunTotal(payrun.Invoices)
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {payrun.payrun?.User?.firstName}{' '}
                                            {payrun.payrun?.User?.lastName}
                                        </DataCell>
                                        <DataCell>
                                            <Tooltip title="Regenerate Payment Remittance">
                                                <IconButton
                                                    onClick={() => {
                                                        RegeneratePaymentRemittance(
                                                            {
                                                                CreditorId:
                                                                    CreditorId,
                                                                paymentRun:
                                                                    payrun
                                                            }
                                                        );
                                                    }}
                                                >
                                                    <Print />
                                                </IconButton>
                                            </Tooltip>
                                            {payrun.payrun?.reconciled ? (
                                                <RoleCheck
                                                    permission="unreconcile_payrun"
                                                    component={
                                                        <Tooltip title="Unreconcile Transaction">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setSelectedPayrun(
                                                                        payrun
                                                                    );
                                                                    HandleUnreconcilePayrun(
                                                                        payrun,
                                                                        setUnreconcileDialogOpen,
                                                                        setMatchedBankLines,
                                                                        null,
                                                                        showSnackbar
                                                                    );
                                                                }}
                                                            >
                                                                <History />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                />
                                            ) : null}
                                        </DataCell>
                                    </TableRow>
                                );
                            } else {
                                return null;
                            }
                        })}
                </DataTable>
            </Paper>

            <Dialog
                fullWidth
                maxWidth="md"
                open={unreconcileDialogOpen}
                onClose={() => {
                    setUnreconcileDialogOpen(false);
                }}
            >
                <DialogTitle>
                    Unreconcile Payrun #{selectedPayrun.id}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ora find multiple match for the Payment Run you wish to
                        unreconcile. Please select the transaction you wish to
                        unreconcile from the list below:
                    </DialogContentText>
                    <br />
                    <Table>
                        {matchedBankLines.map((line) => (
                            <>
                                {line.BankTransactionId ===
                                selectedBankLine.BankTransactionId ? (
                                    <TableRow
                                        sx={{
                                            cursor: 'pointer',
                                            border: `2px solid ${currentTheme.palette.primary.main}`
                                        }}
                                        onClick={() =>
                                            setSelectedBankLine(line)
                                        }
                                    >
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                        >
                                            <RadioButtonChecked color="primary" />
                                        </TableCell>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                        >
                                            {line.reference} <br /> {line.date}{' '}
                                            <br />{' '}
                                            {CurrencyFormatter(line.amount)}
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <TableRow
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            setSelectedBankLine(line)
                                        }
                                    >
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                        >
                                            <RadioButtonUnchecked color="primary" />
                                        </TableCell>
                                        <TableCell
                                            sx={{ borderBottom: 'none' }}
                                        >
                                            {line.reference} <br /> {line.date}{' '}
                                            <br />{' '}
                                            {CurrencyFormatter(line.amount)}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </>
                        ))}
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUnreconcileDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() =>
                            HandleUnreconcilePayrun(
                                selectedPayrun,
                                setUnreconcileDialogOpen,
                                setMatchedBankLines,
                                selectedBankLine.BankTransactionId,
                                showSnackbar
                            )
                        }
                        disabled={
                            !Object.hasOwn(
                                selectedBankLine,
                                'BankTransactionId'
                            )
                        }
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>

            <Drawer
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                title={`Invoices Paid on Payment Run #${selectedPayrun.id}`}
                subTitle={`Created on: ${DateFormatter(selectedPayrun?.payrun?.createdAt)} by ${selectedPayrun?.payrun?.User.firstName} ${selectedPayrun?.payrun?.User.lastName}`}
                width="35vw"
            >
                <DataTable columns={drawerColumns}>
                    {selectedPayrun.Invoices.map((invoice) => (
                        <TableRow>
                            <DataCellColoured
                                handleClick={() =>
                                    window.open(getLink(invoice), '_blank')
                                }
                            >
                                {invoice.id}
                            </DataCellColoured>
                            <DataCell>
                                {invoice.AssociatedInvoice?.documentReference}
                            </DataCell>
                            <DataCell>{getDate(invoice)}</DataCell>
                            <DataCell>
                                {CurrencyFormatter(
                                    invoice.AssociatedInvoice?.documentTotal
                                )}
                            </DataCell>
                            <DataCell>{getSite(invoice)}</DataCell>
                            <DataCell>
                                {DateFormatter(invoice.paymentDate)}
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Drawer>
        </>
    );
};

export default PaymentRuns;
