import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Box, Chip, CircularProgress, Grid, Typography } from '@mui/material';
import PageWrapper from '../../global/PageWrapper';
import { PDFDocument, rgb } from 'pdf-lib';

import { Snack } from '../../global/interfaces/GlobalInterface';
import Snackbar from '../../global/Snackbar';

import DealerVendorStatementForm from './components/DealerVendorStatementForm';
import {
    GetFileFromS3,
    UploadFileToS3
} from '../newVehicleSale/logic/S3FileService';
import {
    DateSafeFormatter,
    MonthYearFormatter
} from '../../global/logic/Formatters';
import { EmailSignUrl } from '../signContract/logic/EmailContract';
import GetVendorStatementInfo from '../signContract/logic/GetVendorStatementInfo';

const DealerSignVendorStatement = () => {
    let { id: vehicleId } = useParams<{ id: string }>();

    const [snackbar, setSnackbar] = useState<Snack>({
        open: false,
        severity: '',
        message: ''
    });

    const [pdfDoc, setPdfDoc] = useState<PDFDocument>(null);
    const [pdfUrl, setPdfUrl] = useState<string>(null);

    const [customer, setCustomer] = useState(null);
    const [contractStatus, setContractStatus] = useState(null);

    const getContractInfo = () => {
        GetVendorStatementInfo(
            vehicleId,
            setCustomer,
            setContractStatus,
            setSnackbar
        );
    };

    useEffect(() => {
        getContractInfo();
        // eslint-disable-next-line
    }, [vehicleId]);

    const LoadContractPDF = () => {
        GetFileFromS3({
            folderName: 'vendorStatements',
            fileName: parseInt(vehicleId),
            setPdfDoc: setPdfDoc,
            setPdfUrl: setPdfUrl
        });
    };

    useEffect(() => {
        LoadContractPDF();
        // eslint-disable-next-line
    }, [vehicleId]);

    const baseURL = window.location.origin;
    const customerSignStatementUrl = `${baseURL}/esign/vendor-statement/${vehicleId}`;

    const handleEmailCustomer = (pdfBlob) => {
        // Convert blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = () => {
            const base64data = reader.result as string;
            // Upload the updated contract to S3
            UploadFileToS3({
                base64data: base64data,
                folderName: 'vendorStatements',
                fileName: parseInt(vehicleId),
                setSnackbar: setSnackbar
            });

            EmailSignUrl({
                type: 'vendor-statement',
                id: parseInt(vehicleId),
                customerSignUrl: customerSignStatementUrl,
                setSnackbar: setSnackbar
            });
        };
    };

    // display the form data on corresponding PDF fields
    const onDealerVendorStatementSubmit = async (formData, submitType) => {
        if (!pdfDoc) return;

        // Make a copy of original document, so everytime formData changes, we start from the original document
        const pdfDocCopy = await PDFDocument.load(await pdfDoc.save());

        try {
            const pages = pdfDocCopy.getPages();
            const firstPage = pages[0];

            // customer acn
            if (formData.acn !== '') {
                firstPage.drawText(formData.acn, {
                    x: 55,
                    y: 572,
                    size: 9
                });
            }

            //vehicle details
            if (formData.builtDate) {
                firstPage.drawText(
                    MonthYearFormatter(formData.builtDate.toISOString()),
                    {
                        x: 350,
                        y: 630,
                        size: 9
                    }
                );
            }

            if (formData.personalPlateOption === 'YES') {
                firstPage.drawText('X', {
                    x: 369,
                    y: 592,
                    size: 9
                });
            }

            if (formData.personalPlateOption === 'NO') {
                firstPage.drawText('X', {
                    x: 441,
                    y: 592,
                    size: 9
                });
            }

            if (formData.newRego !== '') {
                firstPage.drawText(formData.newRego, {
                    x: 350,
                    y: 572,
                    size: 9
                });
            }

            // accessories
            if (formData.accessories !== '') {
                firstPage.drawText(formData.accessories, {
                    x: 75,
                    y: 488,
                    size: 8
                });
            }

            if (formData.engineReplaceOption === 'NO') {
                firstPage.drawLine({
                    start: { x: 90, y: 476 },
                    end: { x: 105, y: 476 },
                    thickness: 1
                });
            }

            if (formData.engineReplaceOption === 'YES') {
                firstPage.drawLine({
                    start: { x: 110, y: 476 },
                    end: { x: 140, y: 476 },
                    thickness: 1
                });

                if (formData.engineReplaceDate) {
                    firstPage.drawText(
                        DateSafeFormatter(
                            formData.engineReplaceDate.toISOString()
                        ),
                        {
                            x: 450,
                            y: 475,
                            size: 8
                        }
                    );
                }
            }

            if (formData.odometerReplaceOption === 'NO') {
                firstPage.drawLine({
                    start: { x: 100, y: 462 },
                    end: { x: 115, y: 462 },
                    thickness: 1
                });
            }

            if (formData.odometerReplaceOption === 'YES') {
                firstPage.drawLine({
                    start: { x: 120, y: 462 },
                    end: { x: 150, y: 462 },
                    thickness: 1
                });
            }

            if (formData.concessionalRegistrationOption === 'NO') {
                firstPage.drawLine({
                    start: { x: 150, y: 448 },
                    end: { x: 165, y: 448 },
                    thickness: 1
                });
            }

            if (formData.concessionalRegistrationOption === 'YES') {
                firstPage.drawLine({
                    start: { x: 170, y: 448 },
                    end: { x: 200, y: 448 },
                    thickness: 1
                });
            }

            if (formData.airBagsActivatedOption === 'NO') {
                firstPage.drawLine({
                    start: { x: 225, y: 434 },
                    end: { x: 240, y: 434 },
                    thickness: 1
                });
            }

            if (formData.airBagsActivatedOption === 'YES') {
                firstPage.drawLine({
                    start: { x: 245, y: 434 },
                    end: { x: 260, y: 434 },
                    thickness: 1
                });
            }

            if (formData.modificationsApprovalOption === 'NO') {
                firstPage.drawLine({
                    start: { x: 285, y: 420 },
                    end: { x: 300, y: 420 },
                    thickness: 1
                });
            }

            if (formData.modificationsApprovalOption === 'YES') {
                firstPage.drawLine({
                    start: { x: 305, y: 420 },
                    end: { x: 335, y: 420 },
                    thickness: 1
                });
            }

            if (formData.currentWrittenOffRegisterOption === 'NO') {
                firstPage.drawLine({
                    start: { x: 85, y: 397 },
                    end: { x: 95, y: 397 },
                    thickness: 1
                });
            }

            if (formData.currentWrittenOffRegisterOption === 'YES') {
                firstPage.drawLine({
                    start: { x: 100, y: 397 },
                    end: { x: 125, y: 397 },
                    thickness: 1
                });
            }

            if (formData.previousWrittenOffRegisterOption === 'NO') {
                firstPage.drawLine({
                    start: { x: 90, y: 383 },
                    end: { x: 105, y: 383 },
                    thickness: 1
                });
            }

            if (formData.previousWrittenOffRegisterOption === 'YES') {
                firstPage.drawLine({
                    start: { x: 110, y: 383 },
                    end: { x: 140, y: 383 },
                    thickness: 1
                });
            }

            // section 2
            if (formData.withSecurityInterest === 'NO') {
                firstPage.drawCircle({
                    x: 44,
                    y: 368,
                    size: 6,
                    borderWidth: 1,
                    borderColor: rgb(0, 0, 0),
                    opacity: 0
                });
            }

            if (formData.withSecurityInterest === 'YES') {
                firstPage.drawCircle({
                    x: 44,
                    y: 348,
                    size: 6,
                    borderWidth: 1,
                    borderColor: rgb(0, 0, 0),
                    opacity: 0
                });

                if (formData.lenderName !== '') {
                    firstPage.drawText(formData.lenderName, {
                        x: 60,
                        y: 333,
                        size: 8
                    });
                }

                if (formData.loanAmount !== '') {
                    firstPage.drawText(formData.loanAmount, {
                        x: 250,
                        y: 333,
                        size: 8
                    });
                }
            }

            if (formData.payoutByDealer === 'NO') {
                firstPage.drawLine({
                    start: { x: 123, y: 320 },
                    end: { x: 133, y: 320 },
                    thickness: 1
                });
            }

            if (formData.payoutByDealer === 'YES') {
                firstPage.drawLine({
                    start: { x: 135, y: 320 },
                    end: { x: 160, y: 320 },
                    thickness: 1
                });
            }

            // section 4
            if (formData.taxiOrHireCarUsage === 'NO') {
                firstPage.drawLine({
                    start: { x: 79, y: 292 },
                    end: { x: 89, y: 292 },
                    thickness: 1
                });
            }

            if (formData.taxiOrHireCarUsage === 'YES') {
                firstPage.drawLine({
                    start: { x: 99, y: 292 },
                    end: { x: 129, y: 292 },
                    thickness: 1
                });
            }

            // section 6
            if (formData.auctionOption === 'NO') {
                firstPage.drawLine({
                    start: { x: 73, y: 263 },
                    end: { x: 83, y: 263 },
                    thickness: 1
                });
            }

            if (formData.auctionOption === 'YES') {
                firstPage.drawLine({
                    start: { x: 88, y: 263 },
                    end: { x: 113, y: 263 },
                    thickness: 1
                });

                if (formData.reservePrice !== '') {
                    firstPage.drawText(formData.reservePrice, {
                        x: 340,
                        y: 262,
                        size: 8
                    });
                }
            }

            // section 8
            if (formData.gstRegistrationOption === 'NO') {
                firstPage.drawLine({
                    start: { x: 205, y: 212 },
                    end: { x: 225, y: 212 },
                    thickness: 1
                });
            }

            if (formData.gstRegistrationOption === 'YES') {
                firstPage.drawLine({
                    start: { x: 227, y: 212 },
                    end: { x: 237, y: 212 },
                    thickness: 1
                });
            }

            if (formData.taxableSupplyOption === 'NO') {
                firstPage.drawLine({
                    start: { x: 450, y: 198 },
                    end: { x: 470, y: 198 },
                    thickness: 1
                });
            }

            if (formData.taxableSupplyOption === 'YES') {
                firstPage.drawLine({
                    start: { x: 475, y: 198 },
                    end: { x: 485, y: 198 },
                    thickness: 1
                });
            }

            if (formData.gstFreeSupplyOption === 'NO') {
                firstPage.drawLine({
                    start: { x: 280, y: 184 },
                    end: { x: 300, y: 184 },
                    thickness: 1
                });
            }

            if (formData.gstFreeSupplyOption === 'YES') {
                firstPage.drawLine({
                    start: { x: 303, y: 184 },
                    end: { x: 313, y: 184 },
                    thickness: 1
                });
            }

            // declaration date
            if (formData.declareTime) {
                const time = formData.declareTime.format('h:mma');
                const day = formData.declareTime.format('D');
                const month = formData.declareTime.format('MMMM');
                const year = formData.declareTime.format('YYYY');
                firstPage.drawText(time, {
                    x: 100,
                    y: 105,
                    size: 9
                });

                firstPage.drawText(day, {
                    x: 270,
                    y: 105,
                    size: 9
                });

                firstPage.drawText(month, {
                    x: 400,
                    y: 105,
                    size: 9
                });

                firstPage.drawText(year, {
                    x: 500,
                    y: 105,
                    size: 9
                });
            }

            // witness signature
            if (formData.witnessSigDataURL) {
                const witnessSigImage = await pdfDocCopy.embedPng(
                    formData.witnessSigDataURL
                );
                firstPage.drawImage(witnessSigImage, {
                    x: 120,
                    y: 30,
                    width: 100,
                    height: 30
                });
            }

            const pdfBytes = await pdfDocCopy.save();
            const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

            if (submitType === 'preview') {
                setPdfUrl(URL.createObjectURL(pdfBlob));
            } else if (submitType === 'upload') {
                handleEmailCustomer(pdfBlob);
            }
        } catch (error) {
            console.error('Error loading PDF:', error);
        }
    };

    return (
        <PageWrapper>
            <Typography variant="h4">
                {`Vendor Statement #${vehicleId} `}{' '}
                {contractStatus && (
                    <Chip
                        label={
                            contractStatus === 'vendorSigned'
                                ? 'Vendor Signed'
                                : contractStatus === 'waitingSignature'
                                  ? 'Waiting for Vendor Signature'
                                  : 'Unsigned'
                        }
                        variant="outlined"
                        color={
                            contractStatus === 'vendorSigned'
                                ? 'success'
                                : contractStatus === 'waitingSignature'
                                  ? 'warning'
                                  : 'default'
                        }
                    />
                )}
            </Typography>

            <Grid container spacing={2}>
                {contractStatus !== 'waitingSignature' &&
                    contractStatus !== 'vendorSigned' && (
                        <Grid item xs={12} sm={6}>
                            <DealerVendorStatementForm
                                onDealerVendorStatementSubmit={
                                    onDealerVendorStatementSubmit
                                }
                                customer={customer}
                                contractStatus={contractStatus}
                            />
                        </Grid>
                    )}
                <Grid item xs={12} sm={contractStatus ? 12 : 6}>
                    {pdfUrl ? (
                        <Box sx={{ padding: 2 }} width="100%" height="200vh">
                            <iframe
                                src={pdfUrl + '#view=Fit'}
                                width="100%"
                                height="100%"
                                title={`Vendor Statement #${vehicleId}`}
                            ></iframe>
                        </Box>
                    ) : (
                        <Box
                            sx={{ padding: 2 }}
                            width="100%"
                            height="100%"
                            textAlign={'center'}
                        >
                            <CircularProgress />
                            <Typography variant="h5">
                                Loading vendor statement...
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
        </PageWrapper>
    );
};

export default DealerSignVendorStatement;
