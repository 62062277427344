// IMPORTS
import { useState } from 'react';
import {
    Paper,
    Button,
    Typography,
    Stepper,
    Step,
    StepLabel,
    CircularProgress,
    Grid
} from '@mui/material';

// COMPONENTS

// TS COMPONENTS
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';

// LOGIC
import SetupOra from './logic/SetupOra';
import ValidateStep1 from './logic/ValidateStep1';
import ValidateStep2 from './logic/ValidateStep2';
import ValidateStep3 from './logic/ValidateStep3';
import ValidateStep4 from './logic/ValidateStep4';
import { withSnackbar } from '../../OraNextGen/global/WrappingSnackbar';

const OnboardingUI = ({ showSnackbar }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);

    const [settings, setSettings] =
        useState <
        import('../../OraNextGen/global/interfaces/GlobalInterface').Snack({});
    const [sites, setSites] = useState([]);
    const [tills, setTills] = useState([]);
    const [user, setUser] = useState({});
    const [companyLogo, setCompanyLogo] = useState({
        method: 'url',
        picturePreview: null,
        pictureAsFile: null
    });

    // const [registrationPrices, setRegistrationPrices] = useState([
    //     {
    //         id: 1,
    //         vehicleType: 'Motorcycle',
    //         length: 6,
    //         prices: [
    //             {
    //                 motorVehicleFee: null,
    //                 trafficFee: null,
    //                 plateFee: null,
    //                 total: null
    //             }
    //         ],
    //         stampDuty: [
    //             { definition: '1-4 Cylinders', percentage: null },
    //             { definition: '5-6 Cylinders', percentage: null },
    //             { definition: '7+ Cylinders', percentage: null }
    //         ]
    //     },
    //     {
    //         id: 2,
    //         vehicleType: 'Motorcycle',
    //         length: 12,
    //         prices: [
    //             {
    //                 motorVehicleFee: null,
    //                 trafficFee: null,
    //                 plateFee: null,
    //                 total: null
    //             }
    //         ],
    //         stampDuty: [
    //             { definition: '1-4 Cylinders', percentage: null },
    //             { definition: '5-6 Cylinders', percentage: null },
    //             { definition: '7+ Cylinders', percentage: null }
    //         ]
    //     }
    // ]);
    // const [ctpPrices, setCtpPrices] = useState([
    //     {
    //         id: 1,
    //         class: 12,
    //         className: '1 Seat Motorcycle',
    //         length: 6,
    //         prices: [{ business: null, personal: null }]
    //     },
    //     {
    //         id: 2,
    //         class: 12,
    //         className: '1 Seat Motorcycle',
    //         length: 12,
    //         prices: [{ business: null, personal: null }]
    //     },
    //     {
    //         id: 3,
    //         class: 13,
    //         className: '2 Seat Motorcycle',
    //         length: 6,
    //         prices: [{ business: null, personal: null }]
    //     },
    //     {
    //         id: 4,
    //         class: 13,
    //         className: '2 Seat Motorcycle',
    //         length: 12,
    //         prices: [{ business: null, personal: null }]
    //     }
    // ]);

    const [step1Validation, setStep1Validation] = useState({
        companyName: {
            error: false,
            message: ''
        },
        legalEntityName: {
            error: false,
            message: ''
        },
        abn: {
            error: false,
            message: ''
        },
        acn: {
            error: false,
            message: ''
        },
        directDepositAccountName: {
            error: false,
            message: ''
        },
        directDepositAccountBsb: {
            error: false,
            message: ''
        },
        directDepositAccountNumber: {
            error: false,
            message: ''
        },
        lmd: {
            error: false,
            message: ''
        },
        logoUrl: {
            error: false,
            message: ''
        }
    });

    const [step2Validation, setStep2Validation] = useState([]);
    const [step3Validation, setStep3Validation] = useState([]);
    const [step4Validation, setStep4Validation] = useState({
        firstName: {
            error: false,
            message: ''
        },
        lastName: {
            error: false,
            message: ''
        },
        username: {
            error: false,
            message: ''
        },
        password: {
            error: false,
            message: ''
        },
        email: {
            error: false,
            message: ''
        }
    });

    const steps = [
        'Business Details',
        'Sites',
        'Tills',
        // 'Registration Pricing',
        'Admin User'
    ];

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const HandleUpload = () => {
        setLoading(true);
        SetupOra({
            setLoading: setLoading,
            settings: settings,
            companyLogo: companyLogo,
            sites: sites,
            tills: tills,
            user: user,
            showSnackbar: showSnackbar
        });
    };

    return (
        <div id="onboarding-table">
            <Paper className="paper-padding">
                <Typography variant="h4">Welcome to Ora</Typography>
                <br />
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <br />

                {loading ? (
                    <>
                        <Typography variant="button">
                            <b>
                                Please wait while Ora sets up your data. This
                                should only take a couple minutes.
                            </b>
                        </Typography>
                        <br />
                        <br />
                        <CircularProgress />
                    </>
                ) : (
                    <>
                        {activeStep === 0 ? (
                            <Step1
                                settings={settings}
                                setSettings={setSettings}
                                companyLogo={companyLogo}
                                setCompanyLogo={setCompanyLogo}
                                stepValidation={step1Validation}
                            />
                        ) : null}
                        {activeStep === 1 ? (
                            <Step2
                                sites={sites}
                                setSites={setSites}
                                stepValidation={step2Validation}
                                setStepValidation={setStep2Validation}
                            />
                        ) : null}

                        {activeStep === 2 ? (
                            <Step3
                                sites={sites}
                                tills={tills}
                                setTills={setTills}
                                stepValidation={step3Validation}
                                setStepValidation={setStep3Validation}
                            />
                        ) : null}

                        {activeStep === 3 ? (
                            <Step4
                                user={user}
                                setUser={setUser}
                                stepValidation={step4Validation}
                            />
                        ) : null}

                        {activeStep === 4 ? (
                            <>
                                <Typography variant="button">
                                    <b>
                                        If you wish to change anything please
                                        feel free to do so now. You will be able
                                        to edit this information later.
                                    </b>
                                </Typography>
                                <br />
                                <br />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={HandleUpload}
                                >
                                    Proceed to Ora
                                </Button>
                            </>
                        ) : (
                            <>
                                <br />
                                <Grid container spacing={2}>
                                    <Grid item xs={1} align="left">
                                        <Button
                                            color="primary"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={10}></Grid>
                                    <Grid item xs={1} align="right">
                                        {activeStep === 0 ? (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled={
                                                    (companyLogo.method ===
                                                        'upload' &&
                                                        companyLogo.picturePreview ===
                                                            null) ||
                                                    (companyLogo.method ===
                                                        'url' &&
                                                        !Object.hasOwn(
                                                            settings,
                                                            'logoUrl'
                                                        ))
                                                }
                                                onClick={() => {
                                                    if (activeStep === 0) {
                                                        ValidateStep1(
                                                            settings,
                                                            setActiveStep,
                                                            setStep1Validation,
                                                            showSnackbar
                                                        );
                                                    } else if (
                                                        activeStep === 1
                                                    ) {
                                                        ValidateStep2(
                                                            sites,
                                                            setActiveStep,
                                                            setStep2Validation,
                                                            showSnackbar
                                                        );
                                                    } else if (
                                                        activeStep === 2
                                                    ) {
                                                        ValidateStep3(
                                                            tills,
                                                            sites,
                                                            setActiveStep,
                                                            setStep3Validation,
                                                            showSnackbar
                                                        );
                                                    } else if (
                                                        activeStep === 3
                                                    ) {
                                                        ValidateStep4(
                                                            user,
                                                            setActiveStep,
                                                            setStep4Validation,
                                                            showSnackbar
                                                        );
                                                    }
                                                }}
                                            >
                                                {activeStep === steps.length - 1
                                                    ? 'Finish'
                                                    : 'Next'}
                                            </Button>
                                        ) : (
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => {
                                                    if (activeStep === 0) {
                                                        ValidateStep1(
                                                            settings,
                                                            setActiveStep,
                                                            setStep1Validation,
                                                            showSnackbar
                                                        );
                                                    } else if (
                                                        activeStep === 1
                                                    ) {
                                                        ValidateStep2(
                                                            sites,
                                                            setActiveStep,
                                                            setStep2Validation,
                                                            showSnackbar
                                                        );
                                                    } else if (
                                                        activeStep === 2
                                                    ) {
                                                        ValidateStep3(
                                                            tills,
                                                            sites,
                                                            setActiveStep,
                                                            setStep3Validation,
                                                            showSnackbar
                                                        );
                                                    } else if (
                                                        activeStep === 3
                                                    ) {
                                                        ValidateStep4(
                                                            user,
                                                            setActiveStep,
                                                            setStep4Validation,
                                                            showSnackbar
                                                        );
                                                    }
                                                }}
                                            >
                                                {activeStep === steps.length - 1
                                                    ? 'Finish'
                                                    : 'Next'}
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Paper>
        </div>
    );
};

export default withSnackbar(OnboardingUI);
