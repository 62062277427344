// IMPORTS
// INTERFACES
import { Order, Voucher } from '../../../global/interfaces/PartsInterface';

interface TempOrder extends Order {
  orderLines?: {
    rowId?: number;
    UnitId?: number;
    name?: string;
    partNumber?: string;
    priceRRP?: number;
    priceCharged?: string;
    pricePaid?: string;
    costPriceDaily?: number;
    costPriceAverage?: number;
    quantity?: string;
    backorderQuantity?: string;
    incGst?: boolean;
    totalPrice?: number;
    gstFree?: boolean;
    totalCost?: number;
    BinLocationIds?: { name: string }[];
    SOH?: number;
    RES?: number;
    tradeRRP?: number;
    isTradePrice?: boolean;
    margin?: number;
    stockOrderType?: boolean;
    collecting?: boolean;
    inactive?: boolean;
    linkedSaleId?: number;
    quantityReturned?: number; // used in crediting order
    backorderQuantityReturned?: number; // used in crediting order
    dateRefunded?: string; // used in crediting order
    creditId?: number; // used in crediting order
    amountRefunded?: number; // used in crediting order
    // USED ONLY HERE
    refundableQuantity?: number;
    backorderableQuantity?: number;
    quantityReturning?: number;
    backorderQuantityReturning?: number;
    amountRefunding?: number;
  }[];
}

/**
 * CalculateAmountPaid
 * Summate the cost of all items the customer has currently paid for
 * @param saleDetails all details associated with the sale
 * @param freight the charged freight
 * @param vouchers any vouchers on the sale
 * @returns the total cost of all paid parts and charges
 */
export const CalculateAmountPaid = (
  saleDetails: TempOrder,
  freight: number,
  vouchers: { voucher: Voucher; qtyReturning: number }[]
) => {
  let amountPaid = freight;

  // Check the sale type before calculating amount paid
  if (saleDetails.VehicleId || saleDetails.internalType) {
    return 0;
  } else {
    // Add the cost of all unrefunded parts
    amountPaid += CalculatePartsTotal(saleDetails.orderLines);

    // Add the cost of all unrefunded vouchers
    amountPaid += CalculateVoucherTotal(vouchers);

    return amountPaid;
  }
};

/**
 * CalculateAmountRefunded
 * Set the combined refund amount for each order line
 * @author Estienne
 * @param orderlines the sale orderlines
 * @param freightRefunding the freight amount to be refunded
 * @returns the total amount to be refunded
 */
export const CalculateAmountRefunded = (
  orderlines: any,
  freightRefunding: number,
  vouchers: { voucher: Voucher; qtyReturning: number }[]
) => {
  let amountRefunded = freightRefunding;

  // Summate the amount refunding for each returned object
  orderlines.forEach((line) => {
    if (Number(line?.amountRefunding)) {
      amountRefunded += parseFloat(line.amountRefunding);
    }
  });

  // Summate the amount refunding for each returned voucher
  let refundedVouchers = vouchers.filter(
    (voucherObj) => voucherObj.qtyReturning > 0
  );
  refundedVouchers.forEach((voucherObj) => {
    amountRefunded += voucherObj.voucher.totalPrice * voucherObj.qtyReturning;
  });

  return amountRefunded;
};

/**
 * CalculateVoucherTotal
 * Calculate the combined total of all refundable vouchers on the sale
 * @author Estienne
 * @param vouchers all vouchers associated with the sale
 * @returns the cost of all vouchers on the sale
 */
export const CalculateVoucherTotal = (
  vouchers: { voucher: Voucher; qtyReturning: number }[]
) => {
  let voucherTotal = 0;
  vouchers.forEach((voucherObj) => {
    if (voucherObj.voucher.CreditId === null) {
      voucherTotal += voucherObj.voucher.totalPrice;
    }
  });

  return voucherTotal;
};

/**
 * CalculatePartsTotal
 * Calculate the combined total of all parts currently held by the customer
 * @author Estienne
 * @param orderlines all orderlines on the sale
 * @returns the summed cost for all paid parts
 */
export const CalculatePartsTotal = (orderlines) => {
  let partsAmount = 0;
  let purchasedParts = orderlines.filter(
    (part) => part.refundableQuantity > 0 || part.backorderableQuantity > 0
  );
  purchasedParts.forEach((part) => {
    partsAmount +=
      parseFloat(part.pricePaid) * part.refundableQuantity +
      parseFloat(part.pricePaid) * part.backorderableQuantity;
  });

  return partsAmount;
};
