// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';
// COMPONENTS
import Drawer from '../../../global/Drawer';
import Paper from '../../../global/Paper';
import PaymentTable from '../../../global/paymentComponents/PaymentTable';
import TillSelector from '../../../global/paymentComponents/TillSelector';
import TotalForm from '../../newBooking/components/TotalsForm';
import VoucherDrawerContent from '../../../parts/newSale/components/VoucherDrawerContent';
// LOGIC
import AddPayment from '../logic/AddPayment';
import GetUser from '../../../global/databaseLogic/GetUser';
import GetVoucher from '../../../parts/newSale/logic/GetVoucher';
import PaymentTextField from '../../../global/paymentComponents/PaymentTextField';
import PaymentMethodSelector from '../../../global/paymentComponents/PaymentMethodSelector';
import SubmitPayment from '../logic/SubmitPayment';
import ValidateVoucher from '../../../global/logic/ValidateVoucher';
import { ServiceBalance } from '../../newBooking/logic/GetTotals';
// INTERFACE
import {
    Customer,
    PaymentLine,
    User
} from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Order, Voucher } from '../../../global/interfaces/PartsInterface';
import {
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';

interface PaymentFormProps {
    id: number;
    serviceDetails: Service;
    insurer: Customer;
    customer: Customer;
    showSnackbar: showSnackbar;
    partSales: Order[];
    sublets: PurchaseOrder[];
    paymentLines: PaymentLine[];
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>;
    incompleteService: boolean;
    setIncompleteServiceDialog: Dispatch<SetStateAction<boolean>>;
}

const PaymentForm = ({
    id,
    serviceDetails,
    insurer,
    customer,
    showSnackbar,
    partSales,
    sublets,
    paymentLines,
    setPaymentLines,
    incompleteService,
    setIncompleteServiceDialog
}: PaymentFormProps) => {
    const [currentUser, setCurrentUser] = useState<User>(null);
    const [payingCustomer, setPayingCustomer] = useState<Customer>(customer);
    const [payingCustomerType, setPayingCustomerType] =
        useState<string>('customer');

    const [voucherCode, setVoucherCode] = useState<string>('');
    const [voucher, setVoucher] = useState<Voucher>();
    const [voucherDrawerOpen, setVoucherDrawerOpen] = useState<boolean>(false);

    const [selectedTillId, setSelectedTillId] = useState<number>(0);
    const [paymentMethod, setPaymentMethod] = useState<string>('cash');

    useEffect(() => {
        GetUser(0, setCurrentUser);
        if (insurer?.id) {
            setPayingCustomerType('insurer');
            setPayingCustomer(insurer);
        }
        // eslint-disable-next-line
    }, []);

    const handleSubmitPayment = () => {
        SubmitPayment(
            id,
            paymentLines,
            serviceDetails,
            payingCustomerType,
            partSales,
            sublets,
            showSnackbar
        );
    };

    const handleAddPayment = (paymentAmount: string, paymentType: string) => {
        if (paymentMethod === 'voucher') {
            validateVoucher(paymentAmount);
            setVoucherDrawerOpen(false);
        } else {
            AddPayment({
                selectedTill: selectedTillId,
                showSnackbar: showSnackbar,
                paymentAmount: parseFloat(paymentAmount),
                paymentMethod: paymentMethod,
                totalPrice: parseFloat(serviceDetails.totalPrice),
                paymentRows: paymentLines,
                setPaymentRows: setPaymentLines,
                multipleCustomers: insurer?.id ? true : false,
                payingCustomer: payingCustomer,
                paymentTypeService: paymentType ? paymentType : 'standard',
                currentUser: currentUser
            });
        }
    };

    const validateVoucher = (paymentAmount: string) => {
        ValidateVoucher(
            parseFloat(paymentAmount),
            voucherCode,
            selectedTillId,
            paymentLines,
            paymentMethod,
            setPaymentLines,
            setPaymentMethod,
            setVoucherCode,
            showSnackbar
        );
    };

    return (
        <div>
            <>
                <Paper>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <PaymentTextField
                                showButton={false}
                                showPaymentTypes={true}
                                incompleteService={incompleteService}
                                setIncompleteServiceDialog={
                                    setIncompleteServiceDialog
                                }
                                SubmitPayment={handleAddPayment}
                                initialValue={ServiceBalance(
                                    serviceDetails,
                                    paymentLines
                                )}
                            />
                        </Grid>
                        <Grid item xs={2} textAlign="center">
                            <TillSelector
                                selectedTillId={selectedTillId}
                                setSelectedTillId={setSelectedTillId}
                                department={'service'}
                            />
                        </Grid>
                        <Grid item xs={2} textAlign="center">
                            <PaymentMethodSelector
                                paymentMethod={paymentMethod}
                                setPaymentMethod={setPaymentMethod}
                                isCustomerDebtor={
                                    payingCustomerType === 'insurer'
                                        ? insurer?.debtor
                                        : customer?.debtor
                                }
                            />
                        </Grid>
                        <Grid item xs={2}>
                            {paymentMethod === 'voucher' ? (
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Voucher Code"
                                    value={voucherCode}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) =>
                                        setVoucherCode(e.target.value)
                                    }
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter')
                                            GetVoucher(
                                                voucherCode,
                                                setVoucher,
                                                setVoucherDrawerOpen,
                                                showSnackbar
                                            );
                                    }}
                                />
                            ) : null}
                        </Grid>
                        <Grid item xs={3} textAlign="center">
                            <TextField
                                fullWidth
                                select
                                size="small"
                                label="Paying Customer"
                                value={payingCustomerType}
                                onChange={(e) => {
                                    if (e.target.value === 'insurer') {
                                        setPayingCustomer(insurer);
                                        setPayingCustomerType('insurer');
                                    } else {
                                        setPayingCustomer(customer);
                                        setPayingCustomerType('customer');
                                    }
                                }}
                            >
                                <MenuItem value="customer">
                                    CUSTOMER:{' '}
                                    {customer?.companyName
                                        ? customer?.companyName.toUpperCase()
                                        : customer?.firstName.toUpperCase() +
                                          ' ' +
                                          customer?.lastName.toUpperCase()}
                                </MenuItem>
                                <MenuItem value="insurer">
                                    INSURER:{' '}
                                    {insurer?.companyName
                                        ? insurer?.companyName.toUpperCase()
                                        : insurer?.firstName.toUpperCase() +
                                          ' ' +
                                          insurer?.lastName.toUpperCase()}
                                </MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </Paper>
                <br />
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Paper>
                            <PaymentTable
                                multipleCustomers={insurer?.id ? true : false}
                                paymentLines={paymentLines}
                                setPaymentLines={setPaymentLines}
                                payingCustomer={payingCustomer}
                                currentUser={currentUser}
                                SubmitPayment={handleSubmitPayment}
                                showSubmitButton={true}
                                showSnackbar={showSnackbar}
                                handleAddPayment={handleAddPayment}
                                serviceDetails={serviceDetails}
                                isFinalised={
                                    serviceDetails.serviceComplete ===
                                    'Finalised'
                                }
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={3}>
                        <TotalForm
                            serviceDetails={serviceDetails}
                            sublets={sublets}
                            partSales={partSales}
                            paymentLines={paymentLines}
                            paymentForm={true}
                        />
                    </Grid>
                </Grid>
            </>

            <Drawer
                openDrawer={voucherDrawerOpen}
                setOpenDrawer={setVoucherDrawerOpen}
                title={`Voucher Details`}
                subTitle={`Voucher Code: ${voucherCode.toUpperCase()}`}
                width="20vw"
            >
                <VoucherDrawerContent
                    voucher={voucher}
                    handleAddPayment={handleAddPayment}
                />
            </Drawer>
        </div>
    );
};

export default PaymentForm;
