import { Dispatch, SetStateAction } from 'react';
import api from '../../../../../api';
import { Snack } from '../../../global/interfaces/GlobalInterface';

// this is dealer send contract link to customer
interface EmailSignUrlProps {
    type: string;
    id: number;
    customerSignUrl: string;
    setSnackbar: Dispatch<SetStateAction<Snack>>;
}

export const EmailSignUrl = ({
    type,
    id,
    customerSignUrl,
    setSnackbar
}: EmailSignUrlProps) => {
    api.post('/emailSignUrl', {
        type: type,
        id: id,
        url: customerSignUrl
    }).then((res) => {
        if (res.status === 200) {
            setSnackbar({
                open: true,
                severity: 'success',
                message: 'Email sent successfully',
                subMessage: 'The page will now refresh.'
            });

            let redirectUrl;

            if (type === 'vehicle-contract') {
                redirectUrl = `/vehicles/viewContract/${id}`;
            } else {
                redirectUrl = `/vehicles/view/${id}`;
            }

            setTimeout(() => (window.location.href = redirectUrl), 1000);
        } else {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Whoops! Something went wrong on our end.',
                subMessage: 'Please contact your IT department.'
            });
        }
    });
};

// this is customer send back signed contract pdf in attachment to dealer
interface EmailSignedPdfProps {
    type: string;
    id: number;
    base64data: string;
    newStatus: string;
    setIsSubmitSuccess?: (value: boolean) => void;
    setSnackbar?: (value: any) => void;
}

export const EmailSignedPdf = ({
    type,
    id,
    base64data,
    newStatus,
    setIsSubmitSuccess,
    setSnackbar
}: EmailSignedPdfProps) => {
    api.post('/emailSignedPdf', {
        type: type,
        id: id,
        newStatus: newStatus,
        base64data: base64data
    }).then((res) => {
        if (res.status === 200) {
            setIsSubmitSuccess?.(true);
            setSnackbar?.({
                open: true,
                severity: 'success',
                message: 'Email sent successfully',
                subMessage: 'The page will now refresh.'
            });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            setIsSubmitSuccess?.(false);
            setSnackbar?.({
                open: true,
                severity: 'error',
                message: 'Whoops! Something went wrong on our end.',
                subMessage: 'Please contact your IT department.'
            });
        }
    });
};
