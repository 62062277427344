// This takes the message body and generates the message body preview by
// replacing the codes with sample data
const HandlePreviewReplacement = (message: string) => {
  let currentMessage = message ? message : '';

  currentMessage = currentMessage.replace('[customerName]', 'Frodo');
  currentMessage = currentMessage.replace('[businessStore]', 'Mordor');
  currentMessage = currentMessage.replace(
    '[businessPhoneNumber]',
    '474 224 95 52'
  );
  currentMessage = currentMessage.replace(
    '[businessHours]',
    `Monday - Friday: 8am - 5:30pm\nSaturday: 8am - 4pm\nSunday: Closed`
  );
  currentMessage = currentMessage.replace('[proformaNumber]', '1234');
  currentMessage = currentMessage.replace('[serviceNumber]', '1234');
  currentMessage = currentMessage.replace('[vehicleSaleNumber]', '1234');
  currentMessage = currentMessage.replace('[onlineOrderNumber]', '1234');
  currentMessage = currentMessage.replace('[bikeMakeModel]', 'Honda CBR250');

  return currentMessage;
};

export default HandlePreviewReplacement;
