// IMPORTS
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import {
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Tooltip
} from '@mui/material';
import { Delete } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../global/Paper';
import Autocomplete from '../../../global/Autocomplete';
import RoleCheck from '../../../global/RoleCheck';
// LOGIC
import {
    DateFormatter,
    CurrencyFormatter
} from '../../../global/logic/Formatters';
import GetAllSuppliers from '../../../global/databaseLogic/GetAllSuppliers';
// INTERFACES
import { Invoice } from '../../../global/interfaces/PartsInterface';
import { Creditor } from '../../../global/interfaces/GeneralInterface';
interface InvoiceDetailsProps {
    invoiceDetails: Invoice;
    setInvoiceDetails: Dispatch<SetStateAction<Invoice>>;
    invoiceType: { type: string; id: number; selector: string };
    readOnly: boolean;
    setDeleteInvoiceDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const InvoiceDetails = ({
    invoiceDetails,
    setInvoiceDetails,
    invoiceType,
    readOnly,
    setDeleteInvoiceDialogOpen
}: InvoiceDetailsProps) => {
    const [creditors, setCreditors] = useState<Creditor[]>([]);

    useEffect(() => {
        GetAllSuppliers(setCreditors);
    }, []);

    const updateInvoiceDetails = (newValue, selector) => {
        let currentObject = invoiceDetails[invoiceType.selector];
        currentObject[selector] = newValue;
        setInvoiceDetails({
            ...invoiceDetails,
            [invoiceType.selector]: currentObject
        });
    };

    const isIncludingGst = () => {
        if (
            invoiceDetails[invoiceType.selector]?.gstMethod === 'including' ||
            invoiceDetails[invoiceType.selector]?.gstType !== 'No GST'
        ) {
            return 'including';
        } else {
            return 'excluding';
        }
    };

    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        disabled
                        fullWidth
                        size="small"
                        label="Document Reference"
                        value={
                            invoiceDetails[invoiceType.selector]
                                ?.documentReference
                        }
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled
                        fullWidth
                        size="small"
                        label="Invoice Date"
                        value={DateFormatter(
                            invoiceDetails[invoiceType.selector]?.invoiceDate
                        )}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled
                        fullWidth
                        size="small"
                        label="Payment Due Date"
                        value={
                            invoiceDetails?.paymentDueDate
                                ? DateFormatter(invoiceDetails?.paymentDueDate)
                                : ''
                        }
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <RoleCheck
                        permission="update_AdminInvoiceGST"
                        component={
                            <TextField
                                disabled={readOnly}
                                fullWidth
                                select
                                size="small"
                                label="GST Type"
                                value={
                                    invoiceDetails[invoiceType.selector]
                                        ?.gstType
                                }
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    setInvoiceDetails({
                                        ...invoiceDetails,
                                        StockInvoice: {
                                            ...invoiceDetails.StockInvoice,
                                            gstType: e.target.value
                                        }
                                    });
                                }}
                            >
                                <MenuItem value={'Capital Purchase'}>
                                    Capital Purchase
                                </MenuItem>
                                <MenuItem value={'Non-Capital Purchase'}>
                                    Non-Capital Purchase
                                </MenuItem>
                            </TextField>
                        }
                        nullComponent={
                            <TextField
                                disabled
                                fullWidth
                                size="small"
                                label="GST Type"
                                value={
                                    invoiceDetails[invoiceType.selector]
                                        ?.gstType
                                }
                                InputLabelProps={{ shrink: true }}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={3} textAlign="center">
                    <FormControl>
                        <RadioGroup
                            row
                            value={isIncludingGst()}
                            onChange={(e) =>
                                updateInvoiceDetails(
                                    e.target.value,
                                    'gstMethod'
                                )
                            }
                        >
                            <FormControlLabel
                                value="including"
                                control={
                                    <Radio
                                        disabled={
                                            readOnly ||
                                            invoiceDetails?.StockInvoice
                                                ?.invoiceType === 'Admin' ||
                                            !!invoiceDetails?.VehicleRegistrationId
                                        }
                                    />
                                }
                                label="INC. GST"
                            />
                            <FormControlLabel
                                value="excluding"
                                control={
                                    <Radio
                                        disabled={
                                            readOnly ||
                                            invoiceDetails?.StockInvoice
                                                ?.invoiceType === 'Admin' ||
                                            !!invoiceDetails?.VehicleRegistrationId
                                        }
                                    />
                                }
                                label="EX. GST"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <Autocomplete
                        size="small"
                        options={creditors}
                        useTwoOptionLabels={false}
                        primaryOptionLabel="name"
                        textfieldLabel="Creditor"
                        isDisabled={
                            readOnly ||
                            invoiceDetails?.StockInvoice?.invoiceType ===
                                'Admin' ||
                            !!invoiceDetails?.VehicleRegistrationId
                        }
                        selectedValue={invoiceDetails?.Supplier}
                        handleSelectedValueChange={(newValue) =>
                            setInvoiceDetails({
                                ...invoiceDetails,
                                Supplier: newValue,
                                SupplierId: newValue.id
                            })
                        }
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={
                            readOnly ||
                            invoiceDetails?.StockInvoice?.invoiceType ===
                                'Admin' ||
                            !!invoiceDetails?.VehicleRegistrationId
                        }
                        fullWidth
                        size="small"
                        label="Document Total"
                        value={
                            readOnly
                                ? CurrencyFormatter(
                                      invoiceDetails[invoiceType.selector]
                                          ?.documentTotal
                                  )
                                : invoiceDetails[invoiceType.selector]
                                      ?.documentTotal
                        }
                        onChange={(e) =>
                            updateInvoiceDetails(
                                e.target.value,
                                'documentTotal'
                            )
                        }
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={
                            readOnly ||
                            invoiceDetails?.StockInvoice?.invoiceType ===
                                'Admin' ||
                            invoiceDetails?.StockInvoice?.invoiceType ===
                                'Stock' ||
                            !!invoiceDetails?.VehicleRegistrationId
                        }
                        fullWidth
                        size="small"
                        label="Freight"
                        value={
                            readOnly
                                ? CurrencyFormatter(
                                      invoiceDetails[invoiceType.selector]
                                          ?.freight
                                  )
                                : invoiceDetails[invoiceType.selector]?.freight
                        }
                        onChange={(e) =>
                            updateInvoiceDetails(e.target.value, 'freight')
                        }
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled
                        fullWidth
                        size="small"
                        label="GST Amount"
                        value={
                            invoiceDetails[invoiceType.selector]?.gstType ===
                            'No GST'
                                ? CurrencyFormatter(
                                      parseFloat(
                                          invoiceDetails[invoiceType.selector]
                                              ?.documentTotal
                                      ) * 1.1
                                  )
                                : invoiceDetails[invoiceType.selector]
                                        ?.gstMethod === 'including' ||
                                    invoiceDetails[invoiceType.selector]
                                        ?.gstType !== 'No GST'
                                  ? CurrencyFormatter(
                                        parseFloat(
                                            invoiceDetails[invoiceType.selector]
                                                ?.documentTotal
                                        ) / 11
                                    )
                                  : CurrencyFormatter(
                                        parseFloat(
                                            invoiceDetails[invoiceType.selector]
                                                ?.documentTotal
                                        ) * 1.1
                                    )
                        }
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={3} textAlign="right">
                    {readOnly ? null : (
                        <RoleCheck
                            permission="delete_invoice"
                            component={
                                <Tooltip
                                    placement="left"
                                    title={
                                        invoiceDetails?.paymentHistory?.length >
                                        0
                                            ? 'There have been payments made to this invoice and it cannot be deleted.'
                                            : ''
                                    }
                                >
                                    <span>
                                        <IconButton
                                            disabled={
                                                invoiceDetails?.paymentHistory
                                                    ?.length > 0
                                            }
                                            onClick={() =>
                                                setDeleteInvoiceDialogOpen(true)
                                            }
                                        >
                                            <Delete />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            }
                        />
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default InvoiceDetails;
