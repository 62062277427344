// LOGIC
import { ToFixed } from '../../../global/logic/Formatters';
// FORMATTERS
import { PaymentLine } from '../../../global/interfaces/GeneralInterface';
import { Order } from '../../../global/interfaces/PartsInterface';
import {
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';

export function PartTotal(serviceDetails: Service, partSales: Order[]) {
    let partsTotal = 0;
    let partsCost = 0;

    serviceDetails?.addedCosts?.forEach((line) => {
        if (line.isFuel) {
            let costPrice = parseFloat(line.costPerLitre);
            partsTotal +=
                (parseFloat(line.quantity) * parseFloat(line.pricePaid)) / 1.1;
            partsCost += (parseFloat(line.quantity) * costPrice) / 1.1;
        } else if (line.partType === 'fluid') {
            partsTotal +=
                parseFloat(line.quantity) * parseFloat(line.pricePaid);
            partsCost +=
                parseFloat(line.quantity) * parseFloat(line.costPerLitre);
        } else {
            let costPrice = line.costPriceAverage
                ? parseFloat(line.costPriceAverage)
                : parseFloat(line.costPriceDaily);
            partsTotal +=
                parseFloat(line.quantity) * parseFloat(line.pricePaid);
            partsCost += parseFloat(line.quantity) * costPrice;
        }
    });

    partSales?.forEach((sale) => {
        sale.orderLines.forEach((line) => {
            let costPrice = line.costPriceAverage
                ? line.costPriceAverage
                : line.costPriceDaily;
            partsTotal +=
                (parseFloat(line.quantity) +
                    parseFloat(line.backorderQuantity)) *
                parseFloat(line.priceCharged);
            partsCost +=
                (parseFloat(line.quantity) +
                    parseFloat(line.backorderQuantity)) *
                costPrice;
        });
    });
    return { partsTotal: ToFixed(partsTotal), partsCost: ToFixed(partsCost) };
}

export function SubletTotal(sublets: PurchaseOrder[]) {
    let subletTotal = 0;

    sublets?.forEach((sublet) => {
        subletTotal += sublet.includingGST
            ? sublet.amount
            : sublet.amount * 1.1;
    });

    return ToFixed(subletTotal);
}

export function MiscTotal(serviceDetails: Service) {
    let miscTotal = 0;

    serviceDetails?.miscCosts?.forEach((misc) => {
        miscTotal += parseFloat(misc.amount);
    });

    miscTotal += parseFloat(serviceDetails.environmentalLevy);
    miscTotal += parseFloat(serviceDetails.sundries);

    return ToFixed(miscTotal);
}

export function PaymentTotal(paymentLines: PaymentLine[]) {
    let paymentTotal = 0;

    paymentLines?.forEach((line) => {
        if (!line.removed) {
            paymentTotal += line.amount;
        }
    });

    return ToFixed(paymentTotal);
}

export function ServiceTotal(
    serviceDetails: Service,
    sublets: PurchaseOrder[],
    partSales: Order[]
) {
    // this is misc total exclude GST
    let miscTotal = MiscTotal(serviceDetails);
    // this is parts total exclude GST
    let partsTotal = PartTotal(serviceDetails, partSales).partsTotal;
    // this is labour total exclude GST
    let labourCharge = parseFloat(serviceDetails.labourCost);

    // this is sublet total INCLUDE GST
    let subletCharge = parseFloat(serviceDetails.subletTotal);

    // subletTotal in db is price include GST, others are price exclude GST
    let serviceTotal =
        subletCharge + (labourCharge + miscTotal + partsTotal) * 1.1;
    // serviceTotal = serviceTotal * 1.1;

    return ToFixed(serviceTotal);
}

export function ServiceBalance(
    serviceDetails: Service,
    paymentLines?: PaymentLine[]
) {
    let paymentTotal = paymentLines
        ? PaymentTotal(paymentLines)
        : PaymentTotal(serviceDetails?.paymentLines);

    let balance = parseFloat(serviceDetails?.totalPrice) - paymentTotal;

    return ToFixed(balance);
}
