// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../api';
import { Creditor, Site } from '../interfaces/GeneralInterface';
import { showSnackbar } from '../interfaces/GlobalInterface';

interface SelectedPart {
    unitData: {};
    stockData: {};
}

const CheckPartSearchPart = (
    searchString: string,
    setSelectedPart: Dispatch<SetStateAction<SelectedPart>>,
    setSelectSupplierDialogOpen: Dispatch<SetStateAction<boolean>>,
    setSupplierOptions: Dispatch<SetStateAction<[]>>,
    setNewPartDialogOpen: Dispatch<SetStateAction<boolean>>,
    setSupercessionOptions: Dispatch<SetStateAction<[]>>,
    setSupercessionDialogOpen: Dispatch<SetStateAction<boolean>>,
    SupplierId?: number,
    SelectedSite?: number,
    checkBackorders?: boolean,
    setSelectedSupplier?: Dispatch<SetStateAction<Creditor>>,
    showSnackbar?: showSnackbar,
    SiteFrom?: Site,
    SiteTo?: Site
) => {
    searchString = searchString.replace('&', '').split(' | ')[0];

    const currentSiteId = localStorage.getItem('SiteId');

    // for stock transfer: this will check from all sites
    // display create new dialog when: part do not exist
    // auto add when: only one part and one supplier found
    // display selected dialog when: multiple parts or multiple supplier found
    if (SiteFrom?.id && SiteTo?.id) {
        api.get(
            `checkPartExists?searchString=${searchString}&SiteId=${SiteFrom?.id}&checkBackorders=${checkBackorders}`
        )
            .then((res) => {
                if (res.status === 200) {
                    // Multiple parts found
                    if (res.data.length > 1) {
                        setSelectSupplierDialogOpen(true);
                        setSupplierOptions(res.data);
                    }
                    // Only one part found, need to check suppliers
                    else if (res.data.length === 1) {
                        const part = res.data[0];
                        const suppliers = part.unitData?.SupplierIds;
                        if (suppliers?.length === 1) {
                            // Only one supplier found, auto add the part and supplier
                            setSelectedPart(part);
                            setSelectedSupplier(suppliers[0]);
                        } else {
                            // Multiple suppliers found for the part, display select dialog
                            setSelectSupplierDialogOpen(true);
                            setSupplierOptions(res.data);
                        }
                    } else {
                        setNewPartDialogOpen(true);
                    }
                }
            })
            .catch((error) => {
                console.error('Error in stock transfer:', error);
            });

        return;
    }

    //for enter stock invoice & New Stock Order
    // display create new dialog when: part do not exist
    // display error message when: part exist, but no part match selected supplier and selected site
    // auto add when: only one part match selected supllier and selected site
    //display select dialog when: multiple parts match with selected supllier and selected site
    if (SupplierId || SelectedSite) {
        api.get(
            `checkPartExists?searchString=${searchString}&SiteId=${SelectedSite}&checkBackorders=${checkBackorders}`
        )
            .then((res) => {
                if (res.status === 200) {
                    const isPartsFound = res.data.length > 0;

                    if (!isPartsFound) {
                        setNewPartDialogOpen(true);
                        return;
                    }

                    let matchedPart = [];
                    let matchedSupplier = null;

                    for (let item of res.data) {
                        for (let supplierIdObject of item.unitData
                            .SupplierIds) {
                            if (
                                parseInt(supplierIdObject.SupplierId) ===
                                    SupplierId &&
                                parseInt(supplierIdObject.SiteId) ===
                                    SelectedSite
                            ) {
                                matchedPart.push(item);
                                matchedSupplier = supplierIdObject;
                                break;
                            }
                        }
                    }
                    if (matchedPart.length > 1) {
                        setSelectSupplierDialogOpen(true);
                        setSupplierOptions(matchedPart as []);
                    } else if (matchedPart.length > 0) {
                        setSelectedPart(matchedPart[0]);
                        setSelectedSupplier(matchedSupplier);
                    } else {
                        // If we reach this:
                        // We are in a stock invoice, and we didn't find a supplier for the site we are logged in
                        // Go through the supplier to find if there is a matched in other site.
                        // If that's the case, use it and display a warning to tell the user to update the suppliers
                        for (let item of res.data) {
                            for (let supplierIdObject of item.unitData
                                .SupplierIds) {
                                if (
                                    parseInt(supplierIdObject.SupplierId) ===
                                    SupplierId
                                ) {
                                    matchedPart.push(item);
                                    matchedSupplier = supplierIdObject;
                                    break;
                                }
                            }
                        }
                        if (matchedPart.length > 0) {
                            setSelectedPart(matchedPart[0]);
                            setSelectedSupplier(matchedSupplier);
                            showSnackbar(
                                'The selected supplier does not supply this part for current site, but supply it for other sites.',
                                "If you will order this part from this supplier often, you should add this supplier to the part's details for this site.",
                                'warning'
                            );
                        } else {
                            showSnackbar(
                                'No matching part',
                                'The selected supplier does not supply this part for current site.',
                                'error'
                            );
                        }
                    }
                }
            })
            .catch((error) => {
                console.error('Error in stock invoice:', error);
            });

        return;
    }

    //for add part to sale
    //display create new dialog when: part do not exist
    //auto add when: only one supplier supply to currentSiteId
    //display select dialpg when: more than one supplier supply to currentSiteId; or part exist but no supplier supply currentSiteId
    api.get(
        `checkPartExists?searchString=${searchString}&SiteId=${currentSiteId}&checkBackorders=${checkBackorders}`
    )
        .then((res) => {
            if (res.status === 200) {
                const isPartsFound = res.data.length > 0;
                let isSinglePartAndSupplierFound = false;
                let matchingSupplier = null;
                let partWithMatchingSupplier = null;

                if (!isPartsFound) {
                    setNewPartDialogOpen(true);
                    return;
                }

                for (const item of res.data) {
                    if (item.unitData?.supercessionData.length > 0) {
                        setSupercessionDialogOpen(true);
                        setSupercessionOptions(res.data);
                        return;
                    }

                    if (item.unitData && item.unitData.SupplierIds) {
                        //find all suppliers that supply to the currentSiteId
                        const matchingSuppliers =
                            item.unitData.SupplierIds.filter(
                                (supplier) =>
                                    String(supplier.SiteId) === currentSiteId
                            );
                        //when only one supplier supply to currentSiteId
                        if (matchingSuppliers.length === 1) {
                            if (!isSinglePartAndSupplierFound) {
                                isSinglePartAndSupplierFound = true;
                                matchingSupplier = matchingSuppliers[0];
                                item.supplierData = matchingSupplier;
                                partWithMatchingSupplier = item;
                            } else {
                                isSinglePartAndSupplierFound = false;
                                matchingSupplier = null;
                                break;
                            }
                        }
                    }
                }

                if (isSinglePartAndSupplierFound && matchingSupplier) {
                    setSelectedPart(partWithMatchingSupplier);
                    setSelectedSupplier(matchingSupplier);
                } else {
                    setSelectSupplierDialogOpen(true);
                    setSupplierOptions(res.data);
                }
            }
        })
        .catch((error) => {
            console.error('Error in add part to sale:', error);
        });

    return;
};

export default CheckPartSearchPart;
