import { Service } from '../../../../../global/interfaces/ServiceInterface';
import { Order } from '../../../../../global/interfaces/PartsInterface';
import { ToFixed } from '../../../../../global/logic/Formatters';
import { CalculateVehicleSalesParams } from '../interface/PNLInterfaces';

const getExtraCostsAllowance = (extraCosts: any[]) => {
    var total = 0;
    extraCosts?.forEach((row) => {
        total += parseFloat(row.rowTotal);
    });
    return total;
};

// Get the price of parts sold to the VEHICLE (not the contract) and add it to the cost of this sale
const getVehicleOrderCost = (orderData: Order[]) => {
    let total = 0;
    for (let order of orderData) {
        for (let line of order.orderLines) {
            total += line.totalPrice;
        }
    }
    return total;
};

const getServiceAllowance = (services: Service[]) => {
    var total = 0;
    services.forEach((service) => {
        total += parseFloat(service.totalPrice);
    });
    return total;
};

const getGovernmentCharges = (saleDetails) => {
    let governmentCharges = 0;
    if (saleDetails.includesOnRoadCosts) {
        if (saleDetails.registrationPrice) {
            governmentCharges += parseFloat(saleDetails.registrationPrice);
        }
        if (saleDetails.ctpPrice) {
            governmentCharges += parseFloat(saleDetails.ctpPrice);
        }
        if (saleDetails.transferPrice) {
            governmentCharges += parseFloat(saleDetails.transferPrice);
        }
        if (saleDetails.stampDutyPrice && !saleDetails.excludeStampDuty) {
            governmentCharges += parseFloat(saleDetails.stampDutyPrice);
        }
    }

    return ToFixed(governmentCharges);
};

const getTotalInvoices = (invoices, vehicleId) => {
    let totalInvoice = 0;
    for (let invoice of invoices) {
        if (
            invoice.StockInvoice?.orderLines &&
            invoice.StockInvoice?.orderLines.length > 0
        ) {
            for (let line of invoice.StockInvoice?.orderLines) {
                if (line?.Vehicle?.id === vehicleId) {
                    totalInvoice += parseFloat(line.amount);
                }
            }
        } else {
            totalInvoice += parseFloat(invoice.paymentAmount);
        }
    }
    return totalInvoice;
};

const CalculateVehicleSales: CalculateVehicleSalesParams = (
    vehicleSales,
    registration,
    siteData,
    setVehicleData,
    setVehicleValues,
    setWrongVehicleData
) => {
    let vehicleData = [];
    let vehicleValues = {
        adminLoadCost: 0,
        adminLoadCounter: 0,
        adminLoadFromUsed: 0,
        adminLoadFromNew: 0,
        dofCounter: 0,
        dofPrice: 0,
        newCost: 0,
        newRevenue: 0,
        newTotal: 0,
        usedCost: 0,
        usedRevenue: 0,
        usedTotal: 0,
        warrantyCost: 0,
        warrantyCounter: 0,
        warrantyCostFromUsed: 0,
        warrantyCostFromNew: 0,
        warrantyPrice: 0,
        warrantyRevenueFromUsed: 0,
        DOFFromUsed: 0,
        warrantyRevenueFromNew: 0,
        DOFFromNew: 0
    };
    let wrongVehicleData = [];

    for (let sale of vehicleSales) {
        if (!sale.vehicleSalePrice || !sale['Vehicle.costPrice']) {
            let message =
                !sale.vehicleSalePrice && !sale['Vehicle.costPrice']
                    ? 'No cost or sale price entered for vehicle.'
                    : !sale.vehicleSalePrice
                      ? 'No sale price entered for vehicle.'
                      : 'No cost price entered for vehicle';
            let saleLine = {
                VehicleSaleId: sale.id,
                customerName:
                    sale['Customer.firstName'].charAt(0) +
                    '. ' +
                    sale['Customer.lastName'],
                stockNumber: sale['Vehicle.stockNumber'],
                vehicleCondition: sale['Vehicle.condition'],
                message: message
            };
            wrongVehicleData.push(saleLine);
        }

        let warrantyCost = null;
        if (sale.warrantyLength) {
            for (let site of siteData) {
                if (site.id === sale.SiteId) {
                    for (let warranty of site.Setting.warrantyData) {
                        if (warranty.length === sale.warrantyLength) {
                            warrantyCost = warranty.costPrice;
                        }
                    }
                }
            }
        }

        // When parts are being sold to a contract, the contract total price rise to accomodate for the add-ons
        // The GST is paid by the customer, and is accounted for as a revenue here
        // The rest is an allowance that the customer "used" to pay for the P&A sale
        // So we add the GST of all the parts to the revenue of the vehicle sale, and the rest is a cost to the vehicle sale
        let csmAllowance = 0;
        if (sale.orderData?.length > 0) {
            for (let currentSale of sale.orderData) {
                for (let line of currentSale.orderLines) {
                    csmAllowance += line.totalPrice;
                }
            }
        }
        csmAllowance = csmAllowance / 1.1;

        let totalInvoice = 0;
        if (sale.invoiceData.length > 0) {
            totalInvoice = getTotalInvoices(sale.invoiceData, sale.VehicleId);
        }

        let warrantyPrice = sale.warrantyPrice ? sale.warrantyPrice / 1.1 : 0;
        let insurancePrice = sale.insurancePrice
            ? sale.insurancePrice / 1.1
            : 0;

        let vehicleCostPrice =
            sale['Vehicle.costPrice'] +
            (sale['Vehicle.adminLoadCost']
                ? sale['Vehicle.adminLoadCost']
                : 0) +
            getExtraCostsAllowance(sale['Vehicle.extraCosts']) +
            getServiceAllowance(sale?.vehicleServices) +
            getVehicleOrderCost(sale.vehicleOrderData) +
            (warrantyPrice ? warrantyPrice : 0) +
            (insurancePrice ? insurancePrice : 0);

        let vehicleSalePrice = 0;
        let rebatePrice = 0;

        if (sale.excludeGST) {
            vehicleSalePrice = sale.totalPurchasePrice;
        } else {
            let governmentCharges = getGovernmentCharges(sale);
            vehicleSalePrice =
                (sale.totalPurchasePrice - governmentCharges) / 1.1;
        }

        if (sale.vehicleRebates) {
            sale.vehicleRebates.forEach((rebate) => {
                rebatePrice += parseFloat(rebate.amount);
            });
        }

        let saleLine = {
            VehicleSaleId: sale.id,
            stockNumber: sale['Vehicle.stockNumber'],
            customerName:
                sale['Customer.firstName']?.charAt(0) +
                '. ' +
                sale['Customer.lastName'],

            vehicleCondition: sale['Vehicle.condition'],
            vehicleCostPrice: sale['Vehicle.costPrice']
                ? sale['Vehicle.costPrice']
                : 0,
            vehicleSalePrice: sale.vehicleSalePrice ?? 0,

            adminLoadCost: sale['Vehicle.adminLoadCost'],
            extraCost: getExtraCostsAllowance(sale['Vehicle.extraCosts']),
            serviceCosts: getServiceAllowance(sale?.vehicleServices),
            deliveryPrice: sale.deliveryPrice,
            rebatePrice: rebatePrice > 0 ? rebatePrice / 1.1 : null,
            warrantyCost: warrantyPrice,
            csmAllowance: csmAllowance,
            totalInvoice: totalInvoice,

            totalCost:
                vehicleCostPrice +
                csmAllowance +
                totalInvoice -
                (sale.rebatePrice ? sale.rebatePrice / 1.1 : 0),
            totalSale: vehicleSalePrice // This is currently the total price put in by the user minus the government charges, and then GST is removed
        };

        if (sale['Vehicle.condition'] === 'Used') {
            vehicleValues.usedTotal += 1;
            vehicleValues.adminLoadFromUsed +=
                sale['Vehicle.adminLoadCost'] ?? 0;
            vehicleValues.warrantyCostFromUsed += warrantyCost
                ? parseFloat(warrantyCost)
                : 0;
            vehicleValues.warrantyRevenueFromUsed += warrantyPrice;
            vehicleValues.DOFFromUsed += insurancePrice;

            if (!Number.isNaN(vehicleCostPrice - rebatePrice)) {
                vehicleValues.usedCost +=
                    vehicleCostPrice +
                    csmAllowance +
                    totalInvoice -
                    (sale.rebatePrice ? sale.rebatePrice / 1.1 : 0);
            }
            if (!Number.isNaN(vehicleSalePrice)) {
                vehicleValues.usedRevenue += vehicleSalePrice;
            }
        } else {
            vehicleValues.newTotal += 1;
            vehicleValues.adminLoadFromNew +=
                sale['Vehicle.adminLoadCost'] ?? 0;
            vehicleValues.warrantyCostFromNew += warrantyCost
                ? parseFloat(warrantyCost)
                : 0;
            vehicleValues.warrantyRevenueFromNew += warrantyPrice;
            vehicleValues.DOFFromNew += insurancePrice;

            if (!Number.isNaN(vehicleCostPrice - rebatePrice)) {
                vehicleValues.newCost +=
                    vehicleCostPrice +
                    csmAllowance +
                    totalInvoice -
                    (sale.rebatePrice ? sale.rebatePrice / 1.1 : 0);
            }
            if (!Number.isNaN(vehicleSalePrice)) {
                vehicleValues.newRevenue += vehicleSalePrice;
            }
        }

        // WARRANTY
        vehicleValues.warrantyCost += warrantyCost
            ? parseFloat(warrantyCost)
            : 0;
        vehicleValues.warrantyCounter += warrantyCost ? 1 : 0;
        vehicleValues.warrantyPrice += warrantyPrice;

        // DOF
        vehicleValues.dofPrice += insurancePrice;
        vehicleValues.dofCounter += insurancePrice > 0 ? 1 : 0;

        // ADMIN LOAD
        vehicleValues.adminLoadCost += sale['Vehicle.adminLoadCost'] ?? 0;
        vehicleValues.adminLoadCounter += sale['Vehicle.adminLoadCost'] ? 1 : 0;

        vehicleData.push(saleLine);
    }

    if (setVehicleData) setVehicleData(vehicleData);
    if (setVehicleValues) setVehicleValues(vehicleValues);
    if (setWrongVehicleData) setWrongVehicleData(wrongVehicleData);

    return vehicleValues;
};

export default CalculateVehicleSales;
