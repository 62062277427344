// IMPORTS
import { Dispatch, SetStateAction } from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// INTERFACES
import { Snack } from './interfaces/GlobalInterface';

interface SnackbarProps {
  snackbar: Snack;
  setSnackbar: Dispatch<SetStateAction<Snack>>;
  cypressLabel?: string
}

const Snackbar = ({ snackbar, setSnackbar, cypressLabel }: SnackbarProps) => {
  return (
    <MuiSnackbar
      open={snackbar.open}
      data-cy={cypressLabel ? cypressLabel : ''}
      autoHideDuration={6000}
      onClose={() => setSnackbar({ ...snackbar, open: false })}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Put the snackbar at the top middle of the page
      sx={{ whiteSpace: 'pre-line' }} // This is what allows the multiline in the snackbar as MUI does not default allow it
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        severity={
          snackbar.severity === 'success'
            ? 'success'
            : snackbar.severity === 'warning'
              ? 'warning'
              : snackbar.severity === 'error'
                ? 'error'
                : 'info'
        }
        sx={{ width: '100%', zIndex: 1400 }}
      >
        <b>{snackbar.message}</b>
        {snackbar.subMessage ? `\n${snackbar.subMessage}` : null}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
