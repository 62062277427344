import api from '../../../../../api';

const HandleVerifyCustomer = async (type, documentId, driverLicenseNumber) => {
    driverLicenseNumber = driverLicenseNumber.replace(/\s/g, '');

    let endpoint =
        type === 'vehicle-contract' ? '/verifyCustomer' : '/verifyVendor';

    const res = await api.post(`${endpoint}`, {
        documentId,
        driverLicenseNumber
    });
    if (res.status === 200) {
        const { verified } = res.data;
        return { verified };
    } else if (res.status === 400) {
        const { error } = res.data;
        return { error };
    }
};

export default HandleVerifyCustomer;
