// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TableRow, TextField, Typography } from '@mui/material';
// COMPONENTS
import DataCell from '../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataTable from '../../../global/tableComponents/DataTable';
import Paper from '../../../global/Paper';
// INTERFACES
import { Order, Voucher } from '../../../global/interfaces/PartsInterface';
// LOGIC
import { CurrencyFormatter } from '../../../global/logic/Formatters';

interface TempOrder extends Order {
    orderLines?: {
        rowId?: number;
        UnitId?: number;
        name?: string;
        partNumber?: string;
        priceRRP?: number;
        priceCharged?: string;
        pricePaid?: string;
        costPriceDaily?: number;
        costPriceAverage?: number;
        quantity?: string;
        backorderQuantity?: string;
        incGst?: boolean;
        totalPrice?: number;
        gstFree?: boolean;
        totalCost?: number;
        BinLocationIds?: { name: string }[];
        SOH?: number;
        RES?: number;
        tradeRRP?: number;
        isTradePrice?: boolean;
        margin?: number;
        stockOrderType?: boolean;
        collecting?: boolean;
        inactive?: boolean;
        linkedSaleId?: number;
        quantityReturned?: number; // used in crediting order
        backorderQuantityReturned?: number; // used in crediting order
        dateRefunded?: string; // used in crediting order
        creditId?: number; // used in crediting order
        amountRefunded?: number; // used in crediting order
        // USED ONLY HERE
        refundableQuantity?: number;
        backorderableQuantity?: number;
        quantityReturning?: number;
        backorderQuantityReturning?: number;
        amountRefunding?: number;
    }[];
}

interface CreditProps {
    saleDetails: TempOrder;
    setSaleDetails: Dispatch<SetStateAction<TempOrder>>;
    freightRefunding: number;
    vouchers: { voucher: Voucher; qtyReturning: number }[];
    setVouchers: Dispatch<
        SetStateAction<{ voucher: Voucher; qtyReturning: number }[]>
    >;
    setFreightRefunding: Dispatch<SetStateAction<number>>;
    setCreditDrawerId: Dispatch<SetStateAction<number | string>>;
    setOpenCreditDrawer: Dispatch<SetStateAction<boolean>>;
    amountRefunding: number;
    setAmountRefunding: Dispatch<SetStateAction<number>>;
}

/**
 * CreditOrderlines
 * Show all refundable and refunded parts for an sale
 * @author Estienne
 * @param CreditProps
 * @returns {JSX} the sale's parts and other refundable costs
 */
const CreditOrderlines = ({
    saleDetails,
    setSaleDetails,
    freightRefunding,
    vouchers,
    setVouchers,
    setFreightRefunding,
    setCreditDrawerId,
    setOpenCreditDrawer,
    amountRefunding,
    setAmountRefunding
}: CreditProps) => {
    const refundableOrderLines =
        // for split Proforma, exclude the sale split from original proforma
        saleDetails.status === 'Proforma'
            ? saleDetails?.orderLines?.filter(
                  (line) =>
                      line.UnitId &&
                      (line.inactive !== true || line.inactive === undefined) &&
                      (line.collecting !== true ||
                          line.collecting === undefined)
              )
            : // for split Sale, include all collected parts
              saleDetails.status === 'Sale' && saleDetails.LinkedSaleId != null
              ? saleDetails?.orderLines?.filter(
                    (line) =>
                        line.UnitId &&
                        (line.inactive === true ||
                            line.inactive === undefined) &&
                        (line.collecting === true ||
                            line.collecting === undefined)
                )
              : // Otherwise return every line that AREN'T inactive
                saleDetails?.orderLines?.filter(
                    (line) =>
                        line.UnitId &&
                        (line.inactive !== true ||
                            line.inactive === undefined) &&
                        (line.collecting !== true ||
                            line.collecting === undefined)
                );

    const [columns, setColumns] = useState([
        { id: 0, label: 'Part Number' },
        { id: 1, label: 'Name' },
        { id: 2, label: 'Price Paid' },
        { id: 3, label: 'Qty' },
        { id: 5, label: 'Qty Returning' },
        { id: 7, label: 'Refund Amount' }
    ]);

    useEffect(() => {
        if (saleDetails.status === 'Proforma') {
            setColumns([
                { id: 0, label: 'Part Number' },
                { id: 1, label: 'Name' },
                { id: 2, label: 'Price Paid' },
                { id: 3, label: 'Qty' },
                { id: 4, label: 'Backorder Qty' },
                { id: 5, label: 'Qty Returning' },
                { id: 6, label: 'Backorder Qty Returning' },
                { id: 7, label: 'Refund Amount' }
            ]);
        }
    }, [saleDetails.status]);

    /**
     * freightRefundable
     * Make sure freight is present and refundable
     * @author Estienne
     * @param sale the currently-viewed sale
     * @returns true if freight is refundable
     */
    const freightRefundable = (sale: TempOrder) => {
        if (sale.freight != null) {
            if (sale.freightRefunded != null) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    /**
     * handleUpdateVouchers
     * Update the quantity of a voucher being returned
     * @author Estienne
     * @param newValue the voucher quantity being returned
     * @param id the id of the voucher being updated
     */
    const handleUpdateVouchers = (newValue: number, id: number) => {
        let tempVouchers = [...vouchers];
        let index = tempVouchers.findIndex((x) => x.voucher.id === id);
        let voucher = { ...tempVouchers[index] };
        voucher.qtyReturning = newValue;
        tempVouchers[index] = voucher;
        setVouchers(tempVouchers);
    };

    return (
        <Paper>
            <DataTable columns={columns} cypressLabel="creditSaleDataTable">
                {freightRefundable(saleDetails) ? (
                    <>
                        <DataCell />
                        <DataCell>{'FREIGHT'}</DataCell>
                        <DataCell>
                            {CurrencyFormatter(saleDetails?.freight)}
                        </DataCell>
                        <DataCell />
                        <DataCell />
                        <DataCell>
                            <TextField
                                type="number"
                                size="small"
                                variant="standard"
                                value={freightRefunding}
                                onChange={(e) => {
                                    setFreightRefunding(
                                        Number(parseFloat(e.target.value))
                                            ? parseFloat(e.target.value)
                                            : null
                                    );
                                }}
                                inputProps={{
                                    min: 0,
                                    style: { textAlign: 'center' }
                                }}
                            />
                        </DataCell>
                    </>
                ) : null}
                {vouchers.map((row, index) => {
                    return (
                        <TableRow key={index}>
                            {row.voucher.CreditId === null ? (
                                <>
                                    <DataCell />
                                    <DataCell>{`VOUCHER #${row.voucher.id}`}</DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(
                                            row.voucher.totalPrice
                                        )}
                                    </DataCell>
                                    <DataCell>{'1'}</DataCell>
                                    <DataCell />
                                    <DataCell>
                                        <TextField
                                            type="number"
                                            size="small"
                                            variant="standard"
                                            value={row.qtyReturning}
                                            onChange={(e) =>
                                                handleUpdateVouchers(
                                                    parseInt(e.target.value),
                                                    row.voucher.id
                                                )
                                            }
                                            inputProps={{
                                                min: 0,
                                                style: { textAlign: 'center' }
                                            }}
                                        />
                                    </DataCell>
                                    <DataCell />
                                    <DataCell>
                                        {row.qtyReturning
                                            ? CurrencyFormatter(
                                                  row.qtyReturning *
                                                      row.voucher.totalPrice
                                              )
                                            : ''}
                                    </DataCell>
                                </>
                            ) : (
                                ''
                            )}
                        </TableRow>
                    );
                })}

                {refundableOrderLines?.map((row, index) => {
                    return (
                        <TableRow
                            key={index}
                            data-cy={`orderlineTableRow_${index}`}
                        >
                            {row.refundableQuantity <= 0 &&
                            row.backorderableQuantity <= 0 ? (
                                <>
                                    {row?.creditId ? (
                                        <DataCellColoured
                                            handleClick={() => {
                                                setCreditDrawerId(
                                                    row?.creditId
                                                );
                                                setOpenCreditDrawer(true);
                                            }}
                                        >
                                            {row.partNumber}
                                        </DataCellColoured>
                                    ) : row.refundableQuantity === 0 ? (
                                        <DataCell>
                                            <Typography
                                                variant="body2"
                                                sx={{ color: '#808080' }}
                                            >
                                                {row.partNumber}
                                            </Typography>
                                        </DataCell>
                                    ) : (
                                        <DataCell>{row.partNumber}</DataCell>
                                    )}
                                    <DataCell>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#808080' }}
                                        >
                                            {row.name}
                                        </Typography>
                                    </DataCell>
                                    <DataCell>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#808080' }}
                                        >
                                            {CurrencyFormatter(row.pricePaid)}
                                        </Typography>
                                    </DataCell>
                                    <DataCell>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#808080' }}
                                        >
                                            {row.refundableQuantity === 0
                                                ? row.refundableQuantity
                                                : row.quantity}
                                        </Typography>
                                    </DataCell>
                                    <DataCell>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#808080' }}
                                        >
                                            {row.backorderableQuantity}
                                        </Typography>
                                    </DataCell>
                                    <DataCell />
                                    <DataCell />
                                    {saleDetails.status ===
                                    'Proforma' ? null : (
                                        <DataCell>
                                            <Typography
                                                variant="body2"
                                                sx={{ color: '#808080' }}
                                            >
                                                {row?.amountRefunded
                                                    ? CurrencyFormatter(
                                                          row?.amountRefunded
                                                      )
                                                    : ''}
                                            </Typography>
                                        </DataCell>
                                    )}
                                </>
                            ) : (
                                <>
                                    <DataCell>{row.partNumber}</DataCell>
                                    <DataCell>{row.name}</DataCell>
                                    <DataCell
                                        cypressLabel={`pricePaid_${index}`}
                                    >
                                        {CurrencyFormatter(row.pricePaid)}
                                    </DataCell>
                                    <DataCell>
                                        {row.refundableQuantity}
                                    </DataCell>
                                    {saleDetails.status === 'Proforma' ? (
                                        <DataCell>
                                            {row.backorderableQuantity}
                                        </DataCell>
                                    ) : null}
                                    <DataCell>
                                        <TextField
                                            type="number"
                                            data-cy={`qtyReturning_${index}`}
                                            size="small"
                                            variant="standard"
                                            value={row.quantityReturning}
                                            onChange={(e) => {
                                                row.quantityReturning =
                                                    parseInt(e.target.value);
                                                row.amountRefunding =
                                                    parseInt(e.target.value) *
                                                        parseFloat(
                                                            row.pricePaid
                                                        ) +
                                                    (row.backorderQuantityReturning ??
                                                        0) *
                                                        parseFloat(
                                                            row.pricePaid
                                                        );
                                                setSaleDetails({
                                                    ...saleDetails,
                                                    orderLines:
                                                        saleDetails.orderLines
                                                });
                                            }}
                                            inputProps={{
                                                min: 0,
                                                style: { textAlign: 'center' }
                                            }}
                                        />
                                    </DataCell>
                                    {saleDetails.status === 'Proforma' ? (
                                        <DataCell>
                                            <TextField
                                                type="number"
                                                size="small"
                                                variant="standard"
                                                value={
                                                    row.backorderQuantityReturning
                                                }
                                                onChange={(e) => {
                                                    row.backorderQuantityReturning =
                                                        parseInt(
                                                            e.target.value
                                                        );
                                                    row.amountRefunding =
                                                        parseInt(
                                                            e.target.value
                                                        ) *
                                                            parseFloat(
                                                                row.pricePaid
                                                            ) +
                                                        (row.quantityReturning ??
                                                            0) *
                                                            parseFloat(
                                                                row.pricePaid
                                                            );
                                                    setSaleDetails({
                                                        ...saleDetails,
                                                        orderLines:
                                                            saleDetails.orderLines
                                                    });
                                                }}
                                                inputProps={{
                                                    min: 0,
                                                    style: {
                                                        textAlign: 'center'
                                                    }
                                                }}
                                            />
                                        </DataCell>
                                    ) : null}

                                    <DataCell>
                                        <TextField
                                            type="number"
                                            data-cy={`refundAmount_${index}`}
                                            size="small"
                                            variant="standard"
                                            value={row.amountRefunding}
                                            onChange={(e) => {
                                                row.amountRefunding =
                                                    parseFloat(e.target.value);
                                                setSaleDetails({
                                                    ...saleDetails,
                                                    orderLines:
                                                        saleDetails.orderLines
                                                });
                                            }}
                                            inputProps={{
                                                min: 0,
                                                style: {
                                                    textAlign: 'center'
                                                }
                                            }}
                                        />
                                    </DataCell>
                                </>
                            )}
                        </TableRow>
                    );
                })}

                {saleDetails.status === 'Proforma' ? (
                    <TableRow>
                        <DataCell></DataCell>
                        <DataCell></DataCell>
                        <DataCell></DataCell>
                        <DataCell></DataCell>
                        <DataCell>Refund Amount</DataCell>
                        <DataCell>
                            <TextField
                                size="small"
                                value={amountRefunding}
                                type="number"
                                onChange={(e) =>
                                    setAmountRefunding(
                                        parseFloat(e.target.value)
                                    )
                                }
                            />
                        </DataCell>
                        <DataCell></DataCell>
                    </TableRow>
                ) : null}

                {saleDetails?.freight && saleDetails?.freightRefunded ? (
                    <>
                        <DataCellColoured
                            handleClick={() => {
                                setCreditDrawerId('FREIGHT');
                                setOpenCreditDrawer(true);
                            }}
                        >
                            {'FREIGHT'}
                        </DataCellColoured>
                        <DataCell>
                            <Typography
                                variant="body2"
                                sx={{ color: '#808080' }}
                            >
                                {'FREIGHT'}
                            </Typography>
                        </DataCell>
                        <DataCell>
                            <Typography
                                variant="body2"
                                sx={{ color: '#808080' }}
                            >
                                {CurrencyFormatter(saleDetails?.freight)}
                            </Typography>
                        </DataCell>
                        <DataCell />
                        <DataCell />
                        <DataCell />
                        <DataCell />
                        <DataCell>
                            <Typography
                                variant="body2"
                                sx={{ color: '#808080' }}
                            >
                                {CurrencyFormatter(
                                    saleDetails?.freightRefunded
                                )}
                            </Typography>
                        </DataCell>
                    </>
                ) : null}

                {vouchers.map((row, index) => {
                    return (
                        <TableRow key={index}>
                            {row.voucher.CreditId !== null ? (
                                <>
                                    <DataCellColoured
                                        handleClick={() => {
                                            setCreditDrawerId(
                                                row.voucher.CreditId
                                            );
                                            setOpenCreditDrawer(true);
                                        }}
                                    >
                                        {`VOUCHER #${row.voucher.id}`}
                                    </DataCellColoured>
                                    <DataCell>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#808080' }}
                                        >
                                            {`VOUCHER #${row.voucher.id}`}
                                        </Typography>
                                    </DataCell>
                                    <DataCell>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#808080' }}
                                        >
                                            {CurrencyFormatter(
                                                row.voucher.totalPrice
                                            )}
                                        </Typography>
                                    </DataCell>
                                    <DataCell />
                                    <DataCell />
                                    <DataCell />
                                    <DataCell />
                                    <DataCell>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: '#808080' }}
                                        >
                                            {CurrencyFormatter(
                                                row.voucher.totalPrice
                                            )}
                                        </Typography>
                                    </DataCell>
                                </>
                            ) : (
                                ''
                            )}
                        </TableRow>
                    );
                })}
            </DataTable>
        </Paper>
    );
};

export default CreditOrderlines;
