import { useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
    Typography,
    Button,
    Box,
    Paper,
    Grid,
    Divider,
    IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SignatureCanvas from 'react-signature-canvas';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';

interface AdditionalCustomerFormsProps {
    onCustomerFormSubmit: (
        formData: any,
        submitType: 'preview' | 'upload'
    ) => Promise<void>;
    buttonLoading: boolean;
}

const AdditionalCustomerForms = ({
    onCustomerFormSubmit,
    buttonLoading
}: AdditionalCustomerFormsProps) => {
    let customerCreditSigCanvas = useRef<SignatureCanvas>(null);
    let customerDeclarationSigCanvas = useRef<SignatureCanvas>(null);

    const validationSchema = yup.object().shape({
        customerCreditSigDataURL: yup.string().required('Signature required.'),
        customerDeclarationSigDataURL: yup
            .string()
            .required('Signature required.'),
        purchaserAcceptDate: yup.date().required('Date required.')
    });

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        defaultValues: {
            // customer signature fields
            customerCreditSigDataURL: '',
            customerDeclarationSigDataURL: '',
            purchaserAcceptDate: null
        },
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    });

    const clearSignatureCustomer = (canvasName: string) => {
        if (
            canvasName === 'customerCreditSigCanvas' &&
            customerCreditSigCanvas.current
        ) {
            customerCreditSigCanvas.current.clear();
            setValue('customerCreditSigDataURL', null);
        } else if (
            canvasName === 'customerDeclarationSigCanvas' &&
            customerDeclarationSigCanvas.current
        ) {
            customerDeclarationSigCanvas.current.clear();
            setValue('customerDeclarationSigDataURL', null);
        }
    };

    const handleSaveSignature = (canvasName: string) => {
        if (
            canvasName === 'customerCreditSigCanvas' &&
            customerCreditSigCanvas.current
        ) {
            const dataURL = customerCreditSigCanvas.current.toDataURL();
            setValue('customerCreditSigDataURL', dataURL);
        } else if (
            canvasName === 'customerDeclarationSigCanvas' &&
            customerDeclarationSigCanvas.current
        ) {
            const dataURL = customerDeclarationSigCanvas.current.toDataURL();
            setValue('customerDeclarationSigDataURL', dataURL);
        }
    };

    const handlePreviewChanges = async (data) => {
        await onCustomerFormSubmit(data, 'preview');
    };

    const handleUpload = async (data) => {
        await onCustomerFormSubmit(data, 'upload');
    };

    return (
        <Box sx={{ padding: 4, textAlign: 'left' }}>
            <Paper elevation={3} sx={{ padding: 3, textAlign: 'left' }}>
                <Typography
                    variant="body1"
                    gutterBottom
                    component="div"
                    color={'error'}
                >
                    WHEN SIGNED, THIS DOCUMENT BECOMES LEGALLY BINDING. PLEASE
                    READ THE DOCUMENT CAREFULLY BEFORE YOU SIGN.
                </Typography>
                <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                </Grid>

                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                DOES THE PURCHASER REQUIRE CREDIT?
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                Please review the details in the contract file.
                                After confirmation, kindly sign below.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            SIGNATURE OF THE PURCHASER:{' '}
                            <IconButton
                                // disabled={disabled}
                                onClick={() =>
                                    clearSignatureCustomer(
                                        'customerCreditSigCanvas'
                                    )
                                }
                            >
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Controller
                                name="customerCreditSigDataURL"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <SignatureCanvas
                                            penColor="black"
                                            disabled={false}
                                            canvasProps={{
                                                width: 430,
                                                height: 200,
                                                className: 'sigCanvas',
                                                style: {
                                                    border: '1px solid black'
                                                }
                                            }}
                                            ref={customerCreditSigCanvas}
                                            // save user signature dataURL to form data
                                            onEnd={() =>
                                                handleSaveSignature(
                                                    'customerCreditSigCanvas'
                                                )
                                            }
                                        />
                                        {errors.customerCreditSigDataURL && (
                                            <Typography
                                                variant="body2"
                                                color="error"
                                            >
                                                {
                                                    errors
                                                        .customerCreditSigDataURL
                                                        .message
                                                }
                                            </Typography>
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                component="div"
                            >
                                DECLARATION
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                I acknowledge that I have read and understood
                                the motor dealer's / chattel auctioneer's
                                declaration and agree that:
                            </Typography>
                            <ul>
                                <Typography component="li" variant="body1">
                                    This contract is not binding on the dealer
                                    until it is accepted by the Motor Dealer.
                                </Typography>
                                <Typography component="li" variant="body1">
                                    The prices, allowances and amounts shown in
                                    this contract may be adjusted in accordance
                                    with above.
                                </Typography>
                                <Typography component="li" variant="body1">
                                    The buyer is not under the age of 18 years,
                                    not bankrupt and has not committed an act of
                                    bankruptcy.
                                </Typography>
                                <Typography component="li" variant="body1">
                                    Prior to the buyer signing this contract,
                                    all material particulars have been inserted
                                    in this contract and the buyer has read and
                                    understands such particulars and terms and
                                    conditions set out on all pages to this
                                    contract, including the conditions outlined
                                    at the end.
                                </Typography>
                            </ul>
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="purchaserAcceptDate"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            format="DD/MM/YYYY"
                                            label="Date"
                                            value={dayjs(field.value)}
                                            onChange={field.onChange}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: errors.purchaserAcceptDate
                                                        ? true
                                                        : false,
                                                    helperText:
                                                        errors.purchaserAcceptDate &&
                                                        errors
                                                            .purchaserAcceptDate
                                                            .message
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            SIGNATURE OF THE PURCHASER:{' '}
                            <IconButton
                                // disabled={disabled}
                                onClick={() =>
                                    clearSignatureCustomer(
                                        'customerDeclarationSigCanvas'
                                    )
                                }
                            >
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Controller
                                name="customerDeclarationSigDataURL"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <SignatureCanvas
                                            penColor="black"
                                            disabled={false}
                                            canvasProps={{
                                                width: 430,
                                                height: 200,
                                                className: 'sigCanvas',
                                                style: {
                                                    border: '1px solid black'
                                                }
                                            }}
                                            ref={customerDeclarationSigCanvas}
                                            // save user signature dataURL to form data
                                            onEnd={() =>
                                                handleSaveSignature(
                                                    'customerDeclarationSigCanvas'
                                                )
                                            }
                                        />

                                        {errors.customerDeclarationSigDataURL && (
                                            <Typography
                                                variant="body2"
                                                color="error"
                                            >
                                                {
                                                    errors
                                                        .customerDeclarationSigDataURL
                                                        .message
                                                }
                                            </Typography>
                                        )}
                                    </>
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} textAlign="center">
                            <Button
                                onClick={handleSubmit(handlePreviewChanges)}
                                variant="contained"
                                color="primary"
                            >
                                Preview Changes
                            </Button>
                        </Grid>
                        <Grid item xs={6} textAlign="center">
                            <LoadingButton
                                onClick={handleSubmit(handleUpload)}
                                loading={buttonLoading}
                                variant="contained"
                                color="primary"
                            >
                                Submit Signed Contract
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
};

export default AdditionalCustomerForms;
