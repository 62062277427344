import { Box, CircularProgress, Grid } from '@mui/material';
import { PDFDocument } from 'pdf-lib';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    GetFileFromS3,
    UploadFileToS3
} from '../newVehicleSale/logic/S3FileService';
import AdditionalCustomerForms from './components/AdditionalCustomerForms';
import VerifyCustomer from './VerifyCustomer';
import dayjs from 'dayjs';
import { EmailSignedPdf } from './logic/EmailContract';
import { Typography } from '@material-ui/core';
import PageWrapper from '../../global/PageWrapper';
import CustomerSubmitSuccessPage from './components/CustomerSubmitSuccessPage';

const CustomerSignContract = () => {
    const { id: vehicleSaleId } = useParams<{ id: string }>();
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [customerVerified, setCustomerVerified] = useState<boolean>(false);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean>(false);

    const [pdfDoc, setPdfDoc] = useState<PDFDocument>(null);
    const [pdfUrl, setPdfUrl] = useState<string>(null);

    const LoadContractPDF = () => {
        GetFileFromS3({
            folderName: 'vehicleSaleContracts',
            fileName: parseInt(vehicleSaleId),
            setPdfDoc: setPdfDoc,
            setPdfUrl: setPdfUrl
        });
    };

    useEffect(() => {
        if (customerVerified) {
            LoadContractPDF();
        }
        // eslint-disable-next-line
    }, [vehicleSaleId, customerVerified]);

    const handleClickSendBack = (pdfBlob) => {
        // Convert blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = async () => {
            const base64data = reader.result as string;
            // Upload the updated contract to S3
            UploadFileToS3({
                base64data: base64data,
                folderName: 'vehicleSaleContracts',
                fileName: parseInt(vehicleSaleId)
            });
            EmailSignedPdf({
                type: 'vehicle-contract',
                id: parseInt(vehicleSaleId),
                base64data: base64data,
                newStatus: 'customerSigned',
                setIsSubmitSuccess: setIsSubmitSuccess
            });
        };

        // Download the updated contract to the customer's device
        const url = URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Contract-${vehicleSaleId}.pdf`;
        a.click();
    };

    // this will be called when the customer signs the contract
    const onCustomerFormSubmit = async (
        formData: any,
        submitType: 'preview' | 'upload'
    ) => {
        if (!pdfDoc) throw new Error('PDF document not loaded.');

        // Make a copy of original document, so everytime formData changes, we draw text on the original document
        const pdfDocCopy = await PDFDocument.load(await pdfDoc.save());

        if (submitType === 'upload') {
            setButtonLoading(true);
        }
        const pages = pdfDocCopy.getPages();
        const firstPage = pages[0];
        const secondPage = pages[1];

        // customer credit signature on first page
        if (formData.customerCreditSigDataURL) {
            const customerCreditSigImage = await pdfDocCopy.embedPng(
                formData.customerCreditSigDataURL
            );

            firstPage.drawImage(customerCreditSigImage, {
                x: 375,
                y: 380,
                width: 100,
                height: 30
            });
        }

        // customer declaration signature on second page
        if (formData.customerDeclarationSigDataURL) {
            const customerDeclarationSigImage = await pdfDocCopy.embedPng(
                formData.customerDeclarationSigDataURL
            );
            secondPage.drawImage(customerDeclarationSigImage, {
                x: 110,
                y: 100,
                width: 100,
                height: 28
            });
        }

        // customer accept date
        if (formData.purchaserAcceptDate) {
            const date = dayjs(formData.purchaserAcceptDate);
            const day = date.date().toString().padStart(2, '0');
            const month = (date.month() + 1).toString().padStart(2, '0');
            const year = date.year().toString();

            secondPage.drawText(day, {
                x: 120,
                y: 130,
                size: 10
            });

            secondPage.drawText(month, {
                x: 240,
                y: 130,
                size: 10
            });

            secondPage.drawText(year, {
                x: 360,
                y: 130,
                size: 10
            });
        }

        const pdfBytes = await pdfDocCopy.save();
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        if (submitType === 'preview') {
            setPdfUrl(URL.createObjectURL(pdfBlob));
        } else if (submitType === 'upload') {
            handleClickSendBack(pdfBlob);
        }
    };

    if (!customerVerified) {
        return (
            <VerifyCustomer
                type="vehicle-contract"
                setCustomerVerified={setCustomerVerified}
            />
        );
    }

    if (isSubmitSuccess) {
        return <CustomerSubmitSuccessPage />;
    }

    return (
        <PageWrapper>
            {/* <Typography variant="h4">{`Contract #${vehicleSaleId}`}</Typography> */}
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                    <AdditionalCustomerForms
                        onCustomerFormSubmit={onCustomerFormSubmit}
                        buttonLoading={buttonLoading}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    {pdfUrl ? (
                        <Box sx={{ padding: 4 }} width="100%" height="100vh">
                            <iframe
                                src={pdfUrl + '#view=Fit'}
                                width="100%"
                                height="100%"
                                title={`Contract #${vehicleSaleId}`}
                            ></iframe>
                        </Box>
                    ) : (
                        <Box
                            sx={{ padding: 4 }}
                            width="100%"
                            height="100%"
                            textAlign={'center'}
                        >
                            <CircularProgress />
                            <Typography variant="h5">
                                Loading contract...
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default CustomerSignContract;
