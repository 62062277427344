// IMPORTS
import { Dispatch, SetStateAction, useContext, useEffect, useRef } from 'react';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
// COMPONENTS
import Paper from '../../../global/Paper';
import SignatureCanvas from 'react-signature-canvas';
// LOGIC
import GetUserSignature from '../../../global/databaseLogic/GetUserSignature';
// INTERFACES
import { User } from '../../../global/interfaces/GeneralInterface';
import { UserContext } from '../../../../main/Main';
import { Clear } from '@mui/icons-material';
interface PersonalDetailsProps {
    userId: string;
    userDetails: User;
    setUserDetails: Dispatch<SetStateAction<User>>;
    readOnly: boolean;
}
const PersonalDetails = ({
    userId,
    userDetails,
    readOnly,
    setUserDetails
}: PersonalDetailsProps) => {
    const currentUser = useContext<User>(UserContext);
    let sigCanvas = useRef<SignatureCanvas>(null);

    useEffect(() => {
        GetUserSignature(parseInt(userId), sigCanvas);
    }, [userId]);

    useEffect(() => {
        if (sigCanvas.current) {
            if (readOnly) {
                sigCanvas.current.off();
            } else {
                sigCanvas.current.on();
            }
        }
        // eslint-disable-next-line
    }, [readOnly]);

    const clearSignature = () => {
        if (sigCanvas.current) {
            sigCanvas.current.clear();
        }
    };

    const formatIntoPng = () => {
        if (sigCanvas.current) {
            const dataURL = sigCanvas.current.toDataURL();
            setUserDetails({ ...userDetails, signature: dataURL });
            return dataURL;
        }
    };
    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid
                    container
                    spacing={1}
                    item
                    xs={
                        Object.hasOwn(userDetails, 'id') &&
                        currentUser.id === userDetails.id
                            ? 8
                            : 12
                    }
                >
                    <Grid item xs={12}>
                        <Typography variant="h6">Personal Details</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            data-cy="viewUserPhoneNumberField"
                            size="small"
                            label="Phone Number"
                            InputLabelProps={{ shrink: true }}
                            disabled={readOnly}
                            value={userDetails?.phoneNumber}
                            onChange={(e) =>
                                setUserDetails({
                                    ...userDetails,
                                    phoneNumber: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            data-cy="viewUserPersonalEmailField"
                            size="small"
                            label="Email Address"
                            InputLabelProps={{ shrink: true }}
                            disabled={readOnly}
                            value={userDetails?.email}
                            onChange={(e) =>
                                setUserDetails({
                                    ...userDetails,
                                    email: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={readOnly}
                                format="DD/MM/YYYY"
                                label="Date of Birth"
                                value={dayjs(userDetails?.dateOfBirth)}
                                onChange={(newValue) =>
                                    setUserDetails({
                                        ...userDetails,
                                        dateOfBirth:
                                            dayjs(newValue).toISOString()
                                    })
                                }
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Address Line"
                            InputLabelProps={{ shrink: true }}
                            disabled={readOnly}
                            value={userDetails?.addressLine1}
                            onChange={(e) =>
                                setUserDetails({
                                    ...userDetails,
                                    addressLine1: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Suburb"
                            InputLabelProps={{ shrink: true }}
                            disabled={readOnly}
                            value={userDetails?.suburb}
                            onChange={(e) =>
                                setUserDetails({
                                    ...userDetails,
                                    suburb: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="State"
                            InputLabelProps={{ shrink: true }}
                            disabled={readOnly}
                            value={userDetails?.state}
                            onChange={(e) =>
                                setUserDetails({
                                    ...userDetails,
                                    state: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Country"
                            InputLabelProps={{ shrink: true }}
                            disabled={readOnly}
                            value={userDetails?.country}
                            onChange={(e) =>
                                setUserDetails({
                                    ...userDetails,
                                    country: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Postcode"
                            InputLabelProps={{ shrink: true }}
                            disabled={readOnly}
                            value={userDetails?.postcode}
                            onChange={(e) =>
                                setUserDetails({
                                    ...userDetails,
                                    postcode: e.target.value
                                })
                            }
                        />
                    </Grid>
                </Grid>
                {Object.hasOwn(userDetails, 'id') &&
                currentUser.id === userDetails.id ? (
                    <Grid item xs={4} textAlign={'center'}>
                        User Signature (This isn't currently used in Ora.){' '}
                        <IconButton
                            disabled={readOnly}
                            onClick={() => clearSignature()}
                        >
                            <Clear />
                        </IconButton>
                        <br />
                        <SignatureCanvas
                            disabled={readOnly}
                            penColor={'black'}
                            canvasProps={{
                                width: 350,
                                height: 150,
                                className: 'sigCanvas',
                                style: {
                                    border: '1px solid black'
                                }
                            }}
                            ref={sigCanvas}
                            onEnd={() => formatIntoPng()}
                        />
                    </Grid>
                ) : null}
            </Grid>
        </Paper>
    );
};

export default PersonalDetails;
