import api from '../../../../../api';


const GetContractInfo = (
    vehicleSaleId,
    setCustomer,
    setContractStatus,
    setVehicleCondition,
    setHasTradein,
    setSnackbar
) => {
    api.get('/getContractInfo/' + vehicleSaleId).then((res) => {
        if (res.status === 200) {
            setCustomer(res.data.customerInfo);
            setContractStatus(res.data.contractStatus);
            setVehicleCondition(res.data.vehicleCondition);
            setHasTradein(res.data.hasTradein);
        } else {
            setSnackbar({
                open: true,
                severity: 'error',
                message: 'Whoops! Something went wrong on our end.',
                subMessage: 'Please contact your IT department.'
            });
        }
    });
};

export default GetContractInfo;
