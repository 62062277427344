//IMPORTS
import { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Receipt, Save } from '@mui/icons-material';
//COMPONENTS
import CreditDetails from './components/CreditDetails';
import CreditDrawerContent from './components/CreditDrawerContent';
import CreditOrderlines from './components/CreditOrderlines';
import TotalForm from './components/TotalForm';
import Fab from '../../global/Fab';
import PageWrapper from '../../global/PageWrapper';
import SpeedDial from '../../global/SpeedDial';
import Drawer from '../../global/Drawer';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { Credit, Order, Voucher } from '../../global/interfaces/PartsInterface';
// LOGIC
import GetOrderCreditData from './logic/GetCreditOrderData';
import GetCompanyDetails from '../../global/databaseLogic/GetCompanyDetails';
import HandleSubmitCredit from './logic/HandleSubmitCredit';
import CreditPDF from './logic/CreditPDF';
import { withSnackbar } from '../../global/WrappingSnackbar';

interface TempOrder extends Order {
    orderLines?: {
        rowId?: number;
        UnitId?: number;
        name?: string;
        partNumber?: string;
        priceRRP?: number;
        priceCharged?: string;
        pricePaid?: string;
        costPriceDaily?: number;
        costPriceAverage?: number;
        quantity?: string;
        backorderQuantity?: string;
        incGst?: boolean;
        totalPrice?: number;
        gstFree?: boolean;
        totalCost?: number;
        BinLocationIds?: { name: string }[];
        SOH?: number;
        RES?: number;
        tradeRRP?: number;
        isTradePrice?: boolean;
        margin?: number;
        stockOrderType?: boolean;
        collecting?: boolean;
        inactive?: boolean;
        linkedSaleId?: number;
        quantityReturned?: number; // used in crediting order
        backorderQuantityReturned?: number; // used in crediting order
        dateRefunded?: string; // used in crediting order
        creditId?: number; // used in crediting order
        amountRefunded?: number; // used in crediting order
        // USED ONLY HERE
        refundableQuantity?: number;
        backorderableQuantity?: number;
        quantityReturning?: number;
        backorderQuantityReturning?: number;
        amountRefunding?: number;
    }[];
}

/**
 * Create a credit
 * @author Estienne
 * @returns {JSX} credit details, credit orderlines and total form
 */
const CreditSale = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const [saleDetails, setSaleDetails] = useState<TempOrder>();
    const [creditDetails, setCreditDetails] = useState<Credit>();
    const [vouchers, setVouchers] = useState<
        { voucher: Voucher; qtyReturning: number }[]
    >([]);
    const [returnReason, setReturnReason] = useState<string>('');
    const [returnMethod, setReturnMethod] = useState<string>('cash');
    const [selectedTill, setSelectedTill] = useState<number>(null);
    const [freightRefunding, setFreightRefunding] = useState<number>(null);
    const [amountRefunding, setAmountRefunding] = useState<number>(null); // USED WHEN REFUNDING PROFORMA
    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);

    // Drawer content
    const [creditDrawerId, setCreditDrawerId] = useState<number | string>();
    const [openCreditDrawer, setOpenCreditDrawer] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('Credit #');
    const [subtitle, setSubtitle] = useState<string>('Created by');

    // Get required credit data from the sale's details on mount
    useEffect(() => {
        GetOrderCreditData({
            id: parseInt(id),
            setSaleDetails: setSaleDetails,
            setCreditDetails: setCreditDetails,
            setVouchers: setVouchers,
            setLoading: setLoading
        });
        GetCompanyDetails(
            parseInt(localStorage.getItem('SiteId')),
            setSettings
        );
    }, [id]);

    /**
     * creditable
     * Check if the sale is still able to be credited
     * @author Estienne
     * @param sale the currently-viewed sale
     * @param vouchers any vouchers on the sale
     * @returns true if the sale is able to be credited; otherwise, false
     */
    const creditable = (
        sale: TempOrder,
        vouchers: { voucher: Voucher; qtyReturning: number }[]
    ) => {
        let refundableParts = sale.orderLines.filter(
            (line) =>
                line.refundableQuantity > 0 || line.backorderableQuantity > 0
        );
        let refundableVouchers = vouchers.filter(
            (voucherObj) => voucherObj.voucher.CreditId === null
        );

        // Check if all three parts of a sale have been refunded
        let partsRefundable = refundableParts.length > 0 ? true : false;
        let freightRefundable = null;
        let vouchersRefundable = null;

        // Check if freight is present and refundable
        if (sale.freight && sale.freightRefunded === null) {
            freightRefundable = true;
        } else if (sale.freight && sale.freightRefunded !== null) {
            freightRefundable = false;
        }

        // Check if vouchers are present and refundable
        if (vouchers.length > 0 && refundableVouchers.length > 0) {
            vouchersRefundable = true;
        } else if (vouchers.length > 0 && refundableVouchers.length === 0) {
            vouchersRefundable = false;
        }

        // Check if any part of the sale is refundable
        if (partsRefundable || freightRefundable || vouchersRefundable) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * partiallyCredited
     * Check if a sale has been partially credited
     * @author Estienne
     * @param sale the sale
     * @param vouchers the sale's vouchers
     * @returns true if the sale has been partially credited; otherwise, false
     */
    const partiallyCredited = (
        sale: TempOrder,
        vouchers: { voucher: Voucher; qtyReturning: number }[]
    ) => {
        if (creditable(sale, vouchers)) {
            let refundedParts = sale.orderLines.filter((line) => line.creditId);
            let refundedFreight = sale.freightRefunded ? true : false;
            let refundedVouchers = vouchers.filter(
                (voucherObj) => voucherObj.voucher.CreditId !== null
            );

            if (
                refundedParts.length > 0 ||
                refundedFreight ||
                refundedVouchers.length > 0
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    return (
        <PageWrapper>
            {!loading ? (
                <>
                    <Typography
                        variant="h4"
                        align="left"
                    >{`Credit Sale #${saleDetails?.id}`}</Typography>
                    <Typography variant="body2" align="left">
                        <b>{`${creditDetails?.referenceType}: ${creditDetails?.reference}`}</b>
                    </Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            {creditable(saleDetails, vouchers) ? (
                                <>
                                    <CreditDetails
                                        saleDetails={saleDetails}
                                        settings={settings}
                                        selectedTill={selectedTill}
                                        returnMethod={returnMethod}
                                        returnReason={returnReason}
                                        setReturnMethod={setReturnMethod}
                                        setReturnReason={setReturnReason}
                                        setSelectedTill={setSelectedTill}
                                    />
                                    <br />
                                </>
                            ) : null}
                            <CreditOrderlines
                                saleDetails={saleDetails}
                                setSaleDetails={setSaleDetails}
                                freightRefunding={freightRefunding}
                                vouchers={vouchers}
                                setVouchers={setVouchers}
                                setFreightRefunding={setFreightRefunding}
                                setCreditDrawerId={setCreditDrawerId}
                                setOpenCreditDrawer={setOpenCreditDrawer}
                                amountRefunding={amountRefunding}
                                setAmountRefunding={setAmountRefunding}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TotalForm
                                saleDetails={saleDetails}
                                vouchers={vouchers}
                                freightRefunding={freightRefunding}
                                amountRefunding={amountRefunding}
                            />
                        </Grid>
                    </Grid>

                    {creditable(saleDetails, vouchers) ? (
                        partiallyCredited(saleDetails, vouchers) ? (
                            <SpeedDial
                                actionsList={[
                                    {
                                        icon: <Save />,
                                        name: 'Save',
                                        onClick: () =>
                                            HandleSubmitCredit({
                                                saleDetails: saleDetails,
                                                creditDetails: creditDetails,
                                                freightRefunding:
                                                    freightRefunding,
                                                vouchers: vouchers,
                                                returnReason: returnReason,
                                                returnMethod: returnMethod,
                                                selectedTill: selectedTill,
                                                proformaAmountRefunding:
                                                    amountRefunding,
                                                showSnackbar: showSnackbar
                                            })
                                    },
                                    {
                                        icon: <Receipt />,
                                        name: 'Print',
                                        onClick: () =>
                                            CreditPDF({
                                                siteId: parseInt(
                                                    localStorage.getItem(
                                                        'SiteId'
                                                    )
                                                ),
                                                saleDetails: saleDetails,
                                                customerDetails:
                                                    saleDetails.Customer,
                                                vouchers: vouchers,
                                                allCredits: true
                                            })
                                    }
                                ]}
                            />
                        ) : (
                            <Fab
                                editIcon={false}
                                noSession={true}
                                cypressLabel="submitCreditSale"
                                onClick={(e) =>
                                    HandleSubmitCredit({
                                        saleDetails: saleDetails,
                                        creditDetails: creditDetails,
                                        freightRefunding: freightRefunding,
                                        vouchers: vouchers,
                                        returnReason: returnReason,
                                        returnMethod: returnMethod,
                                        selectedTill: selectedTill,
                                        proformaAmountRefunding:
                                            amountRefunding,
                                        showSnackbar: showSnackbar
                                    })
                                }
                            />
                        )
                    ) : (
                        <Fab
                            editIcon={false}
                            customIcon={<Receipt />}
                            noSession={true}
                            onClick={(e) =>
                                CreditPDF({
                                    siteId: parseInt(
                                        localStorage.getItem('SiteId')
                                    ),
                                    saleDetails: saleDetails,
                                    customerDetails: saleDetails?.Customer,
                                    vouchers: vouchers,
                                    allCredits: true
                                })
                            }
                        />
                    )}

                    <Drawer
                        openDrawer={openCreditDrawer}
                        setOpenDrawer={setOpenCreditDrawer}
                        title={title}
                        subTitle={subtitle}
                    >
                        <CreditDrawerContent
                            saleDetails={saleDetails}
                            creditId={creditDrawerId}
                            customerDetails={saleDetails?.Customer}
                            vouchers={vouchers}
                            setSelectedTill={setSelectedTill}
                            setTitle={setTitle}
                            setSubtitle={setSubtitle}
                        />
                    </Drawer>
                </>
            ) : null}
        </PageWrapper>
    );
};

export default withSnackbar(CreditSale);
