import { Customer } from '../../../global/interfaces/GeneralInterface';
import { TradeIn } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import api from '../../../../../api';
// import PDFVendorStatement from '../../newVehicleSale/logic/PDFVendorStatement';

const HandleSubmitTrades = (
    selectedCustomer: Customer,
    tradeVehicles: TradeIn[],
    gstType: string,
    showSnackbar: showSnackbar
) => {
    if (!selectedCustomer) {
        showSnackbar('You must select a customer to continue.', '', 'error');
        return;
    }

    if (tradeVehicles.length === 0) {
        showSnackbar(
            'You must enter at least one trade in vehicle to continue.',
            '',
            'error'
        );
        return;
    }

    let obj = {
        selectedCustomer: selectedCustomer,
        tradeVehicles: tradeVehicles,
        gstType: gstType
    };

    api.post(`newTradeIn?SiteId=${localStorage.getItem('SiteId')}`, obj).then(
        (res) => {
            if (res.status === 200) {
                showSnackbar('Trades successfully added.');
                // PDFVendorStatement(
                //     res.data[0],
                //     res.data[0].Customer,
                //     res.data,
                //     true
                // );
            } else {
                showSnackbar(
                    'Whoops! Something went wrong on our end',
                    'Please contact your IT department.',
                    'error'
                );
            }
        }
    );
};

export default HandleSubmitTrades;
