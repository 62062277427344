import {
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { Controller } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import SignatureCanvas from 'react-signature-canvas';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useRef } from 'react';

const DealerDeclarationForm = ({ control, setValue }) => {
    let dealerSigCanvas = useRef<SignatureCanvas>(null);

    const clearDealerSignature = () => {
        if (dealerSigCanvas.current) {
            dealerSigCanvas.current.clear();
        }
    };

    const handleSaveDealerSignature = () => {
        if (dealerSigCanvas.current) {
            const dataURL = dealerSigCanvas.current.toDataURL();
            setValue('dealerSigDataURL', dataURL);
        }
    };

    return (
        <Grid container spacing={2} margin={'10px'}>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                    Motor Dealer/Chattel Auctioneer Declaration
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="noPriorContract"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="There is no prior contract with another buyer to purchase this vehicle (motor dealer only)."
                        />
                    )}
                />

                <Controller
                    name="notPersuadeCoolingOff"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="I have not attempted to persuade the buyer to forfeit their cooling-off rights (motor dealer only)."
                        />
                    )}
                />

                <Controller
                    name="hasStatutoryWarranty"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="This motor vehicle has a statutory warranty."
                        />
                    )}
                />
                <Controller
                    name="noStatutoryWarranty"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="This motor vehicle does not have a statutory warranty."
                        />
                    )}
                />

                <Controller
                    name="notPersuadeWarranty"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="I have not attempted to persuade the buyer to forfeit their statutory warranty."
                        />
                    )}
                />

                <Controller
                    name="informedBuyer"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Checkbox {...field} />}
                            label="I have informed the buyer if the vehicle is an unregistered written-off vehicle or was previously written off."
                        />
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                        name="dealerSignDate"
                        control={control}
                        render={({ field }) => (
                            <DatePicker
                                format="DD/MM/YYYY"
                                label="Date"
                                value={dayjs(field.value)}
                                onChange={field.onChange}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false
                                    }
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
                SIGNATURE:{' '}
                <IconButton onClick={clearDealerSignature}>
                    <ClearIcon />
                </IconButton>
            </Grid>
            <Grid item xs={12} textAlign="center">
                <Controller
                    name="dealerSigDataURL"
                    control={control}
                    render={({ field }) => (
                        <SignatureCanvas
                            penColor="black"
                            disabled={false}
                            canvasProps={{
                                width: 430,
                                height: 200,
                                className: 'sigCanvas',
                                style: {
                                    border: '1px solid black'
                                }
                            }}
                            ref={dealerSigCanvas}
                            onEnd={handleSaveDealerSignature}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default DealerDeclarationForm;
