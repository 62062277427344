// IMPORTS
import { Dispatch, SetStateAction, useRef } from 'react';
import {
    Grid,
    TextField,
    ToggleButtonGroup,
    ToggleButton,
    IconButton,
    Badge,
    Tooltip
} from '@mui/material';
import { Notes } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import PartSearch from '../../../../global/PartSearch';
import RoleCheck from '../../../../global/RoleCheck';
import SiteSelector from '../../../../global/SiteSelector';
// LOGIC
import HandleAddPart from '../../logic/HandleAddPart';
import HasBackorder from '../../logic/HasBackorder';
import UpdateExtraCostsOnStatusChange from '../../logic/UpdateExtraCostsOnStatusChange';
import { safeCalculateAmountDiff } from '../../../../global/logic/Operators';
// INTERFACES
import { ExtraCost, Order } from '../../../../global/interfaces/PartsInterface';
import { Customer } from '../../../../global/interfaces/GeneralInterface';
import {
    Vehicle,
    VehicleSale
} from '../../../../global/interfaces/VehicleSaleInterface';
import { Service } from '../../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

interface OrderlineHeaderProps {
    id: number;
    readOnly: boolean;
    oldSale: Order;
    saleDetails: Order;
    setSaleDetails: Dispatch<SetStateAction<Order>>;
    selectedType: string;
    selectedEntity: Customer | VehicleSale | Vehicle | Service;
    showSnackbar: showSnackbar;
    setNotesDialogOpen: Dispatch<SetStateAction<boolean>>;
    extraCosts: ExtraCost[];
    setExtraCosts: Dispatch<SetStateAction<ExtraCost[]>>;
    selectedSite: number;
    setSelectedSite: Dispatch<SetStateAction<number>>;
}

const OrderlineHeader = ({
    id,
    readOnly,
    oldSale,
    saleDetails,
    setSaleDetails,
    selectedType,
    selectedEntity,
    showSnackbar,
    setNotesDialogOpen,
    extraCosts,
    setExtraCosts,
    selectedSite,
    setSelectedSite
}: OrderlineHeaderProps) => {
    const preNewTypeRef = useRef(null);

    const handleAddPart = (selectedPart, selectedSupplier) => {
        HandleAddPart(
            selectedPart,
            saleDetails,
            setSaleDetails,
            selectedType,
            selectedEntity,
            selectedSupplier
        );
    };

    const calculateLeftToPay = (saleDetails) => {
        let total = 0;
        for (let line of saleDetails.orderLines) {
            if (!line.inactive) {
                total += line.totalPrice;
            }
        }
        return total;
    };

    const handleChangeType = (
        event: React.MouseEvent<HTMLElement>,
        newType: string | null
    ) => {
        if (newType != null) {
            const prevType = preNewTypeRef.current;

            if (selectedType === 'contract' && newType === 'Sale') {
                if (
                    safeCalculateAmountDiff(
                        saleDetails.amountPaid,
                        calculateLeftToPay(saleDetails)
                    ) >= 0
                ) {
                    let currentOrderLines = JSON.parse(
                        JSON.stringify(saleDetails.orderLines)
                    );
                    for (let line of currentOrderLines) {
                        if (!line.inactive) {
                            line.collecting = false;
                        }
                    }
                    setSaleDetails({
                        ...saleDetails,
                        status: newType,
                        orderLines: currentOrderLines
                    });
                } else {
                    showSnackbar(
                        'CSM add-ons have not been paid!',

                        'Add-ons must be paid through the contract before converting to a sale.',
                        'error'
                    );
                }
            } else {
                setSaleDetails({ ...saleDetails, status: newType });
            }

            preNewTypeRef.current = newType;

            if (
                prevType === 'Sale' &&
                newType !== 'Sale' &&
                extraCosts.length > 0
            ) {
                UpdateExtraCostsOnStatusChange({
                    status: newType,
                    extraCosts,
                    setExtraCosts,
                    showSnackbar
                });
            }
        }
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <PartSearch
                            cypressLabel="partSearchPA"
                            HandleAdd={handleAddPart}
                            size="small"
                            isDisabled={readOnly || oldSale.status === 'Sale'}
                            useAutocomplete={false}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            data-cy="poNumberField"
                            fullWidth
                            size="small"
                            disabled={readOnly || oldSale.status === 'Sale'}
                            label="PO Number"
                            value={saleDetails.PONumber}
                            onChange={(e) =>
                                setSaleDetails({
                                    ...saleDetails,
                                    PONumber: e.target.value
                                })
                            }
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={2} textAlign="left">
                        <SiteSelector
                            variant="outlined"
                            label="Site"
                            disabled={readOnly || oldSale.status === 'Sale'}
                            fullWidth
                            size="small"
                            viewPartUI={false}
                            selectedSite={selectedSite}
                            setSelectedSite={setSelectedSite}
                        />
                    </Grid>
                    <Grid item xs={1} textAlign="left">
                        <Tooltip placement="bottom" title="Sale Notes">
                            <IconButton
                                onClick={() => setNotesDialogOpen(true)}
                            >
                                <Badge
                                    color="secondary"
                                    variant="dot"
                                    invisible={
                                        saleDetails.customerNotes ||
                                        saleDetails.notes
                                            ? false
                                            : true
                                    }
                                >
                                    <Notes />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            fullWidth
                            value={saleDetails.status}
                            onChange={handleChangeType}
                            disabled={readOnly}
                            size="small"
                        >
                            <ToggleButton
                                value="Sale"
                                data-cy="saleToggleButton"
                                disabled={
                                    !id &&
                                    (selectedType === 'contract' ||
                                        HasBackorder(saleDetails) ||
                                        oldSale.status === 'Sale')
                                }
                            >
                                Sale
                            </ToggleButton>

                            {oldSale.status === 'Sale' ? (
                                <RoleCheck
                                    permission={'sale_to_proforma'}
                                    component={
                                        <ToggleButton value="Proforma">
                                            Proforma
                                        </ToggleButton>
                                    }
                                    nullComponent={
                                        <ToggleButton
                                            value="Proforma"
                                            disabled={true}
                                        >
                                            Proforma
                                        </ToggleButton>
                                    }
                                />
                            ) : (
                                <ToggleButton
                                    value="Proforma"
                                    data-cy="proformaToggleButton"
                                    disabled={selectedType === 'guest'}
                                >
                                    Proforma
                                </ToggleButton>
                            )}

                            <ToggleButton
                                value="Quote"
                                data-cy="quoteToggleButton"
                                disabled={
                                    selectedType === 'contract' ||
                                    oldSale.status === 'Sale' ||
                                    (oldSale.status === 'Proforma' &&
                                        oldSale.paymentLines.length > 0)
                                }
                            >
                                Quote
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default OrderlineHeader;
