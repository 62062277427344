// INTERFACES
import {
    Credit,
    Order,
    Voucher
} from '../../../global/interfaces/PartsInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
// LOGIC
import api from '../../../../../api';
import { ToFixed } from '../../../global/logic/Formatters';
import ValidateCreditSale from './ValidateCreditSale';
import { CalculateAmountRefunded } from './CreditCalculations';
import PDFVoucherInvoice from '../../voucher/logic/PDFVoucherInvoice';
import CreditPDF from './CreditPDF';

interface TempOrder extends Order {
    orderLines?: {
        rowId?: number;
        UnitId?: number;
        name?: string;
        partNumber?: string;
        priceRRP?: number;
        priceCharged?: string;
        pricePaid?: string;
        costPriceDaily?: number;
        costPriceAverage?: number;
        quantity?: string;
        backorderQuantity?: string;
        incGst?: boolean;
        totalPrice?: number;
        gstFree?: boolean;
        totalCost?: number;
        BinLocationIds?: { name: string }[];
        SOH?: number;
        RES?: number;
        tradeRRP?: number;
        isTradePrice?: boolean;
        margin?: number;
        stockOrderType?: boolean;
        collecting?: boolean;
        inactive?: boolean;
        linkedSaleId?: number;
        quantityReturned?: number; // used in crediting order
        backorderQuantityReturned?: number; // used in crediting order
        dateRefunded?: string; // used in crediting order
        creditId?: number; // used in crediting order
        // USED ONLY HERE
        refundableQuantity?: number;
        backorderableQuantity?: number;
        quantityReturning?: number;
        backorderQuantityReturning?: number;
        amountRefunding?: number;
    }[];
}

interface CreditProps {
    saleDetails: TempOrder;
    creditDetails: Credit;
    freightRefunding: number;
    vouchers: { voucher: Voucher; qtyReturning: number }[];
    returnReason: string;
    returnMethod: string;
    selectedTill: number;
    proformaAmountRefunding: number;
    showSnackbar: showSnackbar;
}

/**
 * HandleSubmitCredit
 * Submit the credit to the backend
 * @author Estienne
 * @params CreditProps
 */
const HandleSubmitCredit = async ({
    saleDetails,
    creditDetails,
    freightRefunding,
    vouchers,
    returnReason,
    returnMethod,
    selectedTill,
    proformaAmountRefunding,
    showSnackbar
}: CreditProps) => {
    let amountRefunding = CalculateAmountRefunded(
        saleDetails.orderLines,
        freightRefunding,
        vouchers
    );

    // Make sure the user inputs are valid
    let validated = ValidateCreditSale(
        saleDetails,
        freightRefunding,
        vouchers,
        returnReason
    );

    if (validated.validated === false) {
        showSnackbar(validated.message, '', 'error');
    } else {
        await api
            .post('/creditNextGen', {
                OrderId: saleDetails?.id,
                referenceType: creditDetails.referenceType,
                referenceId: creditDetails.id,
                SiteId: localStorage.getItem('SiteId'),
                TillId: selectedTill,
                orderLines: saleDetails.orderLines,
                amountRefunded: ToFixed(amountRefunding),
                freightRefunded: ToFixed(freightRefunding),
                vouchers: vouchers,
                reasonForCredit: returnReason,
                refundMethod: returnMethod,
                CustomerId: saleDetails.CustomerId,
                saleStatus: saleDetails.status,
                proformaAmountRefunding: proformaAmountRefunding
            })
            .then(async (res) => {
                if (res.status === 200) {
                    showSnackbar('Credit processed successfully.');

                    // If refunding to a voucher, print the voucher PDF
                    if (returnMethod === 'voucher') {
                        await PDFVoucherInvoice(res.data.voucher[0].id);
                    }

                    // Print the credit PDF
                    await CreditPDF({
                        siteId: parseInt(localStorage.getItem('SiteId')),
                        saleDetails: saleDetails,
                        customerDetails: saleDetails.Customer,
                        vouchers: res.data.creditedVouchers,
                        allCredits: false,
                        credit: res.data.credit
                    });

                    window.location.href = '/inventory/sales';
                } else {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact your IT department.',
                        'error'
                    );
                }
            });
    }
};

export default HandleSubmitCredit;
