// IMPORTS
import { useState, useEffect, useContext } from 'react';
import {
    List,
    Toolbar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Collapse,
    Badge,
    useTheme
} from '@mui/material';
import {
    AccountBalance,
    AttachMoney,
    BarChart,
    Build,
    Computer,
    DriveEta,
    ExpandLess,
    ExpandMore,
    FormatListBulleted,
    LocalAtm,
    Outbox,
    People,
    ShowChart,
    HelpOutline,
    ArrowCircleRightOutlined,
    PedalBike,
    TwoWheeler
} from '@mui/icons-material';

import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Link, useLocation } from 'react-router-dom';

// COMPONENTS
import AvatarCard from './components/AvatarCard';
import AdminSubMenu from './components/AdminSubMenu';
import RoleCheck from '../global/RoleCheck';
import PartsSubMenu from './components/PartsSubMenu';
import StockSubMenu from './components/StockSubMenu';
import VehicleSubMenu from './components/VehicleSubMenu';
import ServiceSubMenu from './components/ServiceSubMenu';
import ITSubMenu from './components/ITSubMenu';

// LOGIC
import CountOnlineOrders from './logic/CountOnlineOrders';

// INTERFACE
import { Site } from '../global/interfaces/GeneralInterface';
import { SiteContext } from '../../main/Main';
import OnlineSubMenu from './components/OnlineSubMenu';

const drawerWidth = 320;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 5px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 999,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme), // apply closed styles default
    '&.open': {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    } // Apply opened styles when className is 'open'
}));

const Sidebar = () => {
    const siteDetails = useContext<Site>(SiteContext);
    const location = useLocation();
    const isSupportPage = location.pathname.startsWith('/support');
    const currentTheme = useTheme();
    const [openSubmenu, setOpenSubmenu] = useState<string>('');
    const [onlineOrderCount, setOnlineOrderCount] = useState<number>(0);

    useEffect(() => {
        CountOnlineOrders(setOnlineOrderCount);
    }, []);

    const toggleSubmenu = (menu: string) => {
        if (openSubmenu === menu) setOpenSubmenu('');
        else setOpenSubmenu(menu);
    };

    return (
        <>
            {/* Drawer is default set as open when window inner width larger than 600 */}
            <Drawer
                variant="permanent"
                id="sidebar"
                className={window.outerWidth < 600 ? '' : 'open'}
            >
                <Toolbar />
                <AvatarCard />
                <Divider />
                {!isSupportPage && (
                    <List component="nav">
                        {/* HOME PAGE */}
                        <ListItemButton
                            component={Link}
                            to="/"
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemIcon>
                                <BarChart sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Overview
                            </ListItemText>
                        </ListItemButton>

                        {/* ADMIN DROPDOWN */}
                        <ListItemButton
                            sx={{ fontSize: '13px' }}
                            onClick={() => toggleSubmenu('admin')}
                        >
                            <ListItemIcon>
                                <AccountBalance sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Admin
                            </ListItemText>
                            {openSubmenu === 'admin' ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </ListItemButton>
                        <Collapse
                            in={openSubmenu === 'admin'}
                            timeout="auto"
                            unmountOnExit
                        >
                            <AdminSubMenu />
                        </Collapse>

                        {/* IT DROPDOWN */}
                        <RoleCheck
                            permission={'configure_api, access_ipWhitelist'}
                            component={
                                <>
                                    <ListItemButton
                                        sx={{ fontSize: '13px' }}
                                        onClick={() => toggleSubmenu('IT')}
                                    >
                                        <ListItemIcon>
                                            <Computer sx={{ color: '#555' }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                variant: 'caption'
                                            }}
                                        >
                                            IT
                                        </ListItemText>
                                        {openSubmenu === 'IT' ? (
                                            <ExpandLess />
                                        ) : (
                                            <ExpandMore />
                                        )}
                                    </ListItemButton>
                                    <Collapse
                                        in={openSubmenu === 'IT'}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <ITSubMenu />
                                    </Collapse>
                                </>
                            }
                        />

                        {/*CUSTOMERS */}
                        <ListItemButton
                            component={Link}
                            to="/customers"
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemIcon>
                                <People sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Customers
                            </ListItemText>
                        </ListItemButton>

                        {/* ONLINE ORDERS */}
                        {siteDetails.departments.some(
                            (x) => x.name === 'ONLINE'
                        ) && (
                            <RoleCheck
                                permission={'admin_manager'}
                                component={
                                    <>
                                        <ListItemButton
                                            sx={{ fontSize: '13px' }}
                                            onClick={() =>
                                                toggleSubmenu('online')
                                            }
                                        >
                                            <ListItemIcon>
                                                <Badge
                                                    badgeContent={
                                                        onlineOrderCount
                                                    }
                                                    color="error"
                                                >
                                                    <Outbox
                                                        sx={{
                                                            color: '#555'
                                                        }}
                                                    />
                                                </Badge>
                                            </ListItemIcon>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    variant: 'caption'
                                                }}
                                            >
                                                Online Orders
                                            </ListItemText>
                                            {openSubmenu === 'online' ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            )}
                                        </ListItemButton>
                                        <Collapse
                                            in={openSubmenu === 'online'}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <OnlineSubMenu />
                                        </Collapse>
                                    </>
                                }
                                nullComponent={
                                    <RoleCheck
                                        permission={'access_online'}
                                        component={
                                            <ListItemButton
                                                component={Link}
                                                to="/online/orders"
                                                sx={{ fontSize: '13px' }}
                                            >
                                                <ListItemIcon>
                                                    <Badge
                                                        badgeContent={
                                                            onlineOrderCount
                                                        }
                                                        color="error"
                                                    >
                                                        <Outbox
                                                            sx={{
                                                                color: '#555'
                                                            }}
                                                        />
                                                    </Badge>
                                                </ListItemIcon>
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        variant: 'caption'
                                                    }}
                                                >
                                                    Online Orders
                                                </ListItemText>
                                            </ListItemButton>
                                        }
                                    />
                                }
                            />
                        )}

                        {/* INVENTORY */}
                        <ListItemButton
                            component={Link}
                            to="/inventory/search"
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemIcon>
                                <FormatListBulleted sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Inventory
                            </ListItemText>
                        </ListItemButton>

                        {/* PARTS DROPDOWN */}
                        <ListItemButton
                            sx={{ fontSize: '13px' }}
                            data-cy="paButton"
                        >
                            <ListItemIcon
                                onClick={() =>
                                    (window.location.href =
                                        '/inventory/newSale')
                                }
                            >
                                <AttachMoney
                                    sx={{
                                        color: '#555',
                                        '&:hover': {
                                            color: currentTheme.palette.primary
                                                .main
                                        }
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                                onClick={() => toggleSubmenu('parts')}
                            >
                                Parts & Accessories
                            </ListItemText>
                            {openSubmenu === 'parts' ? (
                                <ExpandLess
                                    onClick={() => toggleSubmenu('parts')}
                                />
                            ) : (
                                <ExpandMore
                                    onClick={() => toggleSubmenu('parts')}
                                />
                            )}
                        </ListItemButton>
                        <Collapse
                            in={openSubmenu === 'parts'}
                            timeout="auto"
                            unmountOnExit
                        >
                            <PartsSubMenu />
                        </Collapse>

                        {/* STOCK DROPDOWN */}
                        <ListItemButton
                            sx={{ fontSize: '13px' }}
                            onClick={() => toggleSubmenu('stock')}
                        >
                            <ListItemIcon>
                                <ShowChart sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Stock Control
                            </ListItemText>
                            {openSubmenu === 'stock' ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </ListItemButton>
                        <Collapse
                            in={openSubmenu === 'stock'}
                            timeout="auto"
                            unmountOnExit
                        >
                            <StockSubMenu />
                        </Collapse>

                        {/* VEHICLE DROPDOWN */}
                        <>
                            <ListItemButton sx={{ fontSize: '13px' }}>
                                <ListItemIcon
                                    onClick={() =>
                                        (window.location.href =
                                            '/vehicles/newContract')
                                    }
                                >
                                    {siteDetails.vehicleTypeSold ===
                                    'Motorcycles' ? (
                                        <TwoWheeler
                                            sx={{
                                                color: '#555',
                                                '&:hover': {
                                                    color: currentTheme.palette
                                                        .primary.main
                                                }
                                            }}
                                        />
                                    ) : (
                                        <PedalBike
                                            sx={{
                                                color: '#555',
                                                '&:hover': {
                                                    color: currentTheme.palette
                                                        .primary.main
                                                }
                                            }}
                                        />
                                    )}
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: 'caption'
                                    }}
                                    onClick={() => toggleSubmenu('vehicle')}
                                >
                                    {siteDetails.vehicleTypeSold ===
                                    'Motorcycles'
                                        ? 'Vehicle'
                                        : 'Bicycle'}{' '}
                                    Sales
                                </ListItemText>
                                {openSubmenu === 'vehicle' ? (
                                    <ExpandLess
                                        onClick={() => toggleSubmenu('vehicle')}
                                    />
                                ) : (
                                    <ExpandMore
                                        onClick={() => toggleSubmenu('vehicle')}
                                    />
                                )}
                            </ListItemButton>
                            <Collapse
                                in={openSubmenu === 'vehicle'}
                                timeout="auto"
                                unmountOnExit
                            >
                                <VehicleSubMenu />
                            </Collapse>
                        </>

                        {/* SERVICE DROPDOWN */}
                        <>
                            {' '}
                            <ListItemButton sx={{ fontSize: '13px' }}>
                                <ListItemIcon
                                    onClick={() =>
                                        (window.location.href =
                                            '/service/newBooking')
                                    }
                                >
                                    <Build
                                        sx={{
                                            color: '#555',
                                            '&:hover': {
                                                color: currentTheme.palette
                                                    .primary.main
                                            }
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: 'caption'
                                    }}
                                    onClick={() => toggleSubmenu('service')}
                                >
                                    Service
                                </ListItemText>
                                {openSubmenu === 'service' ? (
                                    <ExpandLess
                                        onClick={() => toggleSubmenu('service')}
                                    />
                                ) : (
                                    <ExpandMore
                                        onClick={() => toggleSubmenu('service')}
                                    />
                                )}
                            </ListItemButton>
                            <Collapse
                                in={openSubmenu === 'service'}
                                timeout="auto"
                                unmountOnExit
                            >
                                <ServiceSubMenu />
                            </Collapse>
                        </>

                        {/* TILL RECONCILIATION */}
                        <ListItemButton
                            component={Link}
                            to="/tillReconciliation"
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemIcon>
                                <LocalAtm sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Till Reconciliation
                            </ListItemText>
                        </ListItemButton>

                        {/* SUPPORT */}
                        <ListItemButton
                            onClick={() =>
                                (window.location.href = `/support${location.pathname}`)
                            }
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemIcon>
                                <HelpOutline sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Support
                            </ListItemText>
                        </ListItemButton>
                    </List>
                )}

                {isSupportPage && (
                    <List component="nav">
                        {/* About Ora */}
                        <ListItemButton
                            component={Link}
                            to="/support/"
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemIcon>
                                <BarChart sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Overview
                            </ListItemText>
                        </ListItemButton>

                        {/* ADMIN Documentation */}
                        <ListItemButton
                            sx={{ fontSize: '13px' }}
                            onClick={() => toggleSubmenu('admin')}
                        >
                            <ListItemIcon>
                                <AccountBalance sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Admin
                            </ListItemText>
                            {openSubmenu === 'admin' ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </ListItemButton>
                        <Collapse
                            in={openSubmenu === 'admin'}
                            timeout="auto"
                            unmountOnExit
                        >
                            <AdminSubMenu isSupportPage={isSupportPage} />
                        </Collapse>

                        {/*CUSTOMERS  Documentation*/}
                        <ListItemButton
                            component={Link}
                            to="/support/customers"
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemIcon>
                                <People sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Customers
                            </ListItemText>
                        </ListItemButton>

                        {/* INVENTORY Documentation*/}
                        <ListItemButton
                            component={Link}
                            to="/support/inventory/search"
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemIcon>
                                <FormatListBulleted sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Inventory
                            </ListItemText>
                        </ListItemButton>

                        {/* PARTS DROPDOWN Documentation*/}
                        <ListItemButton
                            sx={{ fontSize: '13px' }}
                            onClick={() => toggleSubmenu('parts')}
                        >
                            <ListItemIcon>
                                <AttachMoney sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Parts & Accessories
                            </ListItemText>
                            {openSubmenu === 'parts' ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </ListItemButton>
                        <Collapse
                            in={openSubmenu === 'parts'}
                            timeout="auto"
                            unmountOnExit
                        >
                            <PartsSubMenu isSupportPage={isSupportPage} />
                        </Collapse>

                        {/* STOCK DROPDOWN */}
                        <ListItemButton
                            sx={{ fontSize: '13px' }}
                            onClick={() => toggleSubmenu('stock')}
                        >
                            <ListItemIcon>
                                <ShowChart sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Stock Control
                            </ListItemText>
                            {openSubmenu === 'stock' ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </ListItemButton>
                        <Collapse
                            in={openSubmenu === 'stock'}
                            timeout="auto"
                            unmountOnExit
                        >
                            <StockSubMenu isSupportPage={isSupportPage} />
                        </Collapse>

                        {/* VEHICLE DROPDOWN */}
                        <>
                            <ListItemButton
                                sx={{ fontSize: '13px' }}
                                onClick={() => toggleSubmenu('vehicle')}
                            >
                                <ListItemIcon>
                                    <DriveEta sx={{ color: '#555' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: 'caption'
                                    }}
                                >
                                    Vehicle Sales
                                </ListItemText>
                                {openSubmenu === 'vehicle' ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItemButton>
                            <Collapse
                                in={openSubmenu === 'vehicle'}
                                timeout="auto"
                                unmountOnExit
                            >
                                <VehicleSubMenu isSupportPage={isSupportPage} />
                            </Collapse>
                        </>

                        {/* SERVICE DROPDOWN */}
                        <>
                            {' '}
                            <ListItemButton
                                sx={{ fontSize: '13px' }}
                                onClick={() => toggleSubmenu('service')}
                            >
                                <ListItemIcon>
                                    <Build sx={{ color: '#555' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: 'caption'
                                    }}
                                >
                                    Service
                                </ListItemText>
                                {openSubmenu === 'service' ? (
                                    <ExpandLess />
                                ) : (
                                    <ExpandMore />
                                )}
                            </ListItemButton>
                            <Collapse
                                in={openSubmenu === 'service'}
                                timeout="auto"
                                unmountOnExit
                            >
                                <ServiceSubMenu isSupportPage={isSupportPage} />
                            </Collapse>
                        </>

                        {/* TILL RECONCILIATION */}
                        <ListItemButton
                            component={Link}
                            to="/support/tillReconciliation"
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemIcon>
                                <LocalAtm sx={{ color: '#555' }} />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Till Reconciliation
                            </ListItemText>
                        </ListItemButton>

                        {/* Back to ora */}
                        <ListItemButton
                            onClick={() => (window.location.href = '/')}
                            sx={{ fontSize: '13px' }}
                        >
                            <ListItemIcon>
                                <ArrowCircleRightOutlined
                                    sx={{ color: '#555' }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'caption' }}
                            >
                                Back Home
                            </ListItemText>
                        </ListItemButton>
                    </List>
                )}
            </Drawer>
        </>
    );
};

export default Sidebar;
