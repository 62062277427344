import { Grid, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CustomerSubmitSuccessPage = () => {
    return (
        <Grid container spacing={2} sx={{ margin: 10, textAlign: 'center' }}>
            <Grid item xs={12}>
                <CheckCircleOutlineIcon
                    sx={{ fontSize: 100, color: 'green' }}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4" align="center">
                    Document signed successfully!
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" align="center">
                    Document has been signed and downloaded to your device.
                    Check your email for a copy of the document.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default CustomerSubmitSuccessPage;
