// IMPORTS
import { Customer } from '../../../../global/interfaces/GeneralInterface';
import { Service } from '../../../../global/interfaces/ServiceInterface';
import { Vehicle } from '../../../../global/interfaces/VehicleSaleInterface';
import CustomerDetails from './CustomerDetails';
import VehicleDetails from './VehicleDetails';
import { DateSafeFormatter } from '../../../../global/logic/Formatters';

const PDFJobCard = async (
    doc,
    serviceDetails: Service,
    selectedCustomer: Customer,
    vehicleType: string,
    selectedVehicle: Vehicle,
    image,
    settings,
    site
) => {
    /* ----------------------------- Document Header ---------------------------- */
    doc.addImage(image, 10, 15, 65, 20); // Put image on page

    if (serviceDetails.insuranceClaimNumber) {
        // PDF title
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(13);
        doc.text(`Service Order`, 10, 35);

        doc.setFont('helvetica', 'bold').text(
            serviceDetails.jobType === 'Quote'
                ? 'Quote #'
                : 'Tax Invoice #' + serviceDetails.id,
            10,
            40
        );

        serviceDetails.jobFinish &&
            doc
                .setFontSize(10)
                .text(DateSafeFormatter(serviceDetails.jobFinish), 10, 45);

        // Dealership details (ABN, address, etc)
        doc.setFont('helvetica', 'normal').setFontSize(11);
        doc.text(settings.companyName, 199, 20, { align: 'right' });
        doc.text(site.address, 199, 25, { align: 'right' });
        doc.text(
            site.suburb + ' ' + site.state + ' ' + site.postcode,
            199,
            30,
            {
                align: 'right'
            }
        );
        doc.text('Phone: ' + site.phoneNumber, 199, 35, { align: 'right' });
        doc.setFont('helvetica', 'bold').text('ABN: ' + settings.abn, 199, 40, {
            align: 'right'
        });
        doc.setFont('helvetica', 'normal').text(
            'Account Name: ' + settings.directDepositAccountName,
            199,
            45,
            {
                align: 'right'
            }
        );
        doc.setFont('helvetica', 'normal').text(
            'BSB: ' +
                settings.directDepositAccountBsb +
                ' Account Number: ' +
                settings.directDepositAccountNumber,
            199,
            50,
            { align: 'right' }
        );

        /* ---------------------------- Customer Details ---------------------------- */
        CustomerDetails(doc, site, settings, selectedCustomer);

        /* ----------------------------- Vehicle Details ---------------------------- */
        VehicleDetails(doc, vehicleType, selectedVehicle);
    } else {
        // PDF title
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(13);
        doc.text(`Service Order`, 10, 40);

        doc.setFont('helvetica', 'bold').text(
            serviceDetails.jobType === 'Quote'
                ? 'Quote #'
                : 'Tax Invoice #' + serviceDetails.id,
            10,
            45
        );

        doc.setFontSize(10).text(
            DateSafeFormatter(serviceDetails.jobFinish),
            10,
            50
        );

        // Dealership details (ABN, address, etc)
        doc.setFont('helvetica', 'normal').setFontSize(11);
        doc.text(settings.companyName, 199, 20, { align: 'right' });
        doc.text(site.address, 199, 25, { align: 'right' });
        doc.text(
            site.suburb + ' ' + site.state + ' ' + site.postcode,
            199,
            30,
            {
                align: 'right'
            }
        );
        doc.text('Phone: ' + site.phoneNumber, 199, 35, { align: 'right' });
        doc.setFont('helvetica', 'bold').text('ABN: ' + settings.abn, 199, 40, {
            align: 'right'
        });
        doc.setFont('helvetica', 'normal').text(
            'Account Name: ' + settings.directDepositAccountName,
            199,
            45,
            {
                align: 'right'
            }
        );
        doc.setFont('helvetica', 'normal').text(
            'BSB: ' +
                settings.directDepositAccountBsb +
                ' Account Number: ' +
                settings.directDepositAccountNumber,
            199,
            50,
            { align: 'right' }
        );

        /* ---------------------------- Customer Details ---------------------------- */
        CustomerDetails(doc, site, settings, selectedCustomer);

        /* ----------------------------- Vehicle Details ---------------------------- */
        VehicleDetails(doc, vehicleType, selectedVehicle);
    }

    // Open the pdf
    return doc;
};

export default PDFJobCard;
