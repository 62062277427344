// IMPORTS
import { Dispatch, SetStateAction } from 'react';
// LOGIC
import api from '../../../../../api';
// INTERFACES
import {
    Credit,
    Order,
    Voucher
} from '../../../global/interfaces/PartsInterface';

interface TempOrder extends Order {
    orderLines?: {
        rowId?: number;
        UnitId?: number;
        name?: string;
        partNumber?: string;
        priceRRP?: number;
        priceCharged?: string;
        pricePaid?: string;
        costPriceDaily?: number;
        costPriceAverage?: number;
        quantity?: string;
        backorderQuantity?: string;
        incGst?: boolean;
        totalPrice?: number;
        gstFree?: boolean;
        totalCost?: number;
        BinLocationIds?: { name: string }[];
        SOH?: number;
        RES?: number;
        tradeRRP?: number;
        isTradePrice?: boolean;
        margin?: number;
        stockOrderType?: boolean;
        collecting?: boolean;
        inactive?: boolean;
        linkedSaleId?: number;
        quantityReturned?: number; // used in crediting order
        backorderQuantityReturned?: number; // used in crediting order
        dateRefunded?: string; // used in crediting order
        creditId?: number; // used in crediting order
        // USED ONLY HERE
        refundableQuantity?: number;
        backorderableQuantity?: number;
        quantityReturning?: number;
        backorderQuantityReturning?: number;
        amountRefunding?: number;
    }[];
}

interface CreditProps {
    id: number;
    setSaleDetails: Dispatch<SetStateAction<TempOrder>>;
    setCreditDetails: Dispatch<SetStateAction<Credit>>;
    setVouchers: Dispatch<
        SetStateAction<{ voucher: Voucher; qtyReturning: number }[]>
    >;
    setLoading: Dispatch<SetStateAction<boolean>>;
}

/**
 * GetOrderCreditData
 * Get credit sale data
 * @author Pierre
 * @param id the sale id
 * @param setSaleDetails state setter for the order data
 * @param setCreditDetails state setter for the credit data
 * @param setVouchers state setter for vouchers on the sale
 * @param setLoading state setter for loading state
 */
const GetOrderCreditData = ({
    id,
    setSaleDetails,
    setCreditDetails,
    setVouchers,
    setLoading
}: CreditProps) => {
    api.get(`saleDetails/${id}`).then((res) => {
        if (res.status === 200) {
            let data = res.data.saleDetails;
            let voucherData = res.data.vouchers;
            let vouchers = [];

            // Create the array of voucher objects
            voucherData.forEach((voucher) => {
                vouchers.push({ voucher: voucher, qtyReturning: null });
            });

            if (vouchers.length > 0) {
                setVouchers(vouchers);
            }

            if (data.VehicleId) {
                setCreditDetails({
                    type: 'Vehicle Part',
                    referenceType: 'Vehicle Part Sale ID',
                    id: data.Vehicle.id,
                    reference: String(data.Vehicle.id)
                });
            } else if (data.CustomerId !== null) {
                setCreditDetails({
                    type: 'Customer',
                    referenceType: 'Customer Sale ID',
                    id: data.Customer.id,
                    reference:
                        data.Customer.id +
                        ' - ' +
                        (data.Customer.firstName ?? '') +
                        ' ' +
                        (data.Customer.lastName ?? '')
                });
            } else if (data.VehicleSaleId) {
                setCreditDetails({
                    type: 'Vehicle Contract',
                    referenceType: 'Vehicle Sale ID',
                    id: data.VehicleSale.id,
                    reference: String(data.VehicleSale.id)
                });
            } else if (data.ServiceId) {
                setCreditDetails({
                    type: 'Service Parts',
                    referenceType: 'Service ID',
                    id: data.Service.id,
                    reference: String(data.Service.id)
                });
            } else if (data.internalType === 1) {
                setCreditDetails({
                    type: 'Workshop',
                    referenceType: 'Reference',
                    id: data.VehicleSale.id,
                    reference: data.Site.name + ' Workshop'
                });
            } else if (data.internalType === 2) {
                setCreditDetails({
                    type: 'Sales Department',
                    referenceType: 'Reference',
                    id: data.VehicleSale.id,
                    reference: data.Site.name + ' Vehicle Sales Department'
                });
            }

            let newOrderLines = [];

            // Add refundable quantity to the orderlines
            // and total the amount paid
            data.orderLines.forEach((line) => {
                line.refundableQuantity =
                    parseInt(line.quantity) - (line.quantityReturned ?? 0);
                line.backorderableQuantity =
                    parseInt(line.backorderQuantity) -
                    (line.backorderQuantityReturned ?? 0);
                newOrderLines.push(line);
            });

            data.orderLines = newOrderLines;

            setSaleDetails(data);
            setLoading(false);
        }
    });
};

export default GetOrderCreditData;
