// IMPORTS
import { useState } from 'react';
import {
    Typography,
    TableRow,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import {
    Check,
    Add,
    FileDownload,
    Groups,
    FileUpload
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import CustomerTableFilter from './components/CustomerTableFilter';
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
import MergeCustomerDialog from './components/MergeCustomerDialog';
import { withSnackbar } from '../../global/WrappingSnackbar';
import { ImportCustomerCSVContent } from './components/ImportCustomerCSVContent';
// LOGIC
import ConvertArrayToFilter from '../../global/logic/ConvertArrayToFilter';
import HandleDownloadResults from '../../global/logic/HandleDownloadResults';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { Customer } from '../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {
    customerFilter: {
        customerType: [],
        debtor: null,
        hasPhoneNumber: null,
        hasEmail: null
    }
};
const clearedSort = ['id', 'DESC'];

const CustomerTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const history = useHistory();
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<Customer[]>([]);
    const [mergeDialogOpen, setMergeDialogOpen] = useState<boolean>(false);

    // CSV Import Dialog
    const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);

    // Boolean that check if there is at least one account in the DB
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Customer',
            onClick: () => history.push('/addCustomer')
        },
        {
            icon: <FileDownload />,
            name: 'Export Current Results',
            onClick: () => handleDownloadResults()
        },
        {
            icon: <Groups />,
            name: 'Merge Customers',
            onClick: () => setMergeDialogOpen(true)
        },
        {
            icon: <FileUpload />,
            name: 'Import Customer CSV',
            onClick: () => setImportDialogOpen(true)
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
        { id: 1, label: 'Personal / Company Name', width: 300 },
        { id: 2, label: 'Email', width: 200 },
        { id: 3, label: 'Phone Number', width: 200 },
        { id: 4, label: 'Debtor', width: 100 },
        { id: 5, label: 'Type', name: 'customerType', sort: true, width: 100 }
    ];

    // Handle downloading the csv
    const handleDownloadResults = () => {
        HandleDownloadResults(
            sort,
            'customerSearchNextGen',
            handleRequestCreate(),
            'OraCustomerResults.csv',
            [
                'ID',
                'Customer Type',
                'First Name',
                'Last Name',
                'Company Name',
                'ABN',
                'Email',
                'Phone Number',
                'Suburb',
                'Postcode',
                'Is Debtor',
                'Account Limit',
                'Payment Terms'
            ],
            [
                'id',
                'customerType',
                'firstName',
                'lastName',
                'companyName',
                'abn',
                'email',
                'phoneNumber',
                'suburb',
                'postcode',
                'debtor',
                'accountLimit',
                'paymentTerms'
            ]
        );
    };

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        let customerType =
            filter.customerFilter.customerType.length > 0
                ? ConvertArrayToFilter({
                      array: filter.customerFilter.customerType,
                      filterStart: '&customerType=',
                      selector: 'value'
                  })
                : '';
        let debtor = '&debtor=' + filter.customerFilter.debtor;
        let hasPhoneNumber =
            '&hasPhoneNumber=' + filter.customerFilter.hasPhoneNumber;
        let hasEmail = '&hasEmail=' + filter.customerFilter.hasEmail;

        let apiString = `${customerType}${debtor}${hasPhoneNumber}${hasEmail}&checkEmpty=true`;
        return apiString;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        let isActive = false;

        if (
            filter.customerFilter.customerType.length > 0 ||
            filter.customerFilter.debtor != null ||
            filter.customerFilter.hasEmail != null ||
            filter.customerFilter.hasPhoneNumber != null
        ) {
            isActive = true;
        }

        return isActive;
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Customers</Typography>
            <br />
            <TableSearch
                searchTitle="Search Customers"
                showFilter={true}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="customerSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
                setIsEmpty={setIsEmpty}
            >
                <CustomerTableFilter
                    filter={filter}
                    setFilter={setFilter}
                    isFilterActive={isFilterActive}
                />
            </TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() =>
                                    history.push(`/viewCustomer/${row.id}`)
                                }
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>
                                {row.companyName != null &&
                                row.customerType === 'Trade'
                                    ? row.companyName
                                    : row.firstName + ' ' + row.lastName}
                            </DataCell>
                            <DataCell>{row.email}</DataCell>
                            <DataCell>{row.phoneNumber}</DataCell>
                            <DataCell>{row.debtor ? <Check /> : null}</DataCell>
                            <DataCell>
                                <Chip
                                    label={row.customerType}
                                    variant="outlined"
                                    color={
                                        row.customerType === 'Retail'
                                            ? 'success'
                                            : 'warning'
                                    }
                                />
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>

            <Dialog
                open={mergeDialogOpen}
                onClose={() => setMergeDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Merge Customers</DialogTitle>
                <DialogContent>
                    <MergeCustomerDialog
                        setMergeDialogOpen={setMergeDialogOpen}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={isEmpty}
                onClose={() => setIsEmpty(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>No customer created yet</DialogTitle>
                <DialogContent>
                    <Typography>
                        Ora couldn't find any customer in the database. Do you
                        wish to import customer(s) through a CSV?
                    </Typography>
                    <Typography>
                        You can also manually create customer(s), simply close
                        this dialog and click on the pop-up menu in the bottom
                        right of the page and click on "Add customer".
                    </Typography>
                    <Typography variant="caption">
                        If you have already created customer(s) and this dialog
                        opened by mistake, please contact the Ora development
                        team.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => setIsEmpty(false)}
                    >
                        Close Dialog
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsEmpty(false);
                            setImportDialogOpen(true);
                        }}
                    >
                        Open CSV Import Function
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={importDialogOpen}
                onClose={() => setImportDialogOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <ImportCustomerCSVContent showSnackbar={showSnackbar} />
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(CustomerTable);
