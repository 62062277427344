import { useState, useEffect, Dispatch, SetStateAction } from 'react';

// COMPONENTS
import {
    debounce,
    Grid,
    TableRow,
    TextField,
    Typography,
    IconButton
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Autocomplete from '../../../global/Autocomplete';
import DataTable from '../../../global/tableComponents/DataTable';
import DataCell from '../../../global/tableComponents/DataCell';
import PartSearch from '../../../global/PartSearch';

// INTERFACES
import { Site } from '../../../global/interfaces/GeneralInterface';

// LOGIC
import GetAllSites from '../../../global/databaseLogic/GetAllSites';
import AddPartToStockTransfer from '../logic/AddPartToStockTransfer';
import { DateFormatter } from '../../../global/logic/Formatters';
import SubmitTransfer from '../logic/SubmitTransfer';
import UpdateTransfer from '../logic/UpdateTransfer';
import HandleRemoveParts from '../logic/HandleRemoveParts';
import DeleteTransfer from '../logic/DeleteTransfer';

// INTERFACES
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import {
    Order,
    StockTransfer,
    Unit
} from '../../../global/interfaces/PartsInterface';
import RoleCheck from '../../../global/RoleCheck';
import Drawer from '../../../global/Drawer';
import ProformaDrawerContent from './ProformaDrawerContent';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import api from '../../../../../api';

interface StockTransferDrawerContentProps {
    showSnackbar: showSnackbar;
    selectedTransfer?: StockTransfer;
    setSelectedTransfer?: Dispatch<SetStateAction<StockTransfer>>;
    partDetails?: Unit;
}

// Define the structure of your entire state object
interface TransferData {
    SiteFrom?: Site;
    SiteTo?: Site;
    transferLines: any[];
    notes: string;
}

const StockTransferDrawerContent = ({
    showSnackbar,
    selectedTransfer,
    setSelectedTransfer,
    partDetails
}: StockTransferDrawerContentProps) => {
    const [siteListUnaltered, setSiteListUnaltered] = useState<Site[]>([]);
    const [siteList, setSiteList] = useState<Site[]>([]);
    const [transferData, setTransferData] = useState<TransferData>({
        SiteFrom: {},
        SiteTo: {},
        transferLines: [],
        notes: ''
    });
    const [proformaDrawerOpen, setProformaDrawerOpen] =
        useState<boolean>(false);
    const [proformaData, setProformaData] =
        useState<{ partNumber: string; proformaData: Order[] }[]>();
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        GetAllSites(setSiteList);
        GetAllSites(setSiteListUnaltered);
    }, []);

    const columns = selectedTransfer
        ? [
              { id: 0, label: 'Part Number' },
              { id: 1, label: 'Part Name', width: 250 },
              { id: 2, label: 'Qty Sent' },
              { id: 3, label: 'Qty Received' },
              { id: 4, label: '' }
          ]
        : [
              { id: 0, label: 'Part Number' },
              { id: 1, label: 'Part Name', width: 250 },
              { id: 2, label: 'Qty Sent', width: 150 },
              { id: 3, label: 'Qty Available', width: 250 },
              { id: 4, label: '' }
          ];

    const handleAddPart = (unitDetails) => {
        AddPartToStockTransfer(unitDetails, transferData, setTransferData);
    };

    useEffect(() => {
        if (partDetails) {
            let unitDetails = { unitData: partDetails, stockData: [] };
            AddPartToStockTransfer(unitDetails, transferData, setTransferData);
        }
        // eslint-disable-next-line
    }, []);

    const [stockInfo, setStockInfo] = useState({});

    const getSiteStock = async (unitId, siteTo) => {
        const response = await api.get(
            `inventory/siteStock/${unitId}?SiteTo=${siteTo}`
        );
        const siteStocks = response.data;
        const stockText =
            siteStocks && siteStocks.length > 0
                ? siteStocks.map((stock, index) => (
                      <div key={index}>
                          {stock.Site.name}: {stock.qtyOnHand}
                      </div>
                  ))
                : 'No stock data.';

        setStockInfo((prev) => ({
            ...prev,
            [unitId]: stockText
        }));
    };

    useEffect(() => {
        transferData.transferLines.forEach((line) => {
            getSiteStock(line.UnitId, transferData.SiteTo.id);
        });
    }, [transferData.transferLines, transferData.SiteTo.id]);

    return selectedTransfer ? (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6} textAlign="center">
                    <Typography>
                        Transferred from: {selectedTransfer.SiteFrom.name}
                    </Typography>
                </Grid>
                <Grid item xs={6} textAlign={'center'}>
                    <Typography>
                        Transferred to: {selectedTransfer.SiteTo.name}
                    </Typography>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={6} textAlign="center">
                    <Typography variant="body2">
                        <b>Requested On: </b>
                        {DateFormatter(selectedTransfer?.createdAt)}
                    </Typography>
                </Grid>
                {selectedTransfer?.CompletedUser ? (
                    <Grid item xs={6} textAlign="center">
                        <Typography variant="body2">
                            <b>Completed By: </b>
                            {selectedTransfer?.CompletedUser?.firstName}{' '}
                            {selectedTransfer?.CompletedUser?.lastName}
                        </Typography>
                        <Typography variant="body2">
                            <b>On: </b>
                            {DateFormatter(selectedTransfer?.completedAt)}
                        </Typography>
                        <br />
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <DataTable columns={columns}>
                        {selectedTransfer?.transferLines?.map((row, index) => (
                            <TableRow>
                                <DataCell>{row.partNumber}</DataCell>
                                <DataCell>{row.name}</DataCell>
                                <DataCell>{row.quantitySent}</DataCell>
                                <DataCell>
                                    {selectedTransfer.status === 'COMPLETE' ? (
                                        row.quantityReceived ? (
                                            row.quantityReceived
                                        ) : (
                                            row.quantitySent
                                        )
                                    ) : (
                                        <TextField
                                            type="number"
                                            size="small"
                                            error={row.error}
                                            value={row.quantityReceived}
                                            onChange={(e) => {
                                                let newTransferlines =
                                                    selectedTransfer.transferLines;
                                                newTransferlines[
                                                    index
                                                ].quantityReceived = parseInt(
                                                    e.target.value
                                                );
                                                setSelectedTransfer({
                                                    ...selectedTransfer,
                                                    transferLines: [
                                                        ...newTransferlines
                                                    ]
                                                });
                                            }}
                                        />
                                    )}
                                </DataCell>
                                <DataCell>
                                    {selectedTransfer.status ===
                                    'COMPLETE' ? null : (
                                        <IconButton
                                            onClick={() =>
                                                HandleRemoveParts(
                                                    index,
                                                    selectedTransfer,
                                                    setSelectedTransfer
                                                )
                                            }
                                        >
                                            <Close />
                                        </IconButton>
                                    )}
                                </DataCell>
                            </TableRow>
                        ))}
                    </DataTable>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        disabled={selectedTransfer.status === 'COMPLETE'}
                        multiline
                        rows={5}
                        label="Notes"
                        InputLabelProps={{ shrink: true }}
                        value={selectedTransfer.notes}
                        onChange={(e) =>
                            setSelectedTransfer({
                                ...selectedTransfer,
                                notes: e.target.value
                            })
                        }
                    />
                </Grid>
                <RoleCheck
                    permission="delete_stockTransfer"
                    component={
                        <>
                            <Grid item xs={6} textAlign="center">
                                {selectedTransfer.status === 'PENDING' ? (
                                    <LoadingButton
                                        loading={buttonLoading}
                                        variant="outlined"
                                        color="primary"
                                        onClick={debounce(
                                            () =>
                                                DeleteTransfer(
                                                    selectedTransfer,
                                                    setButtonLoading,
                                                    showSnackbar
                                                ),
                                            300
                                        )}
                                    >
                                        Cancel Stock Transfer
                                    </LoadingButton>
                                ) : null}
                            </Grid>
                            <Grid item xs={6} textAlign="center">
                                {selectedTransfer.status ===
                                'COMPLETE' ? null : (
                                    <LoadingButton
                                        loading={buttonLoading}
                                        variant="contained"
                                        color="primary"
                                        onClick={debounce(
                                            () =>
                                                UpdateTransfer(
                                                    selectedTransfer,
                                                    setProformaDrawerOpen,
                                                    setProformaData,
                                                    setButtonLoading,
                                                    showSnackbar
                                                ),
                                            300
                                        )}
                                    >
                                        Transfer Parts
                                    </LoadingButton>
                                )}
                            </Grid>
                        </>
                    }
                    nullComponent={
                        <Grid item xs={12} textAlign="center">
                            {selectedTransfer.status === 'COMPLETE' ? null : (
                                <LoadingButton
                                    loading={buttonLoading}
                                    variant="contained"
                                    color="primary"
                                    onClick={debounce(
                                        () =>
                                            UpdateTransfer(
                                                selectedTransfer,
                                                setProformaDrawerOpen,
                                                setProformaData,
                                                setButtonLoading,
                                                showSnackbar
                                            ),
                                        300
                                    )}
                                >
                                    Transfer Parts
                                </LoadingButton>
                            )}
                        </Grid>
                    }
                />
            </Grid>

            <Drawer
                openDrawer={proformaDrawerOpen}
                setOpenDrawer={setProformaDrawerOpen}
                title={'Proformas'}
                subTitle={'Parts in Stock Transfer #' + selectedTransfer.id}
                width="40vw"
            >
                <ProformaDrawerContent proformaData={proformaData} />
            </Drawer>
        </>
    ) : (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Autocomplete
                        options={siteList}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Transferred From"
                        selectedValue={transferData.SiteFrom}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = siteListUnaltered.filter(
                                (x) => x.id !== event.id
                            );
                            setSiteList(currentOptions);
                            setTransferData({
                                ...transferData,
                                SiteFrom: event
                            });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Autocomplete
                        options={siteList}
                        useTwoOptionLabels={false}
                        primaryOptionLabel={'name'}
                        textfieldLabel="Transferred To"
                        selectedValue={transferData.SiteTo}
                        handleSelectedValueChange={(event) => {
                            let currentOptions = siteListUnaltered.filter(
                                (x) => x.id !== event.id
                            );
                            setSiteList(currentOptions);
                            setTransferData({ ...transferData, SiteTo: event });
                        }}
                        handleInputValueChange={() => null}
                    />
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12}>
                    <PartSearch
                        HandleAdd={handleAddPart}
                        size="small"
                        isDisabled={
                            !transferData.SiteFrom.id || !transferData.SiteTo.id
                        }
                        SiteFrom={transferData.SiteFrom}
                        SiteTo={transferData.SiteTo}
                    />
                </Grid>
                <Grid item xs={12} />
                {transferData.transferLines.length > 0 ? (
                    <Grid item xs={12}>
                        <DataTable columns={columns}>
                            {transferData.transferLines.map((line, index) => (
                                <TableRow>
                                    <DataCell>{line.partNumber}</DataCell>
                                    <DataCell>{line.name}</DataCell>
                                    <DataCell>
                                        <TextField
                                            value={line.quantitySent}
                                            size="small"
                                            onChange={(e) => {
                                                let newTransferLines = [
                                                    ...transferData.transferLines
                                                ];
                                                const inputValue =
                                                    e.target.value;
                                                // Check if the input value is not empty and is a number, set 0 if it is invalid number
                                                newTransferLines[
                                                    index
                                                ].quantitySent =
                                                    inputValue === ''
                                                        ? ''
                                                        : parseInt(
                                                              inputValue
                                                          ) || 0;
                                                setTransferData({
                                                    ...transferData,
                                                    transferLines:
                                                        newTransferLines
                                                });
                                            }}
                                        />
                                    </DataCell>
                                    <DataCell>
                                        {stockInfo[line.UnitId] || 'Loading...'}
                                    </DataCell>
                                    <DataCell>
                                        <IconButton
                                            onClick={() =>
                                                HandleRemoveParts(
                                                    index,
                                                    transferData,
                                                    setTransferData
                                                )
                                            }
                                        >
                                            <Close />
                                        </IconButton>
                                    </DataCell>
                                </TableRow>
                            ))}
                        </DataTable>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <br />
                    <TextField
                        fullWidth
                        multiline
                        rows={5}
                        label="Notes"
                        InputLabelProps={{ shrink: true }}
                        value={transferData.notes}
                        onChange={(e) =>
                            setTransferData({
                                ...transferData,
                                notes: e.target.value
                            })
                        }
                    />
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <LoadingButton
                        loading={buttonLoading}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            SubmitTransfer(
                                transferData,
                                setButtonLoading,
                                showSnackbar
                            );
                        }}
                    >
                        Submit Transfer
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
};

export default StockTransferDrawerContent;
