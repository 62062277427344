// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Close, East, Refresh } from '@mui/icons-material';
// COMPONENTS
import Paper from '../../../../global/Paper';
import PartSearch from '../../../../global/PartSearch';
import Autocomplete from '../../../../global/Autocomplete';
import DataTable from '../../../../global/tableComponents/DataTable';
import DataCell from '../../../../global/tableComponents/DataCell';
import DataCellColoured from '../../../../global/tableComponents/DataCellColoured';
// LOGIC
import { CurrencyFormatter } from '../../../../global/logic/Formatters';
import GetAllFluids from '../../logic/GetAllFluids';
import AddPart from '../../logic/AddPart';
// INTERFACES
import { Order, Unit } from '../../../../global/interfaces/PartsInterface';
import { Service } from '../../../../global/interfaces/ServiceInterface';
import ResetPrice from '../../logic/ResetPrice';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
interface PartsProps {
    readOnly: boolean;
    serviceDetails: Service;
    setServiceDetails: Dispatch<SetStateAction<Service>>;
    partSales: Order[];
    setPartSales: Dispatch<SetStateAction<Order[]>>;
    showSnackbar: showSnackbar;
}
const Parts = ({
    readOnly,
    serviceDetails,
    setServiceDetails,
    partSales,
    setPartSales,
    showSnackbar
}: PartsProps) => {
    const [fluids, setFluids] = useState<Unit[]>([]);
    const [resetPriceDialogOpen, setResetPriceDialogOpen] =
        useState<boolean>(false);

    const partColumns = [
        { id: 0, label: 'Part Number', width: 100 },
        { id: 1, label: 'Name', width: 200 },
        { id: 2, label: 'Qty', width: 100 },
        { id: 3, label: 'Cost', width: 100 },
        { id: 5, label: 'Item Charge (EX. GST)', width: 100 },
        { id: 6, label: 'Line Total (EX. GST)', width: 100 },
        { id: 7, label: 'Line Total (INC. GST)', width: 100 },
        { id: 8, label: '', width: 50 }
    ];

    const fluidColumns = [
        { id: 1, label: 'Part Number', width: 100 },
        { id: 2, label: 'Name', width: 200 },
        { id: 3, label: 'Litres', width: 100 },
        { id: 4, label: 'Cost / L', width: 100 },
        { id: 5, label: 'Charge / L (EX. GST)', width: 100 },
        { id: 6, label: 'Line Total (EX. GST)', width: 100 },
        { id: 7, label: 'Line Total (INC. GST)', width: 100 },
        { id: 8, label: '', width: 50 }
    ];

    const fuelColumns = [
        { id: 1, label: 'Fuel Type', width: 100 },
        { id: 2, label: 'Litres', width: 100 },
        { id: 3, label: 'Cost / L (INC. GST)', width: 100 },
        { id: 4, label: 'Charge / L (INC. GST)', width: 100 },
        { id: 5, label: 'Line Total (INC. GST)', width: 100 },
        { id: 6, label: '', width: 50 }
    ];

    useEffect(() => {
        GetAllFluids(setFluids);
    }, []);

    const handleAddPart = (selectedPart, supplierData) => {
        AddPart(selectedPart, supplierData, serviceDetails, setServiceDetails);
    };

    const handleEditRow = (
        index: number,
        selector: string,
        newValue: string
    ) => {
        let currentRows = [...serviceDetails.addedCosts];
        currentRows[index][selector] = newValue;

        let costPrice = currentRows[index].costPriceAverage
            ? parseFloat(currentRows[index].costPriceAverage)
            : parseFloat(currentRows[index].costPriceDaily);
        currentRows[index].totalPrice =
            parseFloat(currentRows[index].quantity) *
            parseFloat(currentRows[index].pricePaid);
        currentRows[index].totalCost =
            parseFloat(currentRows[index].quantity) * costPrice;

        setServiceDetails({ ...serviceDetails, addedCosts: currentRows });
    };

    const handleEditSaleRow = (
        saleIndex: number,
        partIndex: number,
        newValue: string
    ) => {
        let currentSales = [...partSales];

        currentSales[saleIndex].orderLines[partIndex].priceCharged = newValue;

        setPartSales(currentSales);
    };

    const handleRemoveRow = (id: number) => {
        let currentRows = [...serviceDetails.addedCosts];
        currentRows = currentRows.filter((x) => x.id !== id);
        setServiceDetails({ ...serviceDetails, addedCosts: currentRows });
    };

    const costsContainsFuel = () => {
        let containsFuel = false;
        serviceDetails.addedCosts.forEach((line) => {
            if (line.isFuel) {
                containsFuel = true;
            }
        });

        return containsFuel;
    };

    return (
        <>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <PartSearch
                            HandleAdd={handleAddPart}
                            size="small"
                            isDisabled={readOnly}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            size="small"
                            options={fluids}
                            useTwoOptionLabels={true}
                            primaryOptionLabel="partNumber"
                            secondaryOptionLabel="name"
                            textfieldLabel="Select Fluid"
                            isDisabled={readOnly}
                            selectedValue={null}
                            handleSelectedValueChange={(newValue) =>
                                handleAddPart(
                                    { unitData: newValue, stockData: {} },
                                    null
                                )
                            }
                            handleInputValueChange={() => null}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <br />
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        <Typography variant="h6">Parts</Typography>
                    </Grid>
                    <Grid item xs={1} textAlign={'right'}>
                        <Tooltip title="Reset item charge to the original unit price of the Sale or Proforma">
                            <IconButton
                                disabled={
                                    serviceDetails.jobFinish === 'Finalised'
                                }
                                onClick={() => setResetPriceDialogOpen(true)}
                            >
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <DataTable columns={partColumns}>
                    <>
                        {serviceDetails.addedCosts.map((line, index) => {
                            if (line.partType === 'part') {
                                return (
                                    <TableRow>
                                        <DataCellColoured
                                            handleClick={() =>
                                                window.open(
                                                    `/inventory/view/${line.UnitId}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.partNumber}
                                        </DataCellColoured>
                                        {line.name.length > 15 ? (
                                            <Tooltip title={line.name}>
                                                <DataCell>
                                                    {line.name.slice(0, 15)}...
                                                </DataCell>
                                            </Tooltip>
                                        ) : (
                                            <DataCell>{line.name}</DataCell>
                                        )}
                                        <DataCell>
                                            {readOnly ? (
                                                line.quantity
                                            ) : (
                                                <TextField
                                                    type="number"
                                                    variant="standard"
                                                    size="small"
                                                    disabled={readOnly}
                                                    fullWidth
                                                    inputProps={{
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                    value={line.quantity}
                                                    onChange={(e) =>
                                                        handleEditRow(
                                                            index,
                                                            'quantity',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                line.costPriceDaily
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {readOnly ? (
                                                CurrencyFormatter(
                                                    line.pricePaid
                                                )
                                            ) : (
                                                <TextField
                                                    type="number"
                                                    variant="standard"
                                                    size="small"
                                                    disabled={readOnly}
                                                    fullWidth
                                                    inputProps={{
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                    value={line.pricePaid}
                                                    onChange={(e) =>
                                                        handleEditRow(
                                                            index,
                                                            'pricePaid',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                parseFloat(line.quantity) *
                                                    parseFloat(line.pricePaid)
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                parseFloat(line.quantity) *
                                                    parseFloat(line.pricePaid) *
                                                    1.1
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {readOnly ||
                                            serviceDetails.serviceComplete ===
                                                'Finalised' ? null : (
                                                <IconButton
                                                    onClick={() =>
                                                        handleRemoveRow(line.id)
                                                    }
                                                >
                                                    <Close />
                                                </IconButton>
                                            )}
                                        </DataCell>
                                    </TableRow>
                                );
                            } else {
                                return null;
                            }
                        })}
                        {partSales.map((sale, saleIndex) => (
                            <>
                                {sale.orderLines.map((line, partIndex) => (
                                    <TableRow>
                                        <DataCellColoured
                                            handleClick={() =>
                                                window.open(
                                                    `/inventory/view/${line.UnitId}`,
                                                    '_blank'
                                                )
                                            }
                                        >
                                            {line.partNumber}
                                        </DataCellColoured>
                                        {line.name.length > 15 ? (
                                            <Tooltip title={line.name}>
                                                <DataCell>
                                                    {line.name.slice(0, 15)}...
                                                </DataCell>
                                            </Tooltip>
                                        ) : (
                                            <DataCell>{line.name}</DataCell>
                                        )}
                                        <DataCell>
                                            QTY: {line.quantity}
                                            <br />
                                            {parseInt(line.backorderQuantity) >
                                            0
                                                ? `BO: ${line.backorderQuantity}`
                                                : ''}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                line.costPriceDaily
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {readOnly ? (
                                                CurrencyFormatter(
                                                    line.priceCharged
                                                )
                                            ) : (
                                                <TextField
                                                    type="number"
                                                    variant="standard"
                                                    size="small"
                                                    disabled={readOnly}
                                                    fullWidth
                                                    inputProps={{
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                    value={line.priceCharged}
                                                    onChange={(e) =>
                                                        handleEditSaleRow(
                                                            saleIndex,
                                                            partIndex,
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                (parseInt(line.quantity) +
                                                    parseInt(
                                                        line.backorderQuantity
                                                    )) *
                                                    parseFloat(
                                                        line.priceCharged
                                                    )
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                (parseInt(line.quantity) +
                                                    parseInt(
                                                        line.backorderQuantity
                                                    )) *
                                                    parseFloat(
                                                        line.priceCharged
                                                    ) *
                                                    1.1
                                            )}
                                        </DataCell>
                                        <DataCell>
                                            <Chip
                                                variant="outlined"
                                                label={
                                                    (sale.status === 'Sale'
                                                        ? 'Sale'
                                                        : sale.status ===
                                                            'Proforma'
                                                          ? 'Proforma'
                                                          : 'Quote') +
                                                    ' ' +
                                                    sale.id
                                                }
                                                color={
                                                    sale.status === 'Sale'
                                                        ? 'success'
                                                        : sale.status ===
                                                            'Proforma'
                                                          ? 'warning'
                                                          : 'error'
                                                }
                                                onClick={() =>
                                                    window.open(
                                                        `/inventory/viewSale/${sale.id}`,
                                                        '_blank'
                                                    )
                                                }
                                            />
                                        </DataCell>
                                    </TableRow>
                                ))}
                            </>
                        ))}
                    </>
                </DataTable>
            </Paper>
            <br />
            <Paper>
                <Typography variant="h6">Fluids</Typography>
                <DataTable columns={fluidColumns}>
                    {serviceDetails.addedCosts.map((row, index) => {
                        if (row.partType === 'fluid' && !row.isFuel) {
                            return (
                                <TableRow>
                                    <DataCellColoured
                                        handleClick={() =>
                                            window.open(
                                                `/inventory/view/${row.UnitId}`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        {row.partNumber}
                                    </DataCellColoured>
                                    {row.name.length > 15 ? (
                                        <Tooltip title={row.name}>
                                            <DataCell>
                                                {row.name.slice(0, 15)}...
                                            </DataCell>
                                        </Tooltip>
                                    ) : (
                                        <DataCell>{row.name}</DataCell>
                                    )}
                                    <DataCell>
                                        {readOnly ? (
                                            row.quantity
                                        ) : (
                                            <TextField
                                                fullWidth
                                                type="number"
                                                disabled={readOnly}
                                                variant="standard"
                                                inputProps={{
                                                    style: {
                                                        textAlign: 'center'
                                                    }
                                                }}
                                                value={row.quantity}
                                                onChange={(e) =>
                                                    handleEditRow(
                                                        index,
                                                        'quantity',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        )}
                                    </DataCell>
                                    <DataCell>
                                        {row.isFuel ? (
                                            readOnly ? (
                                                CurrencyFormatter(
                                                    row.costPerLitre
                                                )
                                            ) : (
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    disabled={readOnly}
                                                    variant="standard"
                                                    inputProps={{
                                                        style: {
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                    value={row.costPerLitre}
                                                    onChange={(e) =>
                                                        handleEditRow(
                                                            index,
                                                            'costPerLitre',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            )
                                        ) : (
                                            CurrencyFormatter(row.costPerLitre)
                                        )}
                                    </DataCell>
                                    <DataCell>
                                        {readOnly ? (
                                            CurrencyFormatter(row.pricePaid)
                                        ) : (
                                            <TextField
                                                fullWidth
                                                type="number"
                                                disabled={readOnly}
                                                variant="standard"
                                                inputProps={{
                                                    style: {
                                                        textAlign: 'center'
                                                    }
                                                }}
                                                value={row.pricePaid}
                                                onChange={(e) =>
                                                    handleEditRow(
                                                        index,
                                                        'pricePaid',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        )}
                                    </DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(
                                            parseFloat(row.quantity) *
                                                parseFloat(row.pricePaid)
                                        )}
                                    </DataCell>
                                    <DataCell>
                                        <Tooltip
                                            placement="bottom"
                                            title={
                                                <span
                                                    style={{
                                                        whiteSpace:
                                                            'break-spaces'
                                                    }}
                                                >
                                                    {/* This needs to be on one line due to the spacing within the tooltip */}
                                                    {/* Please don't seperate it out onto multiple lines */}
                                                    {`RRP per litre: ${CurrencyFormatter(row.rrpPerLitre)}\nCost per litre: ${CurrencyFormatter(row.costPerLitre)}\nMargin: ${CurrencyFormatter(row.margin)}`}
                                                </span>
                                            }
                                        >
                                            <Typography variant="body2">
                                                {CurrencyFormatter(
                                                    parseFloat(row.quantity) *
                                                        parseFloat(
                                                            row.pricePaid
                                                        ) *
                                                        1.1
                                                )}
                                            </Typography>
                                        </Tooltip>
                                    </DataCell>
                                    <DataCell>
                                        {readOnly ||
                                        serviceDetails.serviceComplete ===
                                            'Finalised' ? null : (
                                            <IconButton
                                                onClick={() =>
                                                    handleRemoveRow(row.id)
                                                }
                                            >
                                                <Close />
                                            </IconButton>
                                        )}
                                    </DataCell>
                                </TableRow>
                            );
                        } else {
                            return null;
                        }
                    })}
                </DataTable>
            </Paper>
            {costsContainsFuel() ? (
                <>
                    <br />
                    <Paper>
                        <Typography variant="h6">Fuel</Typography>
                        <DataTable columns={fuelColumns}>
                            {serviceDetails.addedCosts.map((row, index) => {
                                if (row.isFuel) {
                                    return (
                                        <TableRow>
                                            <DataCell>
                                                {row.partNumber}
                                            </DataCell>
                                            <DataCell>
                                                {readOnly ? (
                                                    row.quantity
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        disabled={readOnly}
                                                        variant="standard"
                                                        inputProps={{
                                                            style: {
                                                                textAlign:
                                                                    'center'
                                                            }
                                                        }}
                                                        value={row.quantity}
                                                        onChange={(e) =>
                                                            handleEditRow(
                                                                index,
                                                                'quantity',
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                )}
                                            </DataCell>
                                            <DataCell>
                                                {readOnly ? (
                                                    CurrencyFormatter(
                                                        row.costPerLitre
                                                    )
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        disabled={readOnly}
                                                        variant="standard"
                                                        inputProps={{
                                                            style: {
                                                                textAlign:
                                                                    'center'
                                                            }
                                                        }}
                                                        value={row.costPerLitre}
                                                        onChange={(e) =>
                                                            handleEditRow(
                                                                index,
                                                                'costPerLitre',
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                )}
                                            </DataCell>
                                            <DataCell>
                                                {readOnly ? (
                                                    CurrencyFormatter(
                                                        row.pricePaid
                                                    )
                                                ) : (
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        disabled={readOnly}
                                                        variant="standard"
                                                        inputProps={{
                                                            style: {
                                                                textAlign:
                                                                    'center'
                                                            }
                                                        }}
                                                        value={row.pricePaid}
                                                        onChange={(e) =>
                                                            handleEditRow(
                                                                index,
                                                                'pricePaid',
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                )}
                                            </DataCell>
                                            <DataCell>
                                                {CurrencyFormatter(
                                                    parseFloat(row.quantity) *
                                                        parseFloat(
                                                            row.pricePaid
                                                        )
                                                )}
                                            </DataCell>
                                            <DataCell>
                                                {readOnly ||
                                                serviceDetails.serviceComplete ===
                                                    'Finalised' ? null : (
                                                    <IconButton
                                                        onClick={() =>
                                                            handleRemoveRow(
                                                                row.id
                                                            )
                                                        }
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                )}
                                            </DataCell>
                                        </TableRow>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </DataTable>
                    </Paper>
                </>
            ) : null}

            <Dialog
                open={resetPriceDialogOpen}
                onClose={() => setResetPriceDialogOpen(false)}
            >
                <DialogTitle>Reset item charge to original price</DialogTitle>
                <DialogContent>
                    You are about to reset the item charge to the original price
                    of the P&A sale/proforma. This cannot be reversed, you will
                    have to enter every item charges again if you proceed by
                    mistake.
                    <br />
                    <DataTable
                        columns={[
                            { id: 0, label: 'Part Number' },
                            { id: 1, label: 'Current Price' },
                            { id: 2, label: '' },
                            { id: 3, label: 'Original Price' }
                        ]}
                    >
                        {partSales.map((sale) => {
                            return sale.orderLines.map((line) => (
                                <TableRow>
                                    <DataCell>{line.partNumber}</DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(line.priceCharged)}
                                    </DataCell>
                                    <DataCell>
                                        <East />
                                    </DataCell>
                                    <DataCell>
                                        {CurrencyFormatter(
                                            line.totalPrice /
                                                parseInt(line.quantity)
                                        )}
                                    </DataCell>
                                </TableRow>
                            ));
                        })}
                    </DataTable>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setResetPriceDialogOpen(false)}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            ResetPrice(serviceDetails.id, showSnackbar);
                        }}
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Parts;
